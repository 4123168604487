import React, { FC } from 'react';

import {
  BooleanInput,
  Create,
  CreateProps,
  Record,
  required,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';

import { IdInput, RawIdListInput } from 'components/atoms';

import { NotificationCreateToolbar } from '../NotificationCreateToolbar';

export const NotificationCreate: FC<CreateProps> = props => {
  const notify = useNotify();

  const onFailure = (error: any) => {
    notify(
      `Error: ${error.graphQLErrors[0]?.message}`,
      'error',
      {
        autoHideDuration: 10000,
      },
      false,
      10000,
    );
  };

  const handleTransform = (data: Record) => {
    return {
      ...data,
      student_ids: data?.student_ids?.filter(Boolean),
      sendPushNotification: data?.sendPushNotification || false,
    };
  };

  return (
    <Create {...props} onFailure={onFailure} transform={handleTransform}>
      <SimpleForm toolbar={<NotificationCreateToolbar />}>
        <TextInput
          source="title"
          style={{ width: 400 }}
          validate={required()}
        />
        <TextInput
          source="message"
          style={{ width: 400 }}
          validate={required()}
        />
        <IdInput label="Stint id" source="stint_id" style={{ width: 400 }} />
        <RawIdListInput
          label="Stinter and/or Employer IDs"
          multiline
          rowsMax={15}
          source="student_ids"
          style={{ width: 400 }}
          validate={required()}
        />
        <BooleanInput
          label="Send push notification"
          source="sendPushNotification"
        />
      </SimpleForm>
    </Create>
  );
};

import React from 'react';

import { FieldProps, Record, TextField } from 'react-admin';

import { StudentReview } from 'apollo/schema';

const INTERNAL_REVIEW_LABEL = 'Student cancellation';
const BUSINESS_REVIEW_LABEL = 'Business review';

type Props = FieldProps<Record & Partial<StudentReview>>;

export const StintReviewTypeField: React.FC<Props> = ({ record, ...rest }) => {
  const data = {
    id: '',
    text: record?.isInternalReview
      ? INTERNAL_REVIEW_LABEL
      : BUSINESS_REVIEW_LABEL,
  };
  return <TextField record={data} source="text" {...rest} />;
};

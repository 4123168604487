import React, { ComponentProps, FC, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import { Button, useMutation } from 'react-admin';
import styled from 'styled-components';

import { useSendOtpSmsV2Mutation, useStintUserQuery } from 'apollo/schema';
import { AsideActions } from 'components';
import { EmployerDeactivationDialog } from 'components/models/Employer/EmployerDeactivationDialog';
import { GetOTPDialog } from 'components/models/Users/GetOTPDialog';
import { SendOTPDialog } from 'components/models/Users/SendOTPDialog';

type Props = ComponentProps<typeof Button>;

export const EmployerEditAsideActions: FC<Props> = ({ record }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState<boolean>(false);
  const [sendOtpDialogOpen, setSendOtpDialogOpen] = useState<boolean>(false);
  const [isSendingOtpSms, setIsSendingOtpSms] = useState<boolean>(false);

  const [onClickSendOtpSms] = useSendOtpSmsV2Mutation({
    fetchPolicy: 'no-cache',
  });

  const { refetch, data: stintEmployerData } = useStintUserQuery({
    variables: { id: `${record?.user?.id ?? record?.id}` },
    fetchPolicy: 'network-only',
  });

  const [onClickPermanentDeactivate] = useMutation({
    type: 'update',
    resource: 'StintEmployer',
    payload: {
      id: record?.id,
      data: {
        id: record?.id,
        permanentDeactivated: true,
      },
    },
  });

  const [onClickPermanentActivate] = useMutation({
    type: 'update',
    resource: 'StintEmployer',
    payload: {
      id: record?.id,
      data: {
        id: record?.id,
        permanentDeactivated: false,
      },
    },
  });

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOtpDialogClose = () => {
    setOtpDialogOpen(false);
  };

  const handleClickGetOtp = () => {
    setOtpDialogOpen(true);
    refetch();
  };

  const handleSendOtpDialogClose = () => {
    setSendOtpDialogOpen(false);
  };

  const handleClickSendOtp = () => {
    setSendOtpDialogOpen(true);
  };

  const handlePermanentDeactivateEmployer = () => {
    handleClose();
    onClickPermanentDeactivate();
  };

  const handlePermanentActivateEmployer = () => {
    handleClose();
    onClickPermanentActivate();
  };

  const handleSendOTP = async () => {
    if (record) {
      setIsSendingOtpSms(true);
      const userId = record?.user?.id ?? record?.id;
      await onClickSendOtpSms({
        variables: {
          userId: userId.toString(),
        },
      });
      setIsSendingOtpSms(false);
      setSendOtpDialogOpen(false);
    }
  };

  return (
    <AsideActions>
      <Container>
        {!record?.permanentDeactivated && (
          <DialogContainer>
            <WrapButton
              label="Disable user login"
              onClick={() => {
                setDialogOpen(true);
              }}
            />
            <Dialog
              aria-describedby="confirmation-dialog-description"
              aria-labelledby="confirmation-dialog-title"
              fullWidth
              onClose={handleClose}
              open={dialogOpen}
            >
              <EmployerDeactivationDialog
                onClose={handleClose}
                onSave={handlePermanentDeactivateEmployer}
              />
            </Dialog>
          </DialogContainer>
        )}
        {record?.permanentDeactivated && (
          <DialogContainer>
            <WrapButton
              label="Enable user login"
              onClick={() => {
                setDialogOpen(true);
              }}
            />
            <Dialog
              aria-describedby="confirmation-dialog-description"
              aria-labelledby="confirmation-dialog-title"
              fullWidth
              onClose={handleClose}
              open={dialogOpen}
            >
              <EmployerDeactivationDialog
                onClose={handleClose}
                onSave={handlePermanentActivateEmployer}
              />
            </Dialog>
          </DialogContainer>
        )}
      </Container>
      <Container>
        <DialogContainer>
          <WrapButton label="Get OTP" onClick={handleClickGetOtp} />
          <Dialog
            aria-describedby="confirmation-dialog-description"
            aria-labelledby="confirmation-dialog-title"
            fullWidth
            onClose={handleOtpDialogClose}
            open={otpDialogOpen}
          >
            <GetOTPDialog
              onClose={handleOtpDialogClose}
              otp={stintEmployerData?.mono?.otp ?? ''}
            />
          </Dialog>
        </DialogContainer>
      </Container>
      <Container>
        <DialogContainer>
          <WrapButton label="Send OTP" onClick={handleClickSendOtp} />
          <Dialog
            aria-describedby="confirmation-dialog-description"
            aria-labelledby="confirmation-dialog-title"
            fullWidth
            onClose={handleSendOtpDialogClose}
            open={sendOtpDialogOpen}
          >
            <SendOTPDialog
              isSending={isSendingOtpSms}
              name={record?.firstName}
              onClose={handleSendOtpDialogClose}
              onSendOTP={handleSendOTP}
            />
          </Dialog>
        </DialogContainer>
      </Container>
    </AsideActions>
  );
};

const WrapButton = styled(Button)`
  color: #fff;
  background-color: #3f51b5;
  :hover {
    background-color: #3f6eb5;
  }
  width: 100%;
`;

const Container = styled.div`
  padding-bottom: 10px;
`;

const DialogContainer = styled.div``;

import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import { nameValidator } from '../utils';

interface Props {
  source: string;
  label: string;
}

export const TaskTopTipInput: FC<Props> = ({ source, label }) => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <ArrayInput source={source}>
            <SimpleFormIterator disableAdd={formData?.topTips?.length >= 3}>
              <TextInput label={label} source="name" validate={nameValidator} />
              <Box component="div" display="none">
                <FormDataConsumer>
                  {({ scopedFormData }) =>
                    scopedFormData?.id && (
                      <TextInput label="Id" source="id" visible={false} />
                    )
                  }
                </FormDataConsumer>
              </Box>
            </SimpleFormIterator>
          </ArrayInput>
        );
      }}
    </FormDataConsumer>
  );
};

import React from 'react';

import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

import { TagsFilter } from '../TagsFilter';

export const TagsList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<TagsFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

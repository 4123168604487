import { useEffect, useRef, useState } from 'react';

export const useInterval = (callback: VoidFunction, msDelay: number) => {
  const savedCallback = useRef<VoidFunction>();
  // include msdelay value in deps array to satisfy react-hooks/exhaustive-deps
  // but keep functionality identical for backward compatibility
  const [msDelayState] = useState<number>(msDelay);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current?.();
    };
    const id = setInterval(tick, msDelayState);
    return () => clearInterval(id);
  }, [msDelayState]);
};

import React from 'react';

import {
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { DateTimeField } from 'components/atoms';

export const DeviceLocationShow: React.FC<ShowProps> = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateTimeField showTime source="recordedAt" />
        <ReferenceField label="User" reference="StintUser" source="user_id">
          <TextField source="fullname" />
        </ReferenceField>
        <TextField source="heading" />
        <TextField source="speed" />
        <TextField source="latitude" />
        <TextField source="longitude" />
        <TextField emptyText="-" label="Accuracy [meters]" source="accuracy" />
        <TextField source="batteryLevel" />
      </SimpleShowLayout>
    </Show>
  );
};

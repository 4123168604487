import React from 'react';

import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const CountryCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="shortCode" />
      <TextInput source="regions" validate={required()} />
    </SimpleForm>
  </Create>
);

import React from 'react';

import { Datagrid, List, ResourceComponentProps, TextField } from 'react-admin';

import { LanguageFilter } from '../LanguageFilter';

export const LanguageList: React.FC<ResourceComponentProps> = props => {
  return (
    <List {...props} actions={false} filters={<LanguageFilter />}>
      <Datagrid hasBulkActions={false} isRowSelectable={() => false}>
        <TextField emptyText="-" source="name" />
      </Datagrid>
    </List>
  );
};

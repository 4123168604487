import moment from 'moment-timezone';

import { StintJourney } from 'apollo/schema';

export const getManualGeotrackingDisabled = (
  stintJourney?: Partial<StintJourney>,
): boolean => {
  return (
    stintJourney?.isEtaBasedOnLatestLocation ||
    !stintJourney?.estimatedTimeOfDeparture ||
    moment().isBefore(stintJourney?.estimatedTimeOfDeparture)
  );
};

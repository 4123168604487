import { LocationRecord, ValidDeviceLocation } from './types';

export const convertValidDeviceLocationToLocationRecord = (
  deviceLocation: ValidDeviceLocation,
): LocationRecord => ({
  id: deviceLocation.id,
  latitude: deviceLocation.latitude,
  longitude: deviceLocation.longitude,
  accuracy: deviceLocation.accuracy,
  recordedAt: deviceLocation.recordedAt,
  studentId: deviceLocation.user_id,
});

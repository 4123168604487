// eslint-disable-next-line no-restricted-imports
import { Edit as ReactAdminEdit } from 'react-admin';

const Edit = ReactAdminEdit;

Edit.defaultProps = {
  mutationMode: 'pessimistic',
};

export default Edit;

import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Styles for the CustomModal
 */
const useStyles = makeStyles(() => ({
  modalContent: {
    padding: '1rem',
  },
}));

export const CustomModalContent: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.modalContent}>{children ?? null}</Box>
    </>
  );
};

import React from 'react';

import {
  Datagrid,
  EditButton,
  List,
  ListProps,
  TextField,
  FunctionField,
  Record,
} from 'react-admin';

import { toTitleCase } from 'utils';

import { EmploymentExceptionFilter } from '../EmploymentExceptionFilter';

export const EmploymentExceptionList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<EmploymentExceptionFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <FunctionField
          render={(record: Record | undefined) =>
            toTitleCase(record?.category) ?? ''
          }
          source="category"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

import React from 'react';

import {
  Show,
  ShowProps,
  SimpleShowLayout,
  FunctionField,
  Record,
  TextField,
} from 'react-admin';

import { toTitleCase } from 'utils';

export const EmploymentExceptionShow: React.FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField
        render={(record: Record | undefined) =>
          toTitleCase(record?.category) ?? ''
        }
        source="category"
      />
    </SimpleShowLayout>
  </Show>
);

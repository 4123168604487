import React from 'react';

import { Box, Button } from '@material-ui/core';
import {
  useMutation,
  useNotify,
  DateTimeInput,
  required,
  useRefresh,
} from 'react-admin';
import { Form } from 'react-final-form';
import styled from 'styled-components';

import { SaveButton } from 'components/atoms';
import { Modal } from 'components/molecules';

import {
  INVALID_ACTUAL_LEAVE_TIME_FROM_END_ERROR_CODE,
  ACTUAL_LEAVE_TIME_BEFORE_START_ERROR_CODE,
  ACTUAL_LEAVE_TIME_BEFORE_START_FAILED_MSG,
  ACTUAL_LEAVE_TIME_INPUT_LABEL,
  INVALID_ACTUAL_LEAVE_TIME_FROM_END_FAILED_MSG,
  MODAL_OPEN_BUTTON_TEXT,
  MODAL_TITLE,
  UPDATE_ACTUAL_LEAVE_TIME_FAILED_MSG,
  UPDATE_ACTUAL_LEAVE_TIME_HELP_TEXT,
  UPDATE_ACTUAL_LEAVE_TIME_SUCCESS_MSG,
} from './ActualLeaveTimeEdit.constants';
import { ActualLeaveTimeEditProps } from './ActualLeaveTimeEdit.types';

/**
 * This component contains the form to update the ActualLeaveTime of a Stint.
 * Note that this does not use the react-admin Edit component since
 * the ActualLeaveTime is resolved through the Stint query and
 * therefore trying use the react-admin Edit form will result in an
 * `Element does not exist` error.
 */
export const ActualLeaveTimeEdit = ({
  actualLeaveTime,
  stintId,
}: ActualLeaveTimeEditProps) => {
  const [mutate, { loading }] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Modal
      title={MODAL_TITLE}
      openComponent={
        <WrapButton color="primary">{MODAL_OPEN_BUTTON_TEXT}</WrapButton>
      }
    >
      {({ setOpen }) => (
        <Form
          onSubmit={formData => {
            mutate(
              {
                resource: 'ActualLeaveTime',
                type: 'update',
                payload: {
                  data: {
                    id: stintId,
                    updateInputs: {
                      id: stintId,
                      actualLeaveTime: formData.actualLeaveTime,
                    },
                  },
                },
              },
              {
                onFailure: error => {
                  if (
                    error.message.includes(
                      ACTUAL_LEAVE_TIME_BEFORE_START_ERROR_CODE,
                    )
                  ) {
                    notify(ACTUAL_LEAVE_TIME_BEFORE_START_FAILED_MSG, 'error');
                  } else if (
                    error.message.includes(
                      INVALID_ACTUAL_LEAVE_TIME_FROM_END_ERROR_CODE,
                    )
                  ) {
                    notify(
                      INVALID_ACTUAL_LEAVE_TIME_FROM_END_FAILED_MSG,
                      'error',
                    );
                  } else {
                    notify(UPDATE_ACTUAL_LEAVE_TIME_FAILED_MSG, 'error');
                  }
                },
                onSuccess: () => {
                  notify(UPDATE_ACTUAL_LEAVE_TIME_SUCCESS_MSG, 'info');
                  refresh();
                  setOpen(false);
                },
              },
            );
          }}
          render={({ handleSubmit, valid, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  maxWidth="256px"
                  flexDirection="column"
                  mb={2}
                >
                  <DateTimeInput
                    source="actualLeaveTime"
                    defaultValue={actualLeaveTime}
                    validate={[required()]}
                    label={ACTUAL_LEAVE_TIME_INPUT_LABEL}
                    helperText={UPDATE_ACTUAL_LEAVE_TIME_HELP_TEXT}
                  />
                </Box>
                <SaveButton
                  label="Save"
                  disabled={
                    !valid ||
                    (values?.actualLeaveTime &&
                      typeof values.actualLeaveTime !== 'string' &&
                      isNaN(values.actualLeaveTime.getTime()))
                  }
                  loading={loading}
                />
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

const WrapButton = styled(Button)`
  color: #fff;
  background-color: #3f51b5;
  :hover {
    background-color: #3f6eb5;
  }
  width: 100%;
`;

import React, { useState } from 'react';

import { Dialog } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Button, useDataProvider } from 'react-admin';
import { useField } from 'react-final-form';
import styled from 'styled-components';

import { Label } from 'components';
import { ConfirmationDialog } from 'components/molecules';
import { colors } from 'styles';

export const FinanceEdit: React.FC<any> = ({ record }) => {
  const dataProvider = useDataProvider();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errors, setError] = useState<Error>();

  const updateControlBlocker = (isBlockedByFinance: boolean) =>
    dataProvider.update('Business', {
      id: record.id,
      data: { id: record.id, isBlockedByFinance },
      previousData: { id: record.id, isBlockedByFinance },
    });

  const {
    input: { onChange },
    meta: { touched, error },
  } = useField('rateOverride.rate');

  const handleClose = () => setDialogOpen(false);

  const handleSave = async () => {
    try {
      await updateControlBlocker(!record?.isBlockedByFinance);
    } catch (err) {
      setError(err as Error);
    }

    handleClose();
  };

  return (
    <Inputs>
      <Label
        labelText={
          !record.rateOverride?.rate
            ? 'Current City Base Rate'
            : 'Current Business Override'
        }
      />
      <TextField
        data-testid="base-rate"
        defaultValue={record.rateOverride?.rate || record.city?.baseFee}
        error={!!(touched && error)}
        helperText={touched && error}
        label="Business Base Rate"
        margin="dense"
        name="rateOverride.rate"
        onChange={onChange}
        type="number"
        variant="filled"
      />
      <Label labelText="Finance Actions" />
      <>
        <WrapButton
          data-testid="test-button"
          label={
            record?.isBlockedByFinance
              ? 'Unblock Credit Control'
              : 'Credit Control Block'
          }
          onClick={() => {
            setDialogOpen(true);
          }}
        />
        <Dialog
          aria-describedby="confirmation-dialog-description"
          aria-labelledby="confirmation-dialog-title"
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          open={dialogOpen}
        >
          <ConfirmationDialog
            error={Boolean(errors)}
            header="Block Business?"
            onClose={handleClose}
            onSave={handleSave}
            text={
              'Are you sure you want to Credit Control Block this business?\n\nIf this is enabled, none of the members belonging to this business will be able to post stints for this business.\n\nYou can unblock here at any time.'
            }
          />
        </Dialog>
      </>
    </Inputs>
  );
};

const Inputs = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const WrapButton = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.shadeBlue};
  :hover {
    background-color: ${colors.secondary};
  }
`;

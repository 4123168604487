import React, { FC } from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import styled from 'styled-components';

interface Props extends Omit<TypographyProps, 'variant'> {
  bold?: boolean;
  size?: 'small' | 'regular';
}

export const BodyText: FC<Props> = ({
  bold = false,
  size = 'regular',
  ...rest
}) => {
  const variant = size === 'regular' ? 'body1' : 'body2';
  return <StyledTypography bold={bold} variant={variant} {...rest} />;
};

const StyledTypography = styled(
  (props: Props & { variant: TypographyProps['variant'] }) => (
    <Typography {...props} />
  ),
).withConfig({
  shouldForwardProp: prop => !['bold', 'size'].includes(prop),
})<{
  bold?: boolean;
}>`
  font-weight: ${props => (props.bold ? 600 : 'normal')};
`;

import React from 'react';

import { LoadingIndicator } from 'components/molecules';

import { GeotrackingMapMonolith } from '../GeotrackingMapMonolith';

export const GeoTrackingLiveMapDrawerContent: React.FC<{
  manualGeoStatusUpdateLoading: boolean;
  record: any;
  loading: boolean;
}> = ({ manualGeoStatusUpdateLoading, record, loading }) => {
  if (manualGeoStatusUpdateLoading) {
    return (
      <LoadingIndicator loadingMessage="Manual geo-status calculation in progress." />
    );
  }

  if (!record || loading) {
    return <LoadingIndicator />;
  }

  return <GeotrackingMapMonolith record={record} />;
};

import React, { useEffect, useMemo } from 'react';

import { Location } from 'types';

interface Props {
  locations: Location[];
  trailColor: string;
  map?: google.maps.Map;
  polylineOptions?: google.maps.PolylineOptions;
}

export const LiveMapTrail: React.FC<Props> = ({
  locations,
  trailColor,
  map,
  polylineOptions,
}) => {
  const trailPolyline = useMemo(
    () =>
      new google.maps.Polyline({
        ...polylineOptions,
        geodesic: true,
        strokeColor: trailColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }),
    [polylineOptions, trailColor],
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    trailPolyline.setPath(
      locations.map(location => {
        return {
          lat: location.latitude,
          lng: location.longitude,
        };
      }),
    );
    trailPolyline.setMap(map);
  }, [locations, map, trailPolyline]);

  return null;
};

import gql from 'graphql-tag';

export const RTW_STATUS_QUERY = gql`
  query stinterRightToWorkStatus($id: ID!) {
    getStinterRightToWorkStatus(stinterID: $id) {
      until
      weeklyCap
      approvedAt
    }
  }
`;

import React from 'react';

import { FunctionField, Record } from 'react-admin';

import { OffenceAppealStatus } from 'apollo/schema';

import {
  OffenceFunctionFieldProps,
  OffenceListQueryRecord,
  OffenceQueryRecord,
} from '../Offence.types';

/*
OffenceReasonField renders a field that converts the Offence strikesAwarded to either display
the value or `Strikes Removed`.

This is expecting the Offence to be the provided data source.
*/
export const OffenceStrikesAwardedField: React.FC<OffenceFunctionFieldProps> = props => {
  return (
    <FunctionField<OffenceQueryRecord | OffenceListQueryRecord>
      render={(record: Record | undefined) =>
        record?.appealStatus === OffenceAppealStatus.SUCCESSFUL ? (
          <>
            <s>{record?.strikesAwarded}</s> (Strikes removed)
          </>
        ) : (
          `${record?.strikesAwarded}`
        )
      }
      {...props}
    />
  );
};

OffenceStrikesAwardedField.defaultProps = FunctionField.defaultProps;

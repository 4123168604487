import React, { FC } from 'react';

import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

import { AvatarField } from 'components';

export const UniversityShow: FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <AvatarField size={96} source="logo" />
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="domain" />
    </SimpleShowLayout>
  </Show>
);

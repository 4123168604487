import Keycloak from 'keycloak-js';
import isURL from 'validator/lib/isURL';

import {
  AUTH_CLIENT,
  AUTH_REALM,
  DEFAULT_AUTH_SERVICE_URL,
  DEFAULT_GRAPHQL_URL,
  IS_PRODUCTION,
  REACT_ADMIN_GRAPHQL_ENDPOINT,
} from 'config';

export interface ServerLinkSetting {
  api: string;
  authService: string;
}

export const DEFAULT_SETTINGS: ServerLinkSetting = {
  api: DEFAULT_GRAPHQL_URL,
  authService: DEFAULT_AUTH_SERVICE_URL,
};

let apiSettings: ServerLinkSetting = {
  api: localStorage.getItem('rAdminServerSettings') || DEFAULT_GRAPHQL_URL,
  authService:
    localStorage.getItem('rAuthServerSettings') || DEFAULT_AUTH_SERVICE_URL,
};

export const keycloakInitOptions = {
  url: apiSettings.authService,
  realm: AUTH_REALM,
  clientId: AUTH_CLIENT,
  onLoad: IS_PRODUCTION ? 'login-required' : 'check-sso',
};

export const validateSettings = (
  value: ServerLinkSetting,
): {
  auth: null | string;
  api: null | string;
} => {
  return {
    auth:
      !value.authService ||
      isURL(value.authService, {
        require_tld: false,
        protocols: ['https', 'http'],
        require_protocol: true,
      })
        ? null
        : 'Invalid URL',
    api: isURL(value.api, {
      require_tld: false,
      protocols: ['https', 'http'],
      require_protocol: true,
    })
      ? null
      : 'Invalid URL',
  };
};

const cleanupSettings = (value: ServerLinkSetting) => {
  const settings = validateSettings(value) ? value : DEFAULT_SETTINGS;
  settings.api = `${new URL(value.api).origin}${REACT_ADMIN_GRAPHQL_ENDPOINT}`;
  return value;
};

export const getServerSettings = (): ServerLinkSetting => {
  return apiSettings;
};

export const setServerSettings = (value: ServerLinkSetting) => {
  const cleanupValue = cleanupSettings(value);
  localStorage.setItem('rAdminServerSettings', cleanupValue.api);
  if (cleanupValue.authService)
    localStorage.setItem('rAuthServerSettings', cleanupValue.authService);
  const needRefresh =
    cleanupValue.authService &&
    apiSettings.authService !== cleanupValue.authService;
  apiSettings = cleanupValue;
  if (needRefresh) window.location.reload();
};

export const resetServerSettings = () => {
  setServerSettings(DEFAULT_SETTINGS);
};

export const keycloak: Keycloak.KeycloakInstance = Keycloak(
  keycloakInitOptions,
);

import { useKeycloak } from '@react-keycloak/web';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';

import { getServerSettings } from 'utils';

const errorLink = (keycloak: any) =>
  onError(
    // eslint-disable-next-line consistent-return
    ({ forward, graphQLErrors, networkError, operation }) => {
      const isNotAuthorized = graphQLErrors?.some(({ message }) => {
        console.error(message); // eslint-disable-line no-console
        return [
          'Signature has expired',
          'Error decoding signature',
          'Unauthorized',
        ].includes(message);
      });
      if (isNotAuthorized && keycloak.authenticated) {
        const oldHeaders = operation.getContext().headers;
        return new Observable(observer => {
          keycloak
            .updateToken(30)
            .then(() => {
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: `Bearer ${keycloak.token}`,
                },
              });
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            })
            .catch((error: any) => {
              observer.error(error);
            });
        });
      }

      if (networkError)
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${JSON.stringify(networkError)}`);
    },
  );

const authLink = (keycloak: any) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: any) => {
      if (operation.operationName === 'IntrospectionQuery') {
        return null;
      }
      // eslint-disable-next-line no-param-reassign
      headers = {
        ...headers,
        'content-type': 'application/json;charset=UTF-8',
      };
      if (keycloak.authenticated) {
        // eslint-disable-next-line no-param-reassign
        headers.Authorization = `Bearer ${keycloak.token}`;
      }
      return {
        headers,
      };
    });
    return forward(operation);
  });

const convertFileToBase64 = async (file: any) => {
  const blob = await fetch(file.src).then(res => res.blob());
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const convertParamFilesToBase64 = async (data: any) => {
  const result: { [name: string]: any } = {};
  await Promise.all(
    Object.keys(data).map(async (propName: string) => {
      const value = data[propName];
      if (value?.rawFile) {
        result[propName] = await convertFileToBase64(value);
      } else {
        result[propName] = value;
      }
    }),
  );
  return result;
};

const customFetch = async (uri: string, options: any) => {
  const data = JSON.parse(options.body);
  // eslint-disable-next-line no-param-reassign
  options.body = JSON.stringify({
    ...data,
    variables: await convertParamFilesToBase64(data?.variables),
  });
  return fetch(`${uri}?operation=${data?.operationName}`, options);
};

const httpLink = new HttpLink({
  uri: () => getServerSettings().api,
  fetch: customFetch,
});

export const useApolloClient = () => {
  const { keycloak } = useKeycloak();

  return new ApolloClient({
    link: ApolloLink.from([authLink(keycloak), errorLink(keycloak), httpLink]),
    cache: new InMemoryCache(),
  });
};

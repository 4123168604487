import { Datagrid as ReactAdminDataGrid } from 'react-admin';

const Datagrid = ReactAdminDataGrid;

Datagrid.defaultProps = {
  rowClick: (id, basePath, record) =>
    `${basePath}/${id}/${record.user ? 'edit' : 'show'}`,
};

export default Datagrid;

import React, { MouseEvent, useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { CLOSE_LABEL, OPEN_LABEL } from './Modal.constants';
import { ModalProps } from './Modal.types';

export const Modal = ({
  children,
  openComponent,
  title,
  footer,
}: ModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {openComponent ? (
        React.cloneElement(
          typeof openComponent === 'function'
            ? openComponent({ open, setOpen })
            : openComponent,
          {
            onClick: (e: MouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation();
              setOpen(true);
            },
          },
        )
      ) : (
        <Button
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
          color="primary"
        >
          {OPEN_LABEL}
        </Button>
      )}
      <Dialog open={open} onClick={e => e.stopPropagation()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          {typeof children === 'function'
            ? children({ open, setOpen })
            : children}
        </DialogContent>
        <DialogActions>
          {footer && typeof footer === 'function' ? (
            footer({ open, setOpen })
          ) : footer ? (
            footer
          ) : (
            <Button variant="outlined" onClick={() => setOpen(false)}>
              {CLOSE_LABEL}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

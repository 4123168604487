import React from 'react';

import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  ResourceComponentProps,
  TextInput,
} from 'react-admin';

export const CityFilter: React.FC<ResourceComponentProps> = props => (
  <Filter {...props}>
    <TextInput alwaysOn label="Name" source="name_istartswith" />
    <ReferenceInput
      label="Postal code"
      reference="PostalCode"
      source="postalCodeId"
    >
      <AutocompleteInput emptyText="-" optionText="postalCode" />
    </ReferenceInput>
  </Filter>
);

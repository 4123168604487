import React, { FC } from 'react';

import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-admin';

type Props = {
  name?: string;
  isSending?: boolean;
  onSendOTP: () => Promise<void>;
  onClose: VoidFunction;
};

export const SendOTPDialog: FC<Props> = ({
  name,
  isSending = false,
  onSendOTP,
  onClose,
}) => {
  return (
    <>
      <DialogTitle>
        <Typography variant="h6">
          Resend OTP to <strong>{name}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          An <strong>SMS</strong> will be sent to <strong>{name}</strong>{' '}
          including the <strong>OTP</strong>.
        </Typography>
        <Typography variant="body1">
          Press Send to confirm and continue sending the <strong>SMS</strong>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="default" label="Close" onClick={onClose} />
        {isSending ? (
          <Button color="primary" disabled label="Sending..." />
        ) : (
          <Button color="primary" label="Send" onClick={onSendOTP} />
        )}
      </DialogActions>
    </>
  );
};

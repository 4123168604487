import React, { FC, useCallback } from 'react';

import { Divider, Tab, Tabs } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import {
  BooleanField,
  Datagrid,
  DatagridProps,
  DateField,
  EditButton,
  ListContextProvider,
  ReferenceField,
  TextField,
  useListContext,
} from 'react-admin';

import { UserAvatarWithFullNameField } from 'components/atoms';

import { GeotrackingStatusField } from '../GeotrackingStatusField';
import { basePath, Drawers, TabsType } from '../utils';

const tabs = [
  { id: TabsType.ALL, name: 'ALL' },
  { id: TabsType.ACTION_REQUIRED, name: 'ACTION REQUIRED' },
];

export const GeotrackingTabbedGrid: FC<DatagridProps> = props => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const onTabChanged = useCallback(
    (event: React.ChangeEvent<any>, value: any) => {
      setFilters({ ...filterValues, statusGroup: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <Tabs
        centered
        onChange={onTabChanged}
        value={filterValues.statusGroup}
        variant="fullWidth"
      >
        {tabs.map(choice => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      <ListContextProvider value={listContext}>
        <Datagrid
          {...props}
          rowClick={basePath}
          style={{ tableLayout: 'fixed' }}
        >
          <ReferenceField
            label="Start time"
            link={record =>
              `${basePath}/${Drawers.STINT_DETAILS}/${record.stint.id}`
            }
            reference="Stint"
            source="stint.id"
          >
            <DateField showTime source="dateFrom" />
          </ReferenceField>
          <ReferenceField
            label="Student"
            reference="StintUser"
            source="student_id"
          >
            <UserAvatarWithFullNameField />
          </ReferenceField>
          <ReferenceField
            label="Business name"
            reference="Business"
            source="stint.business_id"
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField
            emptyText="-"
            label="Confirmation"
            source="confirmationScreenAccepted"
          />
          <DateField
            emptyText="No"
            label="Check-In"
            showTime
            source="stint.checkInDate"
          />
          <GeotrackingStatusField source="status" />
          <EditButton
            basePath={`${basePath}/${Drawers.TRACKING_DETAILS}`}
            label=""
          />
          <EditButton
            basePath={`${basePath}/${Drawers.LIVE_MAP}`}
            icon={<RoomIcon />}
            label=""
          />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

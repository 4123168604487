import React, { MouseEvent, useState } from 'react';

import { Button, Grid, makeStyles } from '@material-ui/core';
import { Record as RaRecord, required, useRefresh } from 'ra-core';
import {
  Create,
  DateInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { AppealStatus } from 'apollo/schema';
import {
  CustomModal,
  CustomModalContent,
  CustomModalTitle,
} from 'components/molecules/CustomModal';

import { AppealModalProps } from './AppealModal.type';

const useStyles = makeStyles(() => ({
  grid: {
    fontSize: '0.9rem',
  },
  gridHeading: {
    fontWeight: 'bold',
  },
}));

const appealStatusEntries = [
  {
    id: AppealStatus.SUCCESSFUL,
    name: 'Yes',
  },
  {
    id: AppealStatus.UNSUCCESSFUL,
    name: 'No',
  },
];

/**
 * transformOnSubmission modifies the form state before submission.
 */
const transformOnSubmission = (
  data: RaRecord,
): RaRecord | Promise<RaRecord> => {
  if (data.createInputs && data.createInputs.date) {
    data.createInputs.date = new Date(data.createInputs.date).toISOString();
  }
  return data;
};

export const AppealModal: React.FC<AppealModalProps> = ({
  label,
  offenceId,
  businessName,
  stintDate,
}) => {
  const [open, setOpen] = useState(false);

  const refresh = useRefresh();
  const classes = useStyles();

  const todayDate = new Date().toISOString().slice(0, 10);

  const handleClickOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    setOpen(false);

    refresh();
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="text" color="primary">
        {label || 'Appeal'}
      </Button>
      <CustomModal open={open} handleClose={handleClose}>
        <CustomModalTitle title="Appeal Offence" handleClose={handleClose} />
        <CustomModalContent>
          <Grid container className={classes.grid}>
            <Grid item xs={4} className={classes.gridHeading}>
              Business
            </Grid>
            <Grid item xs={8}>
              {businessName}
            </Grid>
            <Grid item xs={4} className={classes.gridHeading}>
              Stint date & time
            </Grid>
            <Grid item xs={8}>
              {stintDate}
            </Grid>
          </Grid>

          <Create
            record={{}}
            basePath="/Appeal"
            resource="Appeal"
            className=""
            onSuccess={onSuccess}
            transform={transformOnSubmission}
          >
            <SimpleForm redirect={false} variant="filled">
              <TextInput
                label="Offence ID"
                source="createInputs.offenceId"
                defaultValue={offenceId}
                disabled
                style={{
                  display: 'none',
                }}
                data-testid="appeal-offence-id"
                validate={[required()]}
              />

              <DateInput
                fullWidth
                label="Date of Appeal"
                source="createInputs.date"
                validate={[required()]}
                data-testid="appeal-date-appeal"
                defaultValue={todayDate}
              />

              <TextInput
                label="Reason given (& relevant notes)"
                source="createInputs.notes"
                multiline
                fullWidth={true}
                rows={10}
                validate={required()}
                data-testid="appeal-notes"
              />

              <SelectInput
                choices={appealStatusEntries}
                label="Strike removed?"
                source="createInputs.status"
                validate={required()}
                data-testid="appeal-status"
              />
            </SimpleForm>
          </Create>
        </CustomModalContent>
      </CustomModal>
    </>
  );
};

import React, { FC } from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  List,
  ListProps,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';

import { PeriodField } from 'components';
import { STINT_STATUS_CHOICES } from 'utils';

import { StintFilter } from '../StintFilter';
import { StintListActions } from '../StintListActions';

export const StintList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      bulkActionButtons={<StintListActions />}
      filters={<StintFilter />}
    >
      <Datagrid data-testid="stints-table">
        <ReferenceField
          data-testid="business-id"
          label="Business"
          reference="Business"
          source="business_id"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          data-testid="posted-date"
          label="Posted date"
          source="createdAt"
        />
        <PeriodField
          data-testid="stint-period"
          endDateFormat="HH:mm"
          endDateSource="dateTo"
          label="Stint date & time"
          sortBy="dateFrom"
          startDateFormat="DD/MM/YYYY HH:mm"
          startDateSource="dateFrom"
        />
        <ReferenceField
          data-testid="student-id"
          label="Student"
          reference="StintUser"
          source="student_id"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <SelectField
          choices={STINT_STATUS_CHOICES}
          data-testid="stint-status"
          label="Status"
          source="status"
        />
        <BooleanField
          data-testid="is-best-effort-id"
          label="Is best effort"
          sortable={false}
          source="isBestEffort"
        />
        <BooleanField
          data-testid="control-check-id"
          label="Control check"
          sortable={false}
          source="controlCheck"
        />
        <ReferenceField
          data-testid="city-id"
          label="City"
          reference="City"
          source="city_id"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

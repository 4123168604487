import React from 'react';

import { BulkActionProps } from 'react-admin';

import { GeotrackingResolveStatusButton } from '../GeotrackingResolveStatusButton';

export const GeotrackingListActions: React.FC<BulkActionProps> = props => (
  <>
    <GeotrackingResolveStatusButton {...props} />
  </>
);

import React from 'react';

import {
  Datagrid,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  TextField,
} from 'react-admin';

import { PeriodField } from 'components';
import { ALLOWED_UPCOMING_STINT_STATUSES } from 'components/models/Student/utils';
import { UserRecord } from 'components/models/Users';
import { FormInputProps } from 'components/types';
import { STINT_STATUS_CHOICES } from 'utils';

import { StudentRecord } from '../Student.types';
import { StudentUpcomingStintsFilter } from './StudentUpcomingStintsFilter';

export const StudentUpcomingStintsList = (
  props: FormInputProps<StudentRecord | UserRecord>,
) => {
  /**
   * This component is used both on the StintUser page and Student page
   * this logic is to determine which data to use as the record.
   */
  const record = props?.record?.user || props?.record;

  return (
    <ReferenceManyField
      basePath="/Stint"
      filter={{
        status_in: ALLOWED_UPCOMING_STINT_STATUSES,
        only_stints_with_no_student_reviews: true,
        student_id: record.id,
      }}
      fullWidth
      label=""
      pagination={<Pagination />}
      perPage={10}
      record={record}
      reference="Stint"
      sortBy="date_from"
      target="student_id"
    >
      <>
        <StudentUpcomingStintsFilter />
        <Datagrid data-testid="student-upcoming-stints-table">
          <ReferenceField
            data-testid="business-id"
            label="Business"
            reference="Business"
            source="business_id"
          >
            <TextField source="name" />
          </ReferenceField>
          <PeriodField
            data-testid="stint-period"
            endDateFormat="HH:mm"
            endDateSource="dateTo"
            label="Stint date & time"
            sortBy="dateFrom"
            startDateFormat="DD/MM/YYYY HH:mm"
            startDateSource="dateFrom"
          />
          <SelectField
            choices={STINT_STATUS_CHOICES}
            data-testid="stint-status"
            label="Status"
            source="status"
          />
        </Datagrid>
      </>
    </ReferenceManyField>
  );
};

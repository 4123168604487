import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { EditProps, SaveButton, Toolbar } from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const UserEditToolbar: React.FC<Omit<EditProps, 'children'>> = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

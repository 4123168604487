import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 500;

export const useListStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

export const useDrawerStyles = makeStyles(() => ({
  root: {
    paddingTop: 48,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    width: drawerWidth,
  },
  content: {
    padding: '0 1em',
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

export const useMultiselectStyles = makeStyles(() => ({
  root: {
    minWidth: 160,
  },
  chip: {
    maxHeight: 16,
  },
  chipContainerFilled: {
    marginTop: 16,
    marginBottom: 0,
  },
}));

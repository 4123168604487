import { buildQuery as dataProviderBuildQuery } from 'ra-data-graphql-simple';

import { actualLeaveTime } from './actualLeaveTime';
import { addresses } from './addresses';
import { appeals } from './appeal';
import { businesses } from './businesses';
import { characteristics } from './characteristics';
import { cities } from './cities';
import { countries } from './countries';
import { deviceLocations } from './deviceLocations';
import { editableSettings } from './editableSettings';
import { employers } from './employer';
import { employmentExceptions } from './employmentException';
import { languages } from './languages';
import { notifications } from './notifications';
import { notificationUsers } from './notificationUsers';
import { stinterOffences } from './offence';
import { periodicTasks } from './periodicTasks';
import { postalCodes } from './postalCodes';
import { regions } from './regions';
import {
  rightToWorkScreening,
  rightToWorkStatus,
  rightToWorkStinterScreening,
} from './rightToWork';
import { schedulingStintTemplate } from './schedulingStintTemplate';
import { staffs } from './staff';
import { stints } from './stint';
import { stintJourneys } from './stintJourneys';
import { stintopedias } from './stintopediaType';
import { stintTemplates } from './stintTemplate';
import { students } from './student';
import { studentReviews } from './studentReview';
import { tags } from './tag';
import { tasks } from './tasks';
import { topTips } from './topTips';
import { users } from './users';

interface IQueryResourceMap {
  [key: string]: (fetchType: string, params: any) => any;
}

const userResourceTransform = (
  userResource: (fetchType: string, params: any) => void,
) => (fetchType: string, params: any) => {
  const sanitizeParams = { ...params };
  if (params.filter?.query)
    sanitizeParams.filter = JSON.stringify(params.filter.query);
  else sanitizeParams.filter = undefined;
  return userResource(fetchType, sanitizeParams);
};

const resourceNameMap: IQueryResourceMap = {
  ActualLeaveTime: actualLeaveTime,
  Address: addresses,
  Appeal: appeals,
  Business: businesses,
  Characteristic: characteristics,
  City: cities,
  Country: countries,
  DeviceLocations: deviceLocations,
  EditableSetting: editableSettings,
  Employer: userResourceTransform(employers),
  EmploymentException: employmentExceptions,
  Language: languages,
  Notification: notifications,
  NotificationStintUser: notificationUsers,
  Offence: stinterOffences,
  PeriodicTask: periodicTasks,
  PostalCode: postalCodes,
  Region: regions,
  RTWScreening: rightToWorkScreening,
  RTWStatus: rightToWorkStatus,
  RTWStinterScreening: rightToWorkStinterScreening,
  SchedulingStintTemplate: schedulingStintTemplate,
  Staff: userResourceTransform(staffs),
  Stint: stints,
  StintJourney: stintJourneys,
  Stintopedia: stintopedias,
  StintTask: tasks,
  StintTemplate: stintTemplates,
  StintUser: users,
  Student: userResourceTransform(students),
  StudentReview: studentReviews,
  Tag: tags,
  TopTip: topTips,
};

const parseLazyPaginationResponse = (
  lazyPageCursor: any,
  resourceName: any,
  params: any,
  originalParseResponse: (response: any) => any,
) => (response: any) => {
  const { next, items } = response.data.data;
  let { total } = response.data.data;
  if (lazyPageCursor[resourceName] === undefined) {
    lazyPageCursor[resourceName] = [];
  }
  lazyPageCursor[resourceName][params.pagination.page] = next;
  if (total === undefined) {
    total =
      (params.pagination.page - 1) * params.pagination.perPage +
      items.length +
      (next !== undefined ? 1 : 0);
  }
  return { ...originalParseResponse(response), total };
};

export const buildQuery = (lazyPageCursor: any) => (introspection: any) => (
  fetchType: any,
  resourceName: any,
  params: any,
) => {
  const simpleQuery = dataProviderBuildQuery(introspection);

  if (resourceNameMap[resourceName] !== undefined) {
    const query = resourceNameMap[resourceName](fetchType, params);

    if (query.useLazyPagination) {
      if (
        params.pagination.page > 1 &&
        lazyPageCursor[resourceName] !== undefined &&
        lazyPageCursor[resourceName][params.pagination.page - 1] !== undefined
      ) {
        query.variables.next =
          lazyPageCursor[resourceName][params.pagination.page - 1];
      }
      const originalParseResponse = query.parseResponse;
      query.parseResponse = parseLazyPaginationResponse(
        lazyPageCursor,
        resourceName,
        params,
        originalParseResponse,
      );
    }

    return query;
  }

  return simpleQuery(fetchType, resourceName, params);
};

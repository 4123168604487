import React from 'react';

import {
  AutocompleteInput,
  BooleanInput,
  EditProps,
  FormDataConsumer,
  FormTab,
  NullableBooleanInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';

import {
  Edit,
  Label,
  SearchAddressField,
  useOnSuccessSameShowTab,
} from 'components';
import { colors } from 'styles';

import { BusinessActionsToolbar } from '../BusinessActionsToolbar/BusinessActionsToolbar';
import { CancellationHistory } from '../CancellationHistory';
import { FinanceEdit } from '../FinanceEdit';
import { BUSINESS_STATUS_CHOICES, transformBusinessEditData } from '../utils';

export const BusinessEdit: React.FC<EditProps> = props => {
  const { onSuccess } = useOnSuccessSameShowTab(props);

  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      transform={transformBusinessEditData}
      actions={<BusinessActionsToolbar />}
    >
      <TabbedForm redirect="show">
        <FormTab label="Detail">
          <TextInput disabled source="id" />
          <TextInput source="name" validate={required()} />
          <TextInput source="ltdName" />
          <TextInput
            data-testid="business-phone-number"
            label="Contact number"
            source="phoneNumber"
          />
          <NullableBooleanInput label="Control check" source="controlCheck" />
          <SelectInput
            alwaysOn
            choices={BUSINESS_STATUS_CHOICES}
            label="Status"
            source="status"
          />
          <NullableBooleanInput label="Integrated?" source="integrated" />
          <Label isSmall labelText="Unlocked last minute Stints?" />
          <NullableBooleanInput label=" " source="unlockedLastMinuteStints" />
          <Label isSmall labelText="Exempt from invalid payment blocker?" />
          <NullableBooleanInput label=" " source="exemptFromPaymentDetails" />
          <Label isSmall labelText="Onboarding Meeting" />
          <NullableBooleanInput
            label=" "
            source="stintopedia.bookedOnboardingMeeting"
          />
          <Label isSmall labelText="Using Stint correctly away" />
          <NullableBooleanInput
            label=" "
            source="stintopedia.usingStintCorrectly"
          />
          <Label isSmall labelText="Repeat Stinters Enabled" />
          <BooleanInput label=" " source="repeatStintersEnabled" />
          <ReferenceInput
            filterToQuery={searchText => ({ firstName: searchText })}
            label="Sales Representative"
            perPage={400}
            reference="StintUser"
            source="salesRepresentitive.id"
          >
            <AutocompleteInput
              allowEmpty
              emptyText="-"
              optionText="email"
              optionValue="id"
            />
          </ReferenceInput>
          <Location>Location</Location>
          <Label labelText="Business Address" />
          <FormDataConsumer>
            {data => (
              <>
                <SearchAddressField
                  placeholder={data?.formData.displayAddress}
                  source="displayAddress"
                  {...data?.formData}
                />
                <InvisibleBox>
                  {!!data?.formData?.placeId && (
                    <TextInput label="Place Id" source="placeId" />
                  )}
                </InvisibleBox>
              </>
            )}
          </FormDataConsumer>
          <CityFields>
            <TextInput disabled label="City" source="address.cityFk.name" />
            <TextInput disabled label="City ID" source="address.cityFk.id" />
            <TextInput disabled label="Postcode" source="address.postalCode" />
          </CityFields>
          <CityCopy>
            If any of the above location fields are incorrect please review and
            update via the City tab
          </CityCopy>
        </FormTab>
        <FormTab label="Cancellation History">
          <CancellationHistory />
        </FormTab>
        <FormTab label="Finance">
          <FinanceEdit />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const InvisibleBox = styled.div`
  display: none;
`;

const CityFields = styled.div`
  display: flex;
  width: 100%;

  & > div {
    margin-right: 30px;
  }
`;

const Location = styled.h3`
  border-top: 1px solid black;
  padding-top: 30px;
  width: 100%;
`;

const CityCopy = styled.p`
  color: ${colors.neutralDark60};
  font-style: italic;
  margin-top: 0;
  width: 100%;
`;

import React from 'react';

import { useQuery, SelectInput, required } from 'react-admin';
import isUUID from 'validator/lib/isUUID';

import { uuidVersion } from 'utils';

import { STINT_CREATE_INPUT_LABELS } from '../StintCreate.constants';
import { SelectStintTemplateProps } from './SelectStintTemplate.types';

export const SelectStintTemplate = ({
  businessId,
  className,
}: SelectStintTemplateProps) => {
  const { data, loading } = useQuery(
    {
      type: 'getList',
      resource: 'SchedulingStintTemplate',
      payload: {
        businessId,
        page: 1,
        pageSize: 100,
      },
    },
    { enabled: Boolean(businessId && isUUID(businessId, uuidVersion)) },
  );
  return (
    <SelectInput
      className={className}
      data-testid="select-stint-template"
      label={STINT_CREATE_INPUT_LABELS.stintTemplate}
      source="stintTemplateId"
      choices={data ?? []}
      optionText="name"
      optionValue="id"
      disabled={!data}
      loading={loading}
      validate={[required()]}
    />
  );
};

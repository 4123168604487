import React, { useState } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import { Labeled, required, useInput } from 'react-admin';

import { BodyText } from 'components/atoms';
import { csvValidator } from 'utils/validators';

import { FileDropZone } from '../FileDropZone';
import { Container, ErrorTextContainer, Text } from './CSVUploadInput.styles';

export type CSVUploadInputProps = {
  label: string;
  source: string;
};

export const CSVUploadInput = ({ label, source }: CSVUploadInputProps) => {
  const {
    input: { onChange, value },
  } = useInput({
    source,
    validate: required(),
    helperText: 'Only csv files will be accepted',
  });

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [acceptedFileName, setAcceptedFileName] = useState<string | undefined>(
    undefined,
  );

  const onDropRejected = (error: Error) => {
    setAcceptedFileName(undefined);
    setErrorMessage(error.message);
    onChange(undefined);
  };

  const onDropAccepted = (files: File[]) => {
    setErrorMessage(undefined);
    setAcceptedFileName(files?.[0]?.name);
  };

  return (
    <Labeled fullWidth label={label}>
      <FileDropZone
        onDropAccepted={onDropAccepted}
        onError={onDropRejected}
        onLoad={async (data: { file: File }) => {
          const { file } = data;
          onChange(file);
        }}
        validator={csvValidator}
      >
        <>
          {!value && (
            <>
              <Container data-testid="csv-upload-input">
                <Text>
                  Drag & drop some files here, or click to select files
                </Text>
                <Text>(Only csv files will be accepted)</Text>
              </Container>
              <ErrorTextContainer>
                <FormHelperText error>{errorMessage}</FormHelperText>
              </ErrorTextContainer>
            </>
          )}

          <BodyText>{value && acceptedFileName}</BodyText>
        </>
      </FileDropZone>
    </Labeled>
  );
};

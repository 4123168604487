import React from 'react';

import { FunctionField } from 'react-admin';

import { OffenceAppealStatus } from 'apollo/schema';
import { Chip } from 'components/atoms';

import {
  OffenceFunctionFieldProps,
  OffenceListQueryRecord,
  OffenceQueryRecord,
} from '../Offence.types';
import { mapOffenceAppealStatus } from './mapping';

/*
OffenceAppealStatusField renders a field that converts the OffenceAppealStatus to a human readable string.

This is expecting the Offence to be the provided data source.
*/
export const OffenceAppealStatusField: React.FC<OffenceFunctionFieldProps> = props => {
  return (
    <FunctionField<OffenceQueryRecord | OffenceListQueryRecord>
      {...props}
      render={record => {
        if (record?.appealStatus) {
          const label = mapOffenceAppealStatus(record.appealStatus);
          switch (record.appealStatus) {
            case OffenceAppealStatus.SUCCESSFUL:
              return <Chip label={label} variant="success" />;
            case OffenceAppealStatus.UNSUCCESSFUL:
              return <Chip label={label} variant="error" />;
            default:
              return <Chip label={label} variant="default" />;
          }
        }
      }}
    />
  );
};

OffenceAppealStatusField.defaultProps = FunctionField.defaultProps;

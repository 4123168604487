import React from 'react';

import {
  BooleanInput,
  Filter,
  FilterProps,
  SelectInput,
  TextInput,
} from 'react-admin';

import { BUSINESS_STATUS_CHOICES } from '../utils';

export const BusinessFilter: React.FC<
  Omit<FilterProps, 'children'>
> = props => (
  <Filter {...props}>
    <TextInput
      alwaysOn
      data-testid="business-id"
      label="ID"
      source="idIcontains"
    />
    <TextInput
      alwaysOn
      data-testid="business-name"
      label="Name"
      source="search_phrase"
    />
    <SelectInput
      alwaysOn
      choices={BUSINESS_STATUS_CHOICES}
      data-testid="business-status"
      label="Status"
      source="status_istartswith"
    />
    <BooleanInput
      alwaysOn
      data-testid="business-control-check"
      defaultChecked
      label="Control check"
      source="controlCheck"
    />
  </Filter>
);

import React, { ComponentProps } from 'react';

import { TextInput } from 'react-admin';

import { validateId } from 'utils';

type Props = ComponentProps<typeof TextInput> & {
  idValidationMessage?: string;
};

export const IdInput: React.FC<Props> = ({
  validate = [],
  idValidationMessage,
  ...rest
}) => {
  let customValidators = validate;
  if (!Array.isArray(customValidators)) {
    customValidators = [customValidators];
  }
  return (
    <TextInput
      {...rest}
      validate={[...customValidators, validateId(idValidationMessage)]}
    />
  );
};

import React, { ComponentProps, FC } from 'react';

import { SelectInput } from 'react-admin';

type Props = ComponentProps<typeof SelectInput> & {
  intervalInMinutes?: number;
};

export const TimeInput: FC<Props> = ({ intervalInMinutes = 15, ...rest }) => {
  const timeChoices = Array.from(Array(24 * 4)).map((_, index) => {
    const time = intervalInMinutes * index;
    const hour = Math.floor(time / 60);
    const minute = time % 60;
    const timeLabel = `${`0${hour % 24}`.slice(-2)}:${`0${minute}`.slice(-2)}`;
    return {
      id: `${timeLabel}:00`,
      name: timeLabel,
    };
  });
  return <SelectInput choices={timeChoices} {...rest} />;
};

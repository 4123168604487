import React, { FC, useCallback, useState } from 'react';

import { Drawer } from '@material-ui/core';
import { List, ListProps } from 'react-admin';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';

import { useResourcePooling } from 'components/hooks';
import { GEOTRACKING_DATA_REFRESH_SECONDS } from 'config';

import { GeotrackingEditDrawer } from '../GeotrackingEditDrawer';
import { GeotrackingFilter } from '../GeotrackingFilter';
import { GeotrackingListActions } from '../GeotrackingListActions';
import { GeotrackingLiveMapDrawer } from '../GeotrackingLiveMapDrawer';
import { GeotrackingPagination } from '../GeotrackingPagination';
import { GeotrackingStintEditDrawer } from '../GeotrackingStintEditDrawer';
import { GeotrackingTabbedGrid } from '../GeotrackingTabbedGrid';
import {
  basePath,
  Drawers,
  filterDefaults,
  sortDefaults,
  useListStyles,
} from '../utils';

export const GeotrackingList: FC<ListProps> = props => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  useResourcePooling(GEOTRACKING_DATA_REFRESH_SECONDS, !drawerOpen);

  const classes = useListStyles();
  const history = useHistory();

  const closeDrawer = useCallback(() => {
    history.push(basePath);
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path={`${basePath}/:mode/:id`}>
        {({ match }: RouteChildrenProps<{ mode: string; id: string }>) => {
          const mode = match?.params?.mode;
          const id = match?.params?.id;
          const showTracking = mode === Drawers.TRACKING_DETAILS && !!id;
          const showStint = mode === Drawers.STINT_DETAILS && !!id;
          const showMap = mode === Drawers.LIVE_MAP && !!id;
          setDrawerOpen(showTracking || showStint || showMap);

          return (
            <>
              <List
                {...props}
                bulkActionButtons={<GeotrackingListActions />}
                className={drawerOpen ? classes.listWithDrawer : classes.list}
                filterDefaultValues={filterDefaults}
                filters={<GeotrackingFilter />}
                pagination={<GeotrackingPagination />}
                perPage={100}
                sort={sortDefaults}
              >
                <GeotrackingTabbedGrid />
              </List>
              <Drawer
                anchor="right"
                classes={{
                  paper: classes.drawerPaper,
                }}
                onClose={closeDrawer}
                open={drawerOpen}
                variant="persistent"
              >
                {showTracking && (
                  <GeotrackingEditDrawer
                    id={id}
                    onCancel={closeDrawer}
                    {...props}
                  />
                )}
                {showStint && (
                  <GeotrackingStintEditDrawer
                    id={id}
                    onCancel={closeDrawer}
                    {...props}
                    resource="Stint"
                  />
                )}
                {showMap && (
                  <GeotrackingLiveMapDrawer
                    id={id}
                    onCancel={closeDrawer}
                    {...props}
                    resource="StintJourney"
                  />
                )}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  );
};

import React from 'react';

import { FunctionField } from 'react-admin';

import { OffenceReason } from 'apollo/schema';

import {
  OffenceQueryRecord,
  OffenceListQueryRecord,
  OffenceFunctionFieldProps,
} from '../Offence.types';
import { mapOffenceReason } from './mapping';

/*
OffenceReasonField renders a field that converts the offence reason to a human readable string.

This is expecting the offence to be the provided data source.
*/
export const OffenceReasonField: React.FC<OffenceFunctionFieldProps> = props => {
  return (
    <FunctionField<OffenceQueryRecord | OffenceListQueryRecord>
      {...props}
      render={record =>
        record && mapOffenceReason(record.reason as OffenceReason)
      }
    />
  );
};

OffenceReasonField.defaultProps = FunctionField.defaultProps;

import React from 'react';

import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { EmploymentExceptionCategorySelectInput } from '../EmploymentExceptionCategorySelectInput';

export const EmploymentExceptionCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <EmploymentExceptionCategorySelectInput validate={required()} />
    </SimpleForm>
  </Create>
);

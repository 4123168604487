import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Button, Error } from 'react-admin';

interface NotificationConfirmationDialogProps {
  header?: string;
  loading?: boolean;
  error?: boolean;
  onClose: VoidFunction;
  onSave: VoidFunction;
  text?: string;
}

export const ConfirmationDialog: React.FC<NotificationConfirmationDialogProps> = ({
  error,
  onClose,
  onSave,
  loading,
  header,
  text,
}) => {
  if (loading) {
    return (
      <DialogContent>
        <Typography display="block" gutterBottom variant="body1">
          Loading...
        </Typography>
      </DialogContent>
    );
  }
  if (error) return <Error error="Error occurred while cancelling data." />;

  return (
    <>
      <DialogContent>
        <Typography gutterBottom variant="h5">
          {header}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {text?.split('\n').map((i, key) => {
            return <p key={key}>{i}</p>;
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="default" label="Close" onClick={onClose} />
        <Button autoFocus color="primary" label="Confirm" onClick={onSave} />
      </DialogActions>
    </>
  );
};

import React from 'react';

import { EditProps, required, SimpleForm, TextInput } from 'react-admin';

import { Edit } from 'components';
import { EmployerEditAsideActions } from 'components/models/Employer/EmployerEditForm/EmployerEditAsideActions';

import { UserEditToolbar } from '../UserEditToolbar';

export const EmployerEditForm: React.FC<EditProps> = props => {
  return (
    <Edit {...props} aside={<EmployerEditAsideActions />}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput disabled source="id" label="ID (from mono)" />
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput label="Email" source="email" validate={required()} />
        <TextInput source="phoneNumber" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

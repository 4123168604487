import React from 'react';

import {
  Create,
  maxLength,
  minLength,
  NumberInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

// FIXME: import { useFeatureFlagKeycloak } from 'services/feature-flags';
import { validateUrl } from 'utils';

const validateSession = [required(), validateUrl()];

export const CityCreate: React.FC<ResourceComponentProps> = props => {
  // FIXME: keycloak user ID is not the same ID as the one provided by the API
  // const flags = useFeatureFlagKeycloak();

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ReferenceInput
          label="Region"
          reference="Region"
          source="regionId"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          label="Verification URL"
          source="sessionUrl"
          validate={validateSession}
        />
        <TextInput
          label="City code"
          source="shortCode"
          validate={[required(), minLength(3), maxLength(3)]}
        />
        <NumberInput
          // FIXME: disabled={!flags?.manageCityBaseRates03Mar2022}
          disabled={false}
          label="Business Base Fee"
          min={0}
          source="baseFee"
        />
        <NumberInput
          // FIXME: disabled={!flags?.manageCityBaseRates03Mar2022}
          disabled={false}
          label="Student Base Fee"
          min={0}
          source="baseFeeStudent"
        />
      </SimpleForm>
    </Create>
  );
};

import React from 'react';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { string } from 'prop-types';
import QRCode from 'qrcode.react';
import { Button } from 'react-admin';
import styled from 'styled-components';

const onDownloadQrCode = ({
  name,
  qrCodeId,
}: {
  name: string;
  qrCodeId: string;
}) => {
  const canvas = document.getElementById(`${qrCodeId}`) as HTMLCanvasElement;

  if (canvas) {
    const pngUrl = canvas
      .toDataURL('image/svg')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${name}-qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const DownloadQRCode = ({
  record = {
    name: string,
    qrCodeId: string,
  },
}) => {
  const businessName = `${record.name}`;
  const businessQRCode = `${record.qrCodeId}`;

  return (
    <>
      <Button
        label="Download"
        onClick={() =>
          onDownloadQrCode({
            name: businessName,
            qrCodeId: businessQRCode,
          })
        }
      >
        <CloudDownloadIcon />
      </Button>
      <QRCodeHidden>
        <QRCode
          id={businessQRCode}
          includeMargin
          level="H"
          size={290}
          value={businessQRCode}
        />
      </QRCodeHidden>
    </>
  );
};
DownloadQRCode.defaultProps = {
  addLabel: true,
  label: 'QR Code',
  source: 'qrCodeId',
};

const QRCodeHidden = styled.div`
  display: none;
`;

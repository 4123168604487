import React from 'react';

import {
  AutocompleteArrayInput,
  BooleanInput,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

// FIXME: import { useFeatureFlagKeycloak } from 'services/feature-flags';
import { Edit } from 'components';

const validateSession = [required()];

export const CityEdit: React.FC<ResourceComponentProps> = props => {
  // FIXME: keycloak user ID is not the same ID as the one provided by the API
  // const flags = useFeatureFlagKeycloak();

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Details">
          <TextInput disabled source="id" />
          <TextInput source="name" validate={required()} />
          <TextInput
            label="Verification URL"
            source="sessionUrl"
            validate={validateSession}
          />
          <ReferenceInput label="Region" reference="Region" source="regionId">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="isActive" />
          <NumberInput
            // FIXME: disabled={!flags?.manageCityBaseRates03Mar2022}
            disabled={false}
            label="Business Base Fee"
            min={0}
            source="baseFee"
          />
          <NumberInput
            // FIXME: disabled={!flags?.manageCityBaseRates03Mar2022}
            disabled={false}
            label="Student Base Fee"
            min={0}
            source="baseFeeStudent"
          />
          <BooleanInput
            label="Availability Enabled"
            source="isAvailabilityEnabled"
          />
        </FormTab>
        <FormTab label="Postal codes">
          <ReferenceArrayInput
            label="Postal codes"
            perPage={400}
            reference="PostalCode"
            source="postalCodeIds"
          >
            <AutocompleteArrayInput
              optionText="postalCode"
              suggestionLimit={5}
            />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { TagsCreate } from './TagsCreate';
import { TagsEdit } from './TagsEdit';
import { TagsList } from './TagsList';
import { TagsShow } from './TagsShow';

export const icon = LocalOfferIcon;

export const create = TagsCreate;
export const edit = TagsEdit;
export const list = TagsList;
export const show = TagsShow;

import React from 'react';

import {
  BooleanInput,
  DateTimeInput,
  EditProps,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  maxLength,
  minValue,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';

import { Edit, Heading, TimeInput } from 'components';
import {
  NO_OFFER_ALGO_PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH,
  NO_OFFER_ALGO_PUSH_NOTIFICATION_TITLE_MAX_LENGTH,
} from 'config';

export const EditableSettingEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <Form>
      <TextInput disabled fullWidth source="id" />
      <NumberInput fullWidth source="predictionLeadDays" />
      <NumberInput fullWidth source="notificationBlockerWindowHours" />
      <NumberInput fullWidth source="geoTrackingBufferTime" />
      <TimeInput
        fullWidth
        label="Matching start time"
        source="advancedOperationalWindowStartTime"
      />
      <TimeInput
        fullWidth
        label="Matching end time"
        source="advancedOperationalWindowEndTime"
      />
      <FormDataConsumer>
        {({ formData }: FormDataConsumerRenderParams) => {
          return (
            formData?.isTimeManipulationEnabled && (
              <DateTimeInput
                fullWidth
                label="Fake server time"
                showTime
                source="serverDatetime"
              />
            )
          );
        }}
      </FormDataConsumer>
      <BooleanInput
        fullWidth
        label="Matching test mode"
        source="automatedTestingEnabled"
      />
      <BooleanInput
        fullWidth
        label="Student going to be late SMS enabled"
        source="featureFlagStudentGoingToBeLateSmsEnabled"
      />
      <BooleanInput
        fullWidth
        label="Second stint reminder enabled"
        source="featureFlagStintSecondStartReminderEnabled"
      />
      <BooleanInput
        fullWidth
        label="ETD notifications enabled"
        source="featureFlagEtdNotificationsEnabled"
      />
      <BooleanInput
        fullWidth
        label="Leave reminder at route time enabled"
        source="sendingStudentLeaveReminderAtRouteTimeEnabled"
      />
      <BooleanInput
        data-testid="student-cancellation-on-hold-email-enabled"
        fullWidth
        label="Student Cancellation On Hold Email Enabled"
        source="studentCancellationOnHoldEmailEnabled"
      />
      <NumberInput
        fullWidth
        label="ETD calculation (before midday)"
        source="etdCalculationBeforeMiddayOffsetInMinutes"
        validate={[required(), minValue(0)]}
      />
      <NumberInput
        fullWidth
        label="ETD calculation (after midday)"
        source="etdCalculationAfterMiddayOffsetInMinutes"
        validate={[required(), minValue(0)]}
      />

      <GroupHeadingContainer>
        <Heading labelText="Stintmatcher service:" />
      </GroupHeadingContainer>

      <NumberInput
        fullWidth
        label="No offer count limit"
        source="matchingAlgoNoOfferMaxCountPerTimeWindow"
        validate={[required(), minValue(1)]}
      />
      <NumberInput
        fullWidth
        label="No offer time span [minutes]"
        source="matchingAlgoNoOfferTimeWindowSpan"
        validate={[required(), minValue(1)]}
      />
      <TextInput
        fullWidth
        source="matchingAlgoNoOfferTitle"
        validate={[maxLength(NO_OFFER_ALGO_PUSH_NOTIFICATION_TITLE_MAX_LENGTH)]}
      />
      <TextInput
        fullWidth
        multiline
        rows={5}
        source="matchingAlgoNoOfferMessage"
        validate={[
          maxLength(NO_OFFER_ALGO_PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH),
        ]}
      />
      <NumberInput
        fullWidth
        label="No offer/Offer Notification Delay"
        source="matchingAlgoNotificationDelayInMinutes"
        validate={[required(), minValue(0)]}
      />
      <BooleanInput
        fullWidth
        label="Availability matching notification for no offer matches enabled"
        source="availabilityMatchingNotificationForNoOffersEnabled"
      />
      <NumberInput fullWidth source="availabilityMatchingBufferHours" />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Mondays"
        source="availabilityMatchingMaxOffersMonday"
        title="The max number of offers that are allowed to be sent to a student on Mondays"
      />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Tuesdays"
        source="availabilityMatchingMaxOffersTuesday"
        title="The max number of offers that are allowed to be sent to a student on Tuesdays"
      />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Wednesdays"
        source="availabilityMatchingMaxOffersWednesday"
        title="The max number of offers that are allowed to be sent to a student on Wednesdays"
      />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Thursdays"
        source="availabilityMatchingMaxOffersThursday"
        title="The max number of offers that are allowed to be sent to a student on Thursdays"
      />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Fridays"
        source="availabilityMatchingMaxOffersFriday"
        title="The max number of offers that are allowed to be sent to a student on Fridays"
      />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Saturdays"
        source="availabilityMatchingMaxOffersSaturday"
        title="The max number of offers that are allowed to be sent to a student on Saturdays"
      />
      <NumberInput
        fullWidth
        label="Availability Matching Max Offers - Sundays"
        source="availabilityMatchingMaxOffersSunday"
        title="The max number of offers that are allowed to be sent to a student on Sundays"
      />
      <NumberInput
        fullWidth
        label="Offer expiry threshold (hours)"
        source="stintOfferExpiryThresholdInHours"
        title="The timing by which a Stint Offer expires in hours"
        validate={[required(), minValue(0)]}
      />
      <NumberInput
        fullWidth
        label="Offer notification threshold (hours)"
        source="notificationTopUpThresholdInHours"
        title="The number of hours between each top up or manual offer"
        validate={[required(), minValue(0)]}
      />
    </Form>
  </Edit>
);

const Form = styled(SimpleForm)`
  width: 400px;
`;

const GroupHeadingContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

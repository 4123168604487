import React from 'react';

import {
  Record,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

import { StintUser } from 'apollo/schema';
import { BodyText } from 'components/atoms';

import { EmployerEditAsideActions } from '../EmployerEditForm/EmployerEditAsideActions';

export const EmployerShowForm: React.FC<ShowProps> = props => {
  const { record: employer } = useShowController<StintUser & Record>(props);

  return (
    <Show
      {...props}
      aside={<EmployerEditAsideActions />}
      hasEdit={!!employer?.user}
    >
      <SimpleShowLayout>
        {(!employer?.user && (
          <BodyText bold>
            The user has not yet logged in for the first time, so they cannot be
            edited
          </BodyText>
        )) || (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField label="ID (from mono)" source="user.id" />
          </div>
        )}
        <TextField label="Authentication ID (from Keycloak)" source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phoneNumber" />
        <TextField source="appVersion" />
      </SimpleShowLayout>
    </Show>
  );
};

import React from 'react';

import { Filter, FilterProps, SelectInput } from 'react-admin';

import { STATUS_STATE } from './State';

export const StatusFilter: React.FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      choices={STATUS_STATE}
      label="Status"
      source="state"
    />
  </Filter>
);

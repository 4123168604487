import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  required,
  TextInput,
  DateTimeInput,
  NumberInput,
  useNotify,
} from 'react-admin';
import { Form } from 'react-final-form';

import { SaveButton } from 'components/atoms/SaveButton';
import { Modal } from 'components/molecules';
import { createManyQuery } from 'graphql-resources/stint';

import { SelectStintTemplate } from './SelectStintTemplate';
import {
  STINT_CREATE_INPUT_LABELS,
  STINT_CREATE_MODAL_BUTTON,
  STINT_CREATE_SUCCESS,
  STINT_CREATE_FAILED,
} from './StintCreate.constants';
import { CreateFormData, StintCreateProps } from './StintCreate.types';
import { transform } from './StintCreate.utils';

export const StintCreate = ({ businessId }: StintCreateProps) => {
  const notify = useNotify();
  const [mutate, { loading }] = useMutation(createManyQuery, {
    onError: () => {
      notify(STINT_CREATE_FAILED, 'error');
    },
    onCompleted: () => {
      notify(STINT_CREATE_SUCCESS, 'info');
    },
  });
  return (
    <Modal
      title={STINT_CREATE_INPUT_LABELS.legend}
      openComponent={
        <Button color="primary">
          <AddIcon />
          {STINT_CREATE_MODAL_BUTTON}
        </Button>
      }
    >
      {({ setOpen }) => (
        <Form
          onSubmit={formData => {
            const mutationInput = transform(
              (formData as unknown) as CreateFormData,
            );
            mutate({ variables: mutationInput }).then(response => {
              if (!response?.errors) {
                setOpen(false);
              }
            });
          }}
          render={({ handleSubmit, valid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box maxWidth="256px" flexDirection="column" display="flex">
                  <TextInput
                    source="businessId"
                    label={STINT_CREATE_INPUT_LABELS.businessId}
                    disabled
                    defaultValue={businessId}
                  />
                  <SelectStintTemplate businessId={businessId} />
                  <DateTimeInput
                    source="dateFrom"
                    validate={[required()]}
                    label={STINT_CREATE_INPUT_LABELS.dateFrom}
                  />
                  <DateTimeInput
                    source="dateTo"
                    validate={[required()]}
                    label={STINT_CREATE_INPUT_LABELS.dateTo}
                  />
                  <NumberInput
                    source="quantity"
                    min={1}
                    validate={[required()]}
                    label={STINT_CREATE_INPUT_LABELS.quantity}
                  />
                </Box>
                <SaveButton label="Save" loading={loading} disabled={!valid} />
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

import React, { FC } from 'react';

import Avatar from '@material-ui/core/Avatar';
import { FieldProps } from 'react-admin';

type AvatarFieldProps = FieldProps & {
  size?: string | number;
};

const fetchRecord: any = (path: string, obj: any) => {
  if (!path || !obj) return obj;
  const [current, remaining] = path.split('.', 2);
  return fetchRecord(remaining, obj[current]);
};

export const AvatarField: FC<AvatarFieldProps> = ({
  record,
  size = '25',
  className,
  source,
}) => {
  const sizeString = String(size);

  return record ? (
    <Avatar
      className={className}
      src={fetchRecord(source, record)}
      style={{
        width: parseInt(sizeString, 10),
        height: parseInt(sizeString, 10),
      }}
    />
  ) : null;
};

import * as React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  ArrayField,
  ChipField,
  SingleFieldList,
  useShowController,
} from 'react-admin';

export const DisplayListOfTopTips = (props: any) => {
  const { record } = useShowController(props);

  if (!record) {
    return <div>Loading...</div>;
  }

  return (
    <Box m={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            Top tips
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid alignContent="flex-end" container item xs={12}>
          <ArrayField
            basePath="/TopTip"
            fieldKey="id"
            record={record}
            source="topTips"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from 'react';

import {
  BooleanField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

export const StaffShowForm: React.FC<ShowProps> = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField data-testid="staff-id" source="id" />
        <TextField data-testid="staff-firstname" source="firstName" />
        <TextField data-testid="staff-lastname" source="lastName" />
        <TextField data-testid="staff-email" source="email" />
        <BooleanField data-testid="staff-enabled" source="enabled" />
      </SimpleShowLayout>
    </Show>
  );
};

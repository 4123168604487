import { StintUniform, StudentUnassignedReasons } from 'apollo/schema';

export enum STINT_STATUS {
  POSTED = 0,
  ACCEPTED = 1,
  CHECKED_IN = 2,
  COMPLETED_IN_REVIEW = 3,
  COMPLETED_AND_REVIEWED = 4,
  CANCELLED = 5,
}

export const STINT_STATUS_CHOICES = [
  { id: STINT_STATUS.POSTED, name: 'Posted' },
  { id: STINT_STATUS.ACCEPTED, name: 'Accepted' },
  { id: STINT_STATUS.CHECKED_IN, name: 'Checked in' },
  { id: STINT_STATUS.COMPLETED_IN_REVIEW, name: 'Completed (in review)' },
  { id: STINT_STATUS.COMPLETED_AND_REVIEWED, name: 'Completed (reviewed)' },
  { id: STINT_STATUS.CANCELLED, name: 'Cancelled' },
];

export const UNIFORM = [
  { id: StintUniform.WHITE_FORMAL_SHIRT, name: 'White formal shirt' },
  { id: StintUniform.BLACK_FORMAL_SHIRT, name: 'Black formal shirt' },
  { id: StintUniform.BLACK_T_SHIRT, name: 'Black t shirt' },
  { id: StintUniform.BLACK_FORMAL_TROUSERS, name: 'Black formal trousers' },
  { id: StintUniform.BLACK_JEANS, name: 'Black jeans' },
  { id: StintUniform.DARK_TRAINERS, name: 'Dark trainers' },
  { id: StintUniform.BLACK_FORMAL_SHOES, name: 'Black formal shoes' },
  { id: StintUniform.BLACK_FORMAL_SOCKS, name: 'Black formal socks' },
];

export const UNASSIGNED_CHOICES = [
  {
    id: [StudentUnassignedReasons.ACCEPTED_BY_MISTAKE],
    name: 'Accepted by mistake',
  },
  { id: [StudentUnassignedReasons.ARRIVE], name: 'Arrive' },
  { id: [StudentUnassignedReasons.BAD_RATING], name: 'Bad rating' },
  { id: [StudentUnassignedReasons.BUSINESS_UNHAPPY], name: 'Business unhappy' },
  { id: [StudentUnassignedReasons.COVID_HOLD], name: 'Covid hold' },
  { id: [StudentUnassignedReasons.CRITERIA], name: 'Criteria' },
  { id: [StudentUnassignedReasons.DISCIPLINARY], name: 'Disciplinary' },
  {
    id: [StudentUnassignedReasons.EXPECTED_TOO_LATE],
    name: 'Expected too late',
  },
  { id: [StudentUnassignedReasons.EXTENUATING], name: 'Extenuating' },
  {
    id: [StudentUnassignedReasons.NO_CONFIRM_NIGHT_BEFORE],
    name: 'No confirm night before',
  },
  {
    id: [StudentUnassignedReasons.NO_CONFIRM_ON_THE_DAY],
    name: 'No confirm on the day',
  },
  { id: [StudentUnassignedReasons.OTHER], name: 'Other' },
  {
    id: [StudentUnassignedReasons.OVERLAPPING_STINTS],
    name: 'Overlapping Stints',
  },
  { id: [StudentUnassignedReasons.PULLED_OUT], name: 'Pulled out' },
  { id: [StudentUnassignedReasons.WORKING_CAP], name: 'Working cap' },
  {
    id: [StudentUnassignedReasons.TEAMS_SWAP],
    name: 'TEAMS swap',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_SICK],
    name: 'TEAMS sick',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_EXTENUATING],
    name: 'TEAMS extenuating',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_CHANGE_OF_SCHEDULE],
    name: 'TEAMS change of schedule',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_STINTER_LEFT_TEAM],
    name: 'TEAMS stinter left team',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_STINTER_REMOVED_FROM_TEAM],
    name: 'TEAMS stinter removed from team',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_BRAND_REPEAT_CANCELLATION],
    name: 'TEAMS brand repeat cancellation',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_NO_COMPLIANCE],
    name: 'TEAMS no compliance',
  },
  {
    id: [StudentUnassignedReasons.TEAMS_OTHER],
    name: 'TEAMS other',
  },
];

import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from 'react-admin';

import { AvatarField, DateTimeField } from 'components';
import { LazyPagination } from 'components/organisms/LazyPagination';
import { colors } from 'styles';

import { STATUS_STATE } from './State';
import { StatusFilter } from './StatusFilter';

export const RightToWorkStatusList: FC<ListProps> = props => {
  const filterDefaultValues = {
    state: STATUS_STATE[0].id,
  };
  return (
    <List
      {...props}
      filterDefaultValues={filterDefaultValues}
      filters={<StatusFilter />}
      pagination={<LazyPagination />}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
    >
      <Datagrid>
        <ReferenceField
          label=""
          reference="StintUser"
          sortable={false}
          source="stinter.id"
        >
          <AvatarField sortable={false} source="profilePhotoUrl" />
        </ReferenceField>
        <ReferenceField
          label="Stinter"
          reference="StintUser"
          sortable={false}
          source="stinter.id"
        >
          <FunctionField
            render={(data?: any) => {
              let label = data.fullname;
              if (data.isVerifiedByStint) {
                label += ' (Verified by Stint)';
              }
              return label;
            }}
          />
        </ReferenceField>
        <DateTimeField label="Last change at" showTime source="updatedAt" />
        <FunctionField
          label="Status"
          render={(record?: any) => {
            let color = colors.placeholderGrey;
            const status =
              record.state.charAt(0).toUpperCase() +
              record.state.substr(1).toLowerCase();
            if (record.state === 'APPROVED') {
              color = colors.success;
            }
            return (
              <Typography
                style={{
                  color,
                }}
              >
                {status}
              </Typography>
            );
          }}
          source="status"
        />
        <ReferenceField
          label="City"
          reference="StintUser"
          sortable={false}
          source="stinter.id"
        >
          <TextField source="currentCity.name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

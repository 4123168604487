import React, { FC } from 'react';

import {
  Create,
  CreateProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { nameValidator, TASK_TYPE_CHOICES } from '../utils';

export const TaskCreate: FC<CreateProps> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={nameValidator} />
      <SelectInput
        choices={TASK_TYPE_CHOICES}
        defaultValue="STINT_APPROVED"
        label="Type"
        source="taskType"
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

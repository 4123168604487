import React, { FC } from 'react';

import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { ImageUploadInput } from 'components/molecules';

export const UniversityCreate: FC<CreateProps> = props => (
  <Create {...props}>
    <SimpleForm>
      <ImageUploadInput label="Logo" size={120} source="logo" />
      <TextInput source="name" validate={required()} />
      <TextInput source="domain" validate={required()} />
    </SimpleForm>
  </Create>
);

import { CSSProperties } from 'react';

type StandardConfig = CSSProperties & {
  fontDisplay: 'fallback' | 'auto' | 'block' | 'swap' | 'optional' | undefined;
  unicodeRange: string;
};

type FontFace = StandardConfig & {
  src: string;
};

const sharedConfig: StandardConfig = {
  fontDisplay: 'swap',
  fontFamily: 'DINPro',
  fontStyle: 'normal',
  fontWeight: 'normal',
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const dinProLight: FontFace = {
  ...sharedConfig,
  src: `
    local('DINPro'),
    local('DINPro-Light'),
    url('/fonts/DINPro-Light tr.woff') format('woff'),
  `,
};

export const dinProMedium: FontFace = {
  ...sharedConfig,
  src: `
    local('DINPro'),
    local('DINPro-Medium'),
    url('/fonts/DINPro-Medium tr.woff') format('woff'),
  `,
};

/**
 * This file handles the graphql queries and mutations for the ActualLeaveTime
 * resource in the Stints app.
 */
import gql from 'graphql-tag';

import { buildQuery } from './utils';

const updateQuery = gql`
  mutation UpdateActualLeaveTime($updateInputs: UpdateActualLeaveTimeInput!) {
    data: updateActualLeaveTime(updateInputs: $updateInputs) {
      id
      actualLeaveTime
    }
  }
`;

export const actualLeaveTime = buildQuery({
  updateQuery,
});

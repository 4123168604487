import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { EditProps, SaveButton, Toolbar } from 'react-admin';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const GeotrackingCustomToolbar: React.FC<
  Omit<EditProps, 'children'>
> = props => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
    </Toolbar>
  );
};

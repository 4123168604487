import React, { ComponentProps, FC } from 'react';

import { Button, useMutation } from 'react-admin';
import styled from 'styled-components';

import { AsideActions } from 'components';
import { STINT_STATUS } from 'utils';

import { ActualLeaveTimeEdit } from '../StintEdit/ActualLeaveTime';

type Props = ComponentProps<typeof Button>;

export const EditAsideStintActions: FC<Props> = ({ record }) => {
  const [onCompleteStint] = useMutation({
    type: 'update',
    resource: 'Stint',
    payload: {
      id: record?.id,
      data: {
        id: record?.id,
        status: STINT_STATUS.COMPLETED_IN_REVIEW,
      },
    },
  });

  const handleCompleteStudent = () => {
    onCompleteStint();
  };

  return (
    <AsideActions>
      <Container>
        {record?.status > STINT_STATUS.POSTED &&
          record?.status <= STINT_STATUS.CHECKED_IN && (
            <WrapButton
              label="Complete Stint"
              onClick={handleCompleteStudent}
            />
          )}
        {record?.id &&
          (record?.status == STINT_STATUS.CHECKED_IN ||
            record?.status == STINT_STATUS.COMPLETED_IN_REVIEW ||
            record?.status == STINT_STATUS.COMPLETED_AND_REVIEWED) && (
            <ActualLeaveTimeEdit
              stintId={record.id}
              actualLeaveTime={record.actualLeaveTime ?? record.dateTo}
            />
          )}
      </Container>
    </AsideActions>
  );
};

const WrapButton = styled(Button)`
  color: #fff;
  background-color: #3f51b5;
  :hover {
    background-color: #3f6eb5;
  }
  width: 100%;
`;

const Container = styled.div`
  padding-bottom: 10px;
`;

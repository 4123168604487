import React, { ComponentProps } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteArrayInput as RAAutocompleteArrayInput } from 'react-admin';

const useStyles = makeStyles(
  {
    suggestionsContainer: {
      zIndex: 100000,
    },
  },
  { name: 'AutocompleteArrayInput' },
);

type Props = ComponentProps<typeof RAAutocompleteArrayInput>;

export const AutocompleteArrayInput: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <RAAutocompleteArrayInput
      {...props}
      options={{
        suggestionsContainerProps: {
          className: classes?.suggestionsContainer,
        },
      }}
    />
  );
};

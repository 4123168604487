import React from 'react';

import { Link, Typography } from '@material-ui/core';
import {
  useRecordContext,
  Record,
  sanitizeFieldRestProps,
  TextField,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';

interface LinkTextFieldProps<T> {
  record?: T;
  label: string;
  addLabel?: boolean;
  to: (record: T) => string | null;
  text: (record: T) => string;
  ['aria-label']?: (record: T) => string;
}

/*
LinkTextField provides a simple way to add a link using the react router along with associated text.

A null response from `to()` will not render a link.

It is the users responsibility to handle nullability within the record
and return the appropriate text.

Usage:
  <LinkTextField
    label="Stint date & time"
    to={(record: Offence) => record.stint?.id ? `/Stint/${record.stint.id}/show` : null}
    text={(record: Offence) => record.stint.stintTimesDisplay}
  />

Note: the Link needs stopPropagation in order for it to render correctly inside a datagrid with
rowClick enabled - otherwise clicking on the link will take it to the rows detail page.
*/
export const LinkTextField = <T extends Record>({
  to,
  text,
  'aria-label': ariaLabel,
  ...props
}: LinkTextFieldProps<T>) => {
  const record = useRecordContext(props);
  const sanitizedProps = sanitizeFieldRestProps(props);
  if (!record) {
    return null;
  }

  const linkTo = to(record);
  const linkText = text(record);
  if (!linkTo) {
    return (
      <Typography {...sanitizedProps} variant="body2" component="span">
        {text(record)}
      </Typography>
    );
  }

  return (
    <Link
      to={linkTo}
      onClick={e => e.stopPropagation()}
      component={RouterLink}
      variant="body2"
      aria-label={ariaLabel ? ariaLabel(record) : linkText}
      {...sanitizedProps}
    >
      {linkText}
    </Link>
  );
};

LinkTextField.defaultProps = TextField.defaultProps;

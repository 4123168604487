import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { useEditController } from 'ra-core';
import { EditProps, EditView, Record } from 'react-admin';

type Props = EditProps & {
  transformRecord: (record?: Record) => Record | undefined;
  asidePanel?: React.ReactElement;
  children: React.ReactElement;
};

export const Edit: FC<Props> = ({
  transformRecord,
  asidePanel,
  transform,
  ...props
}) => {
  const handleTransform = (data: Record): Record => {
    return transform?.(data) as Record;
  };
  const rest = useEditController({
    ...props,
    transform: handleTransform,
    mutationMode: 'pessimistic',
  });
  const record = rest?.record;

  return (
    <Box display="flex">
      <Box flexGrow={1} p={1}>
        <EditView
          {...props}
          {...rest}
          record={transformRecord ? transformRecord(record) : record}
          transform={handleTransform}
        />
      </Box>
      {asidePanel && (
        <Box justifyContent="center" p={1} pt={10}>
          {React.cloneElement(asidePanel, {
            ...props,
          })}
        </Box>
      )}
    </Box>
  );
};

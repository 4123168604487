import React from 'react';

import Box from '@material-ui/core/Box';
import { LinearProgress } from 'react-admin';

import { BodyText } from 'components/atoms';

interface Props {
  loadingMessage?: string;
}

export const LoadingIndicator: React.FC<Props> = ({ loadingMessage }) => {
  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      {!!loadingMessage && (
        <Box>
          <BodyText>{loadingMessage}</BodyText>
        </Box>
      )}
      <LinearProgress />
    </Box>
  );
};

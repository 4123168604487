import styled from 'styled-components';

export const StintMarkerWrapper = styled.div`
  border-radius: 10%;
  overflow: hidden;
  width: 36px;
  height: 36px;
`;

export const StintImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const getNullableBooleanDisplayValue = (
  fieldValue?: boolean | null,
  displayValueForNull = 'N/A',
) => {
  let displayText;
  if (fieldValue) {
    displayText = 'Yes';
  } else if (fieldValue === null || fieldValue === undefined) {
    displayText = displayValueForNull;
  } else {
    displayText = 'No';
  }
  return displayText;
};

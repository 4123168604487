import gql from 'graphql-tag';

import { buildQuery } from './utils';

const RTWStatusFields = gql`
  fragment RTWStatusFields on StinterRTWStinterStatus {
    id
    stinter {
      id
    }
    state
    updatedAt
  }
`;
const RTWScreeningFields = gql`
  fragment RTWScreeningFields on StinterRTWScreening {
    stinter {
      id
    }
    id
    type
    status
    updatedAt
    error
  }
`;

const listStatusQuery = gql`
  query listStinterRightToWorkStatus(
    $filter: StinterRightToWorkStatusFilter
    $perPage: Int
    $next: String
  ) {
    data: listStinterRightToWorkStatus(
      filter: $filter
      limit: $perPage
      next: $next
    ) {
      items {
        ...RTWStatusFields
      }
      next
    }
  }
  ${RTWStatusFields}
`;

const listScreeningQuery = gql`
  query listStinterRightToWorkScreening(
    $input: StinterRightToWorkScreeningFilter
    $perPage: Int
    $next: String
  ) {
    data: listStinterRightToWorkScreening(
      filter: $input
      limit: $perPage
      next: $next
    ) {
      items {
        ...RTWScreeningFields
      }
      next
    }
  }
  ${RTWScreeningFields}
`;

const listStinterScreeningQuery = gql`
  query listStinterRightToWorkStinterScreening($id: ID!) {
    data: listStinterRightToWorkStinterScreening(stinterID: $id) {
      ...RTWScreeningFields
    }
  }
  ${RTWScreeningFields}
`;

const listParseResponse = (response: any) => {
  return { data: response.data.data.items };
};

export const rightToWorkStatus = buildQuery({
  listQuery: listStatusQuery,
  listParseResponse,
  listOptions: {
    useLazyPagination: true,
  },
});

export const rightToWorkScreening = buildQuery({
  listQuery: listScreeningQuery,
  listParseResponse,
  listOptions: {
    useLazyPagination: true,
  },
});

export const rightToWorkStinterScreening = buildQuery({
  listQuery: listStinterScreeningQuery,
  listParseResponse: (response: any) => {
    return {
      data: response.data.data,
      total: (response.data.data ?? []).length,
    };
  },
});

import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';

interface Props {
  disabled?: boolean;
  label: string;
  loading?: boolean;
}

/** *
 * Use this component if you need to use a form that is not part of
 * the data provider that you setup in graphql-resources
   
 * This button uses the same styles as the SaveButton component that is provided by
 * react-admin.
 ** */
export const SaveButton: FC<Props> = ({ disabled, label, loading }) => {
  const useStyles = makeStyles(
    theme => ({
      button: {
        position: 'relative',
      },
      leftIcon: {
        marginRight: theme.spacing(1),
      },
      icon: {
        fontSize: 18,
      },
    }),
    { name: 'RaSaveButton' },
  );

  const classes = useStyles();

  return (
    <Button
      aria-label={label}
      className={classes.button}
      color={loading ? 'default' : 'primary'}
      data-testid="custom-save-button"
      disabled={disabled || loading}
      type="submit"
      variant="contained"
    >
      {loading && (
        <CircularProgress
          className={`${classes.leftIcon}`}
          data-testid="loading-icon"
          size={18}
          thickness={2}
        />
      )}
      {!loading && (
        <ContentSave
          className={`${classes.leftIcon} ${classes.icon}`}
          data-testid="save-icon"
        />
      )}
      {label}
    </Button>
  );
};

import React, { ComponentProps, FC } from 'react';

import Box from '@material-ui/core/Box';
import { TextField } from 'react-admin';
import styled from 'styled-components';

import { AvatarField } from '../AvatarField';

type Props = {
  imageSource: string;
  labelSource?: string;
} & ComponentProps<typeof AvatarField>;

export const LabeledImageField: FC<Props> = ({
  record,
  imageSource,
  labelSource,
  size,
}) => {
  return record ? (
    <Wrapper>
      <Box mb={0.5} mr={1} mt={0.5}>
        <AvatarField record={record} size={size} source={imageSource} />
      </Box>
      <TextField record={record} source={labelSource} />
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

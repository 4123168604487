import { DocumentNode } from '@apollo/client';
import omit from 'omit-deep-lodash';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';

import { DEFAULT_PER_PAGE_ITEMS_COUNT } from 'config';

interface QueryParams {
  listQuery: DocumentNode;
  singleItemQuery: DocumentNode;
  updateQuery: DocumentNode;
  createQuery: DocumentNode;
  deleteQuery: DocumentNode;
  updateManyQuery: DocumentNode;
  listParseResponse: (response: any) => any;
  singleItemParseResponse: (response: any) => any;
  updateParseResponse: (response: any) => any;
  createParseResponse: (response: any) => any;
  deleteParseResponse: (response: any) => any;
  updateManyParseResponse: (response: any) => any;
  listOptions?: Record<string, any>;
  singleItemOptions?: Record<string, any>;
  updateOptions?: Record<string, any>;
  createOptions?: Record<string, any>;
  deleteOptions?: Record<string, any>;
}

const defaultParseListResponse = (response: any) => {
  return {
    data: response?.data?.items ?? [],
    total: response?.data?.total?.count,
  };
};

const defaultParseItemResponse = (response: any) => response.data;

export const singleQueryPaginationListResponse = (response: any) => {
  /**
   * Handles parsing the response from a list query that uses the PaginatedField class to expose
   * the offset pagination data.
   *
   * A react-admin List component needs the data to come back with values for data and total in
   * order to display the data and paginate it.
   */
  const { objects, paginationInfo } = response?.data?.data ?? {};

  return { data: objects ?? [], total: paginationInfo?.totalObjects ?? 0 };
};

export const buildQuery = ({
  listQuery,
  singleItemQuery,
  updateQuery,
  createQuery,
  deleteQuery,
  updateManyQuery,
  listParseResponse,
  singleItemParseResponse,
  updateParseResponse,
  createParseResponse,
  deleteParseResponse,
  updateManyParseResponse,
  listOptions,
  singleItemOptions,
  updateOptions,
  createOptions,
  deleteOptions,
}: Partial<QueryParams>) => {
  return (fetchType: string, params: any) => {
    const { pagination, sort, ...rest } = params;

    const flattenParams = {
      ...rest,
    };

    if (pagination) {
      flattenParams.page = parseInt(pagination.page, 10) - 1;
      flattenParams.perPage = parseInt(pagination.perPage, 10);
      flattenParams.pageSize = flattenParams.perPage;
    }

    if (sort) {
      flattenParams.sortField = sort.field;
      flattenParams.sortOrder = sort.order;
    }

    switch (fetchType) {
      case GET_MANY:
        return {
          query: listQuery,
          variables: {
            filter: params,
            perPage: params?.ids?.length || DEFAULT_PER_PAGE_ITEMS_COUNT,
          },
          parseResponse: listParseResponse || defaultParseListResponse,
          ...listOptions,
        };
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          query: listQuery,
          variables: flattenParams,
          parseResponse: listParseResponse || defaultParseListResponse,
          ...listOptions,
        };
      case GET_ONE:
        return {
          query: singleItemQuery,
          variables: flattenParams,
          parseResponse: singleItemParseResponse || defaultParseItemResponse,
          ...singleItemOptions,
        };
      case CREATE:
        const createdOmittedParams: Record<string, any> = omit(flattenParams, [
          '__typename',
        ]);
        return {
          query: createQuery,
          variables: createdOmittedParams?.data
            ? { ...createdOmittedParams?.data }
            : createdOmittedParams,
          parseResponse: createParseResponse || defaultParseItemResponse,
          ...createOptions,
        };
      case UPDATE:
        const updateOmittedParams: Record<string, any> = omit(flattenParams, [
          '__typename',
        ]);
        return {
          query: updateQuery,
          variables: updateOmittedParams?.data
            ? { ...updateOmittedParams?.data }
            : updateOmittedParams,
          parseResponse: updateParseResponse || defaultParseItemResponse,
          ...updateOptions,
        };
      case DELETE:
        return {
          query: deleteQuery,
          variables: flattenParams,
          parseResponse: deleteParseResponse || defaultParseItemResponse,
          ...deleteOptions,
        };
      case DELETE_MANY:
        return {
          query: deleteQuery,
          variables: flattenParams,
          parseResponse: deleteParseResponse || defaultParseItemResponse,
          ...deleteOptions,
        };
      case UPDATE_MANY:
        const updateManyOmittedParams: Record<string, any> = omit(
          flattenParams,
          ['__typename'],
        );
        return {
          query: updateManyQuery,
          variables: updateManyOmittedParams?.data
            ? { ...updateManyOmittedParams?.data }
            : updateManyOmittedParams,
          parseResponse: updateManyParseResponse || defaultParseListResponse,
        };
      default:
        throw Error(`Unexpected GQL fetch type ${fetchType}`);
    }
  };
};

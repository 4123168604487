import React from 'react';

import { RightToWorkScreeningList } from './ScreeningList';
import { RightToWorkStatusList } from './StatusList';

export const Status = {
  list: RightToWorkStatusList,
};
export const Screening = {
  list: RightToWorkScreeningList,
};
export const StinterScreening = {
  // eslint-disable-next-line react/display-name
  list: () => {
    return <></>;
  },
};

export { StatusCard } from './StatusCard';

import React from 'react';

import { FunctionField } from 'react-admin';

import { OffenceAppealStatus } from 'apollo/schema';
import { AppealModal } from 'components/models/Appeal/AppealModal';

import {
  OffenceFunctionFieldProps,
  OffenceListQueryRecord,
  OffenceQueryRecord,
} from '../Offence.types';

/*
OffenceAppealButtonField renders a field including a button to appeal an offence.

The button will use the appeal status to determine the label to use when rendering the button.
For NOT_APPEALED offences, the user will be prompted to Appeal, for all others, they can Revise
the offence.

This is expecting the Offence to be the provided data source.
*/
export const OffenceAppealButtonField: React.FC<OffenceFunctionFieldProps> = props => {
  return (
    <FunctionField<OffenceQueryRecord | OffenceListQueryRecord>
      {...props}
      render={record => {
        if (record?.appealStatus) {
          const label =
            record.appealStatus == OffenceAppealStatus.NOT_APPEALED
              ? 'Appeal'
              : 'Revise';
          return (
            <AppealModal
              label={label}
              offenceId={record.id}
              businessName={record.business?.name}
              stintDate={record.stint?.stintTimesDisplay}
            />
          );
        }
      }}
    />
  );
};

OffenceAppealButtonField.defaultProps = FunctionField.defaultProps;

import React, { FC } from 'react';

import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

import { DisplayListOfTopTips } from '../DisplayListOfTopTips';
import { TaskFilter } from '../TaskFilter';

export const TaskList: FC<ListProps> = props => {
  return (
    <List {...props} filters={<TaskFilter />}>
      <Datagrid expand={<DisplayListOfTopTips />} rowClick="edit">
        <TextField label="Task" source="name" />
        <TextField label="Type" source="taskType" />
        <TextField
          emptyText="-"
          label="Stint count"
          source="totalNumberPostedTask"
        />
        <TextField
          emptyText="-"
          label="Business count"
          source="totalNumberBusinessesPosted"
        />
        <TextField
          emptyText="-"
          label="Avg. rating S -> B"
          source="averageStudentRatingPosted"
        />
        <TextField
          emptyText="-"
          label="Avg. rating B -> S"
          source="averageBusinessRatingPosted"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

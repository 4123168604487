import React, { useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import { useMutation } from 'react-admin';

import {
  usesendOtpEmailMutation,
  useverifyStudentUserMutation,
} from 'apollo/schema';
import { AsideActions } from 'components';
import { StinterStrikesAwarded } from 'components/models/Offence';
import { StatusCard } from 'components/models/RightToWork';

import { isStudentRecord } from '../../Student.types';
import {
  Column,
  Container,
  WrapButton,
} from './StudentEditAsideActions.styles';
import { StudentEditAsideActionsProps } from './StudentEditAsideActions.types';

export const StudentEditAsideActions = ({
  record,
}: StudentEditAsideActionsProps) => {
  if (!record) {
    return null;
  }

  /**
   * This component is used both on the StintUser page and Student page
   * this logic is to determine which data to use as the record.
   */
  const user = isStudentRecord(record) ? record.user! : record;
  const { studentProfile: stinterProfile, dateOfBirth } = user;

  const [hasSentPasswordReset, setHasSentPasswordReset] = useState(false);
  const [profileDeactived, setProfileDeactived] = useState(
    user?.deactivated || record?.enabled === false,
  );
  const isReady = user?.isVerifiedByStint !== undefined;
  const [
    onClickVerifyStudent,
    { data: studentVerified },
  ] = useverifyStudentUserMutation({
    fetchPolicy: 'no-cache',
  });

  const [onClickSendPasswordEmail] = usesendOtpEmailMutation({
    fetchPolicy: 'no-cache',
  });

  const [onClickToggleActivate] = useMutation({
    type: 'update',
    resource: 'StintUser',
    payload: {
      id: user.id,
      data: {
        id: user.id,
        deactivated: !profileDeactived,
      },
    },
  });

  const handleToggleUserActivation = () => {
    onClickToggleActivate();
    setProfileDeactived(!profileDeactived);
  };

  const handleVerifyStudent = () => {
    onClickVerifyStudent({
      variables: { studentUserIds: [user.id] },
    });
  };

  const handleClickPasswordReset = async () => {
    if (user.email) {
      onClickSendPasswordEmail({
        variables: { email: user.email },
      });
      setHasSentPasswordReset(true);
    }
  };

  return (
    <Column>
      <AsideActions>
        {!dateOfBirth ? (
          <Tooltip
            data-testid="test-tooltip-nodob"
            title="This button is probably disabled because user didn't added their date of birth"
          >
            <Container>
              <WrapButton
                data-testid="test-verify-nodob"
                disabled
                label="Verify student"
                onClick={handleVerifyStudent}
              />
            </Container>
          </Tooltip>
        ) : (
          !studentVerified?.data?.success &&
          !user?.isVerifiedByStint && (
            <Container>
              <WrapButton
                data-testid="test-verify"
                disabled={!dateOfBirth && !isReady}
                label="Verify student"
                onClick={handleVerifyStudent}
              />
            </Container>
          )
        )}
        <Container>
          <WrapButton
            disabled={!isReady}
            label={
              profileDeactived ? 'Activate Student' : 'De-Activate Student'
            }
            onClick={handleToggleUserActivation}
          />
        </Container>
        <Container>
          <WrapButton
            data-testid="test-button-reset-password"
            disabled={!isReady || hasSentPasswordReset}
            label="Send password reset link"
            onClick={handleClickPasswordReset}
          />
        </Container>
      </AsideActions>
      <Box m="0 0 1em 1em">
        <StatusCard
          stinterMonoId={user.id}
          stinterRTWStatus={record.rightToWork}
        />
      </Box>
      <Box m="0 0 1em 1em">
        <StinterStrikesAwarded
          strikesAwarded={stinterProfile?.strikesAwarded}
        />
      </Box>
    </Column>
  );
};

import gql from 'graphql-tag';

import { buildQuery } from './utils';

const BusinessFields = gql`
  fragment AdminBusinessFields on Business {
    id
    address {
      cityFk {
        id
        name
      }
      postalCode
    }
    city {
      id
      name
      baseFee
    }
    averageRating
    createdAt
    ltdName
    displayAddress
    exemptFromPaymentDetails
    integrated
    name
    logo
    repeatStintersEnabled
    phoneNumber
    placeId
    qrCodeId
    rateOverride {
      rate
    }
    salesRepresentitive {
      id
      email
      firstName
      lastName
    }
    status
    unlockedLastMinuteStints
    controlCheck
    isBlockedByFinance
    stintopedia {
      id
      bookedOnboardingMeeting
      usingStintCorrectly
    }
  }
`;

const listQuery = gql`
  query allBusinesses(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: BusinessFilter
  ) {
    items: allBusinesses(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminBusinessFields
    }
    total: _allBusinessesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${BusinessFields}
`;

const singleItemQuery = gql`
  query Business($id: ID!) {
    data: Business(id: $id) {
      ...AdminBusinessFields
    }
  }
  ${BusinessFields}
`;

const updateQuery = gql`
  mutation updateBusiness(
    $id: ID!
    $businessLogo: String
    $displayAddress: String
    $exemptFromPaymentDetails: Boolean
    $ltdName: String
    $name: String
    $phoneNumber: String
    $placeId: String
    $rateOverride: BusinessRateOverrideInput
    $status: BusinessStatusChoices
    $integrated: Boolean
    $unlockedLastMinuteStints: Boolean
    $controlCheck: Boolean
    $isBlockedByFinance: Boolean
    $stintopedia: StintopediaInput
    $repeatStintersEnabled: Boolean
  ) {
    data: updateBusiness(
      id: $id
      businessLogo: $businessLogo
      displayAddress: $displayAddress
      exemptFromPaymentDetails: $exemptFromPaymentDetails
      ltdName: $ltdName
      name: $name
      phoneNumber: $phoneNumber
      placeId: $placeId
      rateOverride: $rateOverride
      status: $status
      integrated: $integrated
      unlockedLastMinuteStints: $unlockedLastMinuteStints
      controlCheck: $controlCheck
      isBlockedByFinance: $isBlockedByFinance
      stintopedia: $stintopedia
      repeatStintersEnabled: $repeatStintersEnabled
    ) {
      ...AdminBusinessFields
    }
  }
  ${BusinessFields}
`;

export const businesses = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
});

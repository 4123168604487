import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  maxLength,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';

import { BodyText, Label } from 'components';
import {
  getUniversityLabel,
  STUDENT_EMPLOYEE_STATEMENT_CHOICES,
  STUDENT_LOAN_PLAN_CHOICES,
} from 'components/models/Student/utils';
import { getNullableBooleanDisplayValue } from 'utils';

export const StudentDetailsTab: React.FC<{ hasOnboarded: boolean }> = ({
  hasOnboarded,
}) => {
  return (
    <Column>
      {(!hasOnboarded && (
        <BodyText bold>
          The user has not yet logged in for the first time, so they cannot be
          edited
        </BodyText>
      )) || <TextInput disabled label="ID (from mono)" source="user.id" />}
      <TextInput
        disabled
        label="Authentication ID (from Keycloak)"
        source="id"
      />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <FormDataConsumer>
        {data => (
          <TextInput
            label={getUniversityLabel(data?.formData?.emailVerified)}
            source="email"
            validate={required()}
          />
        )}
      </FormDataConsumer>
      <TextInput disabled label="Uni Email - deprecated" source="user.email" />
      <TextInput
        label="Phone Number"
        source="user.phoneNumber"
        validate={required()}
      />
      <TextInput
        label="Date of Birth (YYYY-MM-DD)"
        source="user.dateOfBirth"
        validate={required()}
      />
      <Label labelText="Testing" />
      {hasOnboarded &&
        (
          <>
            <Label labelText="Can See Surge" />
            <FormDataConsumer>
              {({ formData }: any) => {
                const displayText = getNullableBooleanDisplayValue(
                  formData?.user?.canSeeSurge ?? formData?.canSeeSurge,
                );
                return (
                  <BodyText data-testid="can-see-surge">{displayText}</BodyText>
                );
              }}
            </FormDataConsumer>
            <ReferenceInput
              filterToQuery={searchText => ({
                name_istartswith: searchText,
              })}
              fullWidth
              label="University"
              reference="University"
              sort={{ field: 'name', order: 'DESC' }}
              source="user.studentProfile.university_id"
            >
              <AutocompleteInput suggestionLimit={5} />
            </ReferenceInput>
            <NumberInput
              disabled
              label="Average Rating"
              source="user.publicRating"
            />
            <ReferenceArrayInput
              fullWidth
              label="Exceptions"
              perPage={400}
              reference="EmploymentException"
              source="user.exceptionsIds"
            >
              <AutocompleteArrayInput optionText="name" suggestionLimit={5} />
            </ReferenceArrayInput>
            <SelectInput
              choices={STUDENT_LOAN_PLAN_CHOICES}
              label="Student Loan Plan"
              source="user.studentProfile.studentLoanPlan"
            />
            <SelectInput
              allowEmpty
              choices={STUDENT_EMPLOYEE_STATEMENT_CHOICES}
              label="Student Employee Statement"
              source="user.studentProfile.employeeStatement"
            />
            <Divider />
            <Box my={{ xs: '2em' }}>
              <Typography variant="subtitle1">
                <strong>Bank Details</strong>
              </Typography>
            </Box>
            <TextInput
              label="Account Name"
              source="user.studentProfile.paymentMethods.0.accountName"
            />
            <TextInput
              label="Account Number"
              source="user.studentProfile.paymentMethods.0.accountNumber"
              validate={maxLength(8)}
            />
            <TextInput
              label="Sort Code"
              source="user.studentProfile.paymentMethods.0.sortCode"
              validate={maxLength(6)}
            />
            <Divider />
            <Box my={{ xs: '2em' }}>
              <Typography variant="subtitle1">
                <strong>Emergency contact details</strong>
              </Typography>
            </Box>
            <TextInput
              label="Name"
              source="user.studentProfile.emergencyContactName"
            />
            <TextInput
              label="Contact number"
              source="user.studentProfile.emergencyContactNumber"
            />
            <TextInput
              label="Relationship"
              source="user.studentProfile.emergencyContactRelation"
            />
          </>
        ).props.children}
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 512px;
`;

import gql from 'graphql-tag';

import { updateQuery } from './users';
import { buildQuery } from './utils';

const singleItemQuery = gql`
  query StudentUser($id: ID!) {
    mono: studentUser(userId: $id) {
      id
      firstName
      lastName
      email
      emailVerified
      enabled
      attributes {
        name
        value
      }
      user {
        id
        email
        secondaryEmail
        locationSetting
        profilePhotoUrl
        phoneNumber
        deactivated
        isVerifiedByStint
        permanentDeactivated
        appVersion
        dateOfBirth
        exceptions {
          id
          name
        }
        studentProfile {
          id
          university_id
          emergencyContactName
          emergencyContactNumber
          emergencyContactRelation
          employeeStatement
          studentLoanPlan
          strikesAwarded
          paymentMethods {
            accountName
            accountNumber
            sortCode
            __typename
          }
          __typename
        }
        publicRating
        canSeeSurge
        __typename
      }
    }
    rightToWork: getStinterRightToWorkStatus(stinterID: $id) {
      until
      weeklyCap
      approvedAt
    }
  }
`;

const listQuery = gql`
  query allStudentUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: String
  ) {
    items: allStudentUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      firstName
      lastName
      email
      attributes {
        name
        value
      }
      user {
        id
        dateOfBirth
        profilePhotoUrl
        appVersion
        __typename
      }
    }
    total: _allStudentUserMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;

const singleItemParseResponse = (response: any) => {
  return {
    data: { ...response.data.mono, rightToWork: response.data.rightToWork },
  };
};
export const students = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
  singleItemParseResponse,
});

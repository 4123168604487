import { IS_PRODUCTION } from './environment';

declare let window: any;

export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const AUTH_BACKWARD_COMPATIBILITY_MODE = true;

const DEFAULT_AUTH_CLIENT = 'stint-admin-console';

export const DEFAULT_AUTH_SERVICE_URL = window?.LOCAL_SETTINGS?.AUTH_SERVICE;
export const AUTH_SERVICE_CLIENT_ID = IS_PRODUCTION
  ? window?.LOCAL_SETTINGS?.AUTH_CLIENT
  : DEFAULT_AUTH_CLIENT;

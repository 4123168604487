import React from 'react';

import {
  AutocompleteInput,
  Filter,
  FilterProps,
  ReferenceInput,
} from 'react-admin';

export const StintopediaFilter: React.FC<
  Omit<FilterProps, 'children'>
> = props => (
  <Filter {...props}>
    <ReferenceInput
      allowEmpty
      alwaysOn
      label="Business"
      reference="Business"
      source="business_id"
    >
      <AutocompleteInput emptyText="-" optionText="name" />
    </ReferenceInput>
  </Filter>
);

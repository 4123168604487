import React, { FC } from 'react';

import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

import { DateTimeField, LinkTextField } from 'components/atoms';
import { AppealTable } from 'components/models/Appeal';

import { OffenceAppealButtonField } from '../OffenceAppealButtonField';
import { OffenceAppealStatusField } from '../OffenceAppealStatusField';
import { OffenceReasonField } from '../OffenceReasonField';
import { OffenceStrikesAwardedField } from '../OffenceStrikesAwardedField';

export const OffenceShow: FC<ShowProps> = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Offence ID" data-testid="offenceId" />
        <DateTimeField
          source="createdAt"
          label="Created At"
          showTime={true}
          data-testid="createdAt"
        />
        <TextField
          source="createdBy.fullname"
          label="Created By"
          data-testid="createdBy"
        />
        <OffenceReasonField
          source="reason"
          label="Reason"
          data-testid="reason"
        />
        <OffenceStrikesAwardedField
          label="Strikes Awarded"
          data-testid="strikesAwarded"
        />
        <LinkTextField
          data-testid="businessId"
          label="Business ID"
          to={record =>
            record.business?.id ? `/Business/${record.business.id}/show` : null
          }
          text={record => (record.business?.id ? record.business.id : '')}
        />
        <TextField
          source="business.name"
          label="Business Name"
          data-testid="businessName"
        />
        <LinkTextField
          data-testid="stintId"
          label="Stint ID"
          to={record =>
            record.stint?.id ? `/Stint/${record.stint.id}/show` : null
          }
          text={record => (record.stint?.id ? record.stint.id : '')}
        />
        <TextField
          source="stint.stintTimesDisplay"
          label="Stint Date"
          data-testid="stintTimesDisplay"
        />
        <OffenceAppealStatusField
          source="appealStatus"
          label="Appeal Status"
          data-testid="appealStatus"
        />
        <OffenceAppealButtonField
          data-testid="appeal_button"
          label="Action"
          sortable={false}
        />
        <AppealTable />
      </SimpleShowLayout>
    </Show>
  );
};

import React from 'react';

import { FunctionField } from 'react-admin';

import { AppealStatus } from 'apollo/schema';
import { Chip } from 'components/atoms';
import { OffenceQueryAppeal } from 'components/models/Offence';

import { AppealFunctionFieldProps } from '../Appeal.types';
import { mapAppealStatus } from './mapping';

/*
AppealStatusField renders a field that converts the AppealStatus to a human readable string.

This is expecting the Appeal to be the provided data source.
*/
export const AppealStatusField: React.FC<AppealFunctionFieldProps> = props => {
  return (
    <FunctionField<OffenceQueryAppeal>
      {...props}
      render={record => {
        if (record) {
          const label = mapAppealStatus(record.status);
          switch (record.status) {
            case AppealStatus.UNSUCCESSFUL:
              return <Chip label={label} variant="error" />;
            case AppealStatus.SUCCESSFUL:
              return <Chip label={label} variant="success" />;
            default:
              return <Chip label={label} variant="default" />;
          }
        }
      }}
    />
  );
};

AppealStatusField.defaultProps = FunctionField.defaultProps;

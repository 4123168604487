import React, { FC, useEffect } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SpeedIcon from '@material-ui/icons/Speed';
import UpdateIcon from '@material-ui/icons/Update';
import { useNotify } from 'react-admin';

import { StinterRTWStatus } from 'apollo/schema';
import { DateTimeField, LoadingIndicator } from 'components';
import { colors } from 'styles';

import { StatusCardProps } from './StatusCard.types';
import { RTW_STATUS_QUERY } from './StatusCard.utils';

export const StatusCard: FC<StatusCardProps> = ({
  stinterRTWStatus,
  stinterMonoId,
}) => {
  const notify = useNotify();
  const [getRTWStatus, { data, loading }] = useLazyQuery<{
    getStinterRightToWorkStatus: StinterRTWStatus;
  }>(RTW_STATUS_QUERY, {
    variables: { id: stinterMonoId },
    onError: err => {
      notify(err.message, 'error');
    },
  });

  /**
   * required to get around the useQuery `skip` option not working
   * and still making the http request
   * https://github.com/apollographql/apollo-client/issues/6190
   */
  useEffect(() => {
    if (!stinterRTWStatus) {
      getRTWStatus();
    }
  }, [stinterRTWStatus]);

  const rtwStatusData = stinterRTWStatus
    ? stinterRTWStatus
    : data?.getStinterRightToWorkStatus;

  if (loading) {
    return <LoadingIndicator />;
  }
  const { until, approvedAt, weeklyCap } = rtwStatusData ?? {};
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          Right to work
        </Typography>
        <Box flexGrow={1}>
          <Box display="flex" mb="1em">
            <Box mr="1em">
              <PlaylistAddCheckIcon color="disabled" fontSize="small" />
            </Box>
            <Box flexGrow={1}>
              <Typography component="h3">Can work in the UK</Typography>
              <Typography
                style={{
                  color: rtwStatusData ? colors.success : colors.errorDark,
                }}
                variant="h5"
                component="p"
              >
                {rtwStatusData ? <>Yes</> : <>No</>}
              </Typography>
            </Box>
          </Box>
        </Box>
        {approvedAt && (
          <Box flexGrow={1}>
            <Box display="flex" mb="1em">
              <Box mr="1em">
                <EventIcon color="disabled" fontSize="small" />
              </Box>
              <Box flexGrow={1}>
                <Typography component="h3">Checked on</Typography>
                <Typography component="p">
                  <DateTimeField
                    record={{ id: stinterMonoId, approvedAt }}
                    showTime
                    sortable={false}
                    source="approvedAt"
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {weeklyCap && (
          <Box flexGrow={1}>
            <Box display="flex" mb="1em">
              <Box mr="1em">
                <SpeedIcon color="disabled" fontSize="small" />
              </Box>
              <Box flexGrow={1}>
                <Typography component="h3">Weekly cap</Typography>
                <Typography component="p">{`${weeklyCap} hours`}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {until && (
          <Box flexGrow={1}>
            <Box display="flex" mb="1em">
              <Box mr="1em">
                <UpdateIcon color="disabled" fontSize="small" />
              </Box>
              <Box flexGrow={1}>
                <Typography component="h3">Expire</Typography>
                <Typography component="p">
                  <DateTimeField
                    record={{ id: stinterMonoId, until }}
                    sortable={false}
                    source="until"
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

/** *********  START Geotracker live map config ************ */

// Number of most recent location samples to fetch initially for live location map
export const NUMBER_OF_INITIAL_HISTORICAL_LOCATION_SAMPLES_TO_FETCH = 1000;

// Number of samples to fetch every 10 seconds to add to initial historical location
// trail on live map
export const NUMBER_OF_RECENT_HISTORICAL_LOCATION_SAMPLES_TO_FETCH = 20;

// Refresh interval to fetch new locations updates for live map
export const LIVE_MAP_LOCATION_REFRESH_RATE_IN_SECONDS = 10;

// All locations older then (stint start - the value below) will be excluded
// from live map historical trail.
export const NUMBER_OF_MINUTES_BEFORE_STINT_START_TO_ALLOW_LOCATIONS_ON_MAP = 125;

import 'react-image-crop/dist/ReactCrop.css';

import React, { ComponentProps, ReactElement, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ReactCrop from 'react-image-crop';

import { useCrop } from 'hooks/useCrop';

import { CircleImage } from '../CircleImage';
import { ImageDropZone } from '../ImageDropZone';
import { LogoControlButtons } from './LogoControlButtons';
import {
  ButtonsWrapper,
  LogoContainer,
  LogoCropWrapper,
} from './LogoUpload.styles';

interface Props
  extends Omit<ComponentProps<typeof CircleImage>, 'onLoad' | 'onError'> {
  alt?: string;
  inputE2ETestId?: string;
  onError?: (error: Error) => void;
  onLoad: (value: string) => void;
  size?: number;
}

const DEFAULT_CROP_MODAL_VISIBLE = false;
const DEFAULT_IMAGE_SIZE = 120;

export const LogoUpload: React.FC<Props> = ({
  inputE2ETestId,
  onLoad,
  onError = () => {},
  size = DEFAULT_IMAGE_SIZE,
  ...rest
}): ReactElement => {
  const [cropModalVisible, setCropModalVisible] = useState(
    DEFAULT_CROP_MODAL_VISIBLE,
  );
  const {
    crop,
    handleLoad,
    onImageLoaded,
    onMakeClientCrop,
    originalImageAsURLDataRef,
    setCrop,
  } = useCrop({ onError, onLoad, setCropModalVisible });

  const onClose = () => {
    setCropModalVisible(false);
  };

  return (
    <>
      <ImageDropZone onError={onError} onLoad={handleLoad}>
        <LogoContainer size={size}>
          <LogoControlButtons inputE2ETestId={inputE2ETestId} onLoad={onLoad} />
          <CircleImage {...rest} size={size} />
        </LogoContainer>
      </ImageDropZone>
      <Dialog
        aria-describedby="crop-image-description"
        aria-labelledby="crop-image-title"
        onClose={onClose}
        open={cropModalVisible}
      >
        <DialogTitle>Crop Image</DialogTitle>
        <LogoCropWrapper>
          <ReactCrop
            crop={crop}
            keepSelection
            onChange={setCrop}
            onImageLoaded={onImageLoaded}
            src={originalImageAsURLDataRef.current}
          />
        </LogoCropWrapper>
        <ButtonsWrapper>
          <IconButton
            aria-label="cancel-picture"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </IconButton>
          <IconButton
            aria-label="save-picture"
            color="secondary"
            onClick={onMakeClientCrop}
          >
            Save
          </IconButton>
        </ButtonsWrapper>
      </Dialog>
    </>
  );
};

import React from 'react';

import { Datagrid, ReferenceManyField, TextField } from 'react-admin';

import { Offence } from 'apollo/schema';
import { LinkTextField } from 'components/atoms';

import { OffenceAppealButtonField } from '../OffenceAppealButtonField';
import { OffenceAppealStatusField } from '../OffenceAppealStatusField';
import { OffenceReasonField } from '../OffenceReasonField';
import { OffenceStrikesAwardedField } from '../OffenceStrikesAwardedField';
import { StinterOffencesTableProps } from './StinterOffencesTable.types';

export const StinterOffencesTable: React.FC<StinterOffencesTableProps> = props => {
  if (!props.stinterProfile) {
    return null;
  }

  return (
    <div data-testid="stinter-offences-table">
      <ReferenceManyField
        basePath="/Offence"
        fullWidth
        perPage={100}
        record={props.stinterProfile}
        reference="Offence"
        target="id"
      >
        <Datagrid>
          <OffenceStrikesAwardedField
            data-testid="number-of-strikes-awarded-for-offence"
            label="No. of strikes awarded"
            sortable={false}
          />
          <OffenceReasonField
            data-testid="reason-for-offence"
            label="Reason"
            sortable={false}
            source="reason"
          />
          <LinkTextField
            data-testid="stint-date-and-time-for-offence"
            label="Stint date & time"
            to={(record: Offence) =>
              record.stint?.id ? `/Stint/${record.stint.id}/show` : null
            }
            text={(record: Offence) =>
              record.stint?.stintTimesDisplay
                ? record.stint.stintTimesDisplay
                : ''
            }
          />
          <TextField label="Business" sortable={false} source="business.name" />
          <OffenceAppealStatusField
            data-testid="appeal_status"
            label="Appeal Status"
            sortable={false}
          />
          <OffenceAppealButtonField
            data-testid="appeal_button"
            label="Action"
            sortable={false}
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

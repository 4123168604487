import React from 'react';

import { EditProps, FormTab, SimpleForm, TabbedForm } from 'react-admin';

import { StintUser } from 'apollo/schema';
import { Edit } from 'components';
import { UserEditToolbar } from 'components/models/Users/UserEditToolbar';

import { Performance } from '../Performance';
import { StudentRecord } from '../Student.types';
import { StudentCompletedStintsList } from '../StudentCompletedStintsList';
import { RightToWork } from '../StudentRightToWork';
import { StudentUpcomingStintsList } from '../StudentUpcomingStintsList';
import { StudentDetailsTab } from './StudentDetailsTab';
import { StudentEditAsideActions } from './StudentEditAsideActions';
import { transformStudentDataBeforeSave } from './utils';

type StudentEditFormProp = {
  record: StudentRecord;
};

export const StudentEditForm: React.FC<
  EditProps & StudentEditFormProp
> = props => {
  const transformDataBeforeSave = (userData: StintUser) => {
    return transformStudentDataBeforeSave(userData);
  };
  const { record: stintUser, ...restProp } = props;
  return (
    <Edit
      {...restProp}
      aside={<StudentEditAsideActions />}
      transform={data => transformDataBeforeSave(data as StintUser)}
    >
      {(!stintUser?.user && (
        <SimpleForm toolbar={<UserEditToolbar />}>
          <StudentDetailsTab hasOnboarded={false} />
        </SimpleForm>
      )) || (
        <TabbedForm toolbar={<UserEditToolbar />}>
          <FormTab data-testid="student-details-tab" label="Details">
            <StudentDetailsTab hasOnboarded />
          </FormTab>
          <FormTab data-testid="upcoming-stints-tab" label="Upcoming Stints">
            <StudentUpcomingStintsList />
          </FormTab>
          <FormTab data-testid="completed-stints-tab" label="Completed Stints">
            <StudentCompletedStintsList />
          </FormTab>
          <FormTab label="Right To Work">
            <RightToWork />
          </FormTab>
          <FormTab data-testid="performance-tab" label="Performance">
            <Performance />
          </FormTab>
        </TabbedForm>
      )}
    </Edit>
  );
};

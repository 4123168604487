import { AppealStatus } from 'apollo/schema';

const reasonMap = new Map<AppealStatus, string>([
  [AppealStatus.PENDING, 'Pending'],
  [AppealStatus.SUCCESSFUL, 'Successful'],
  [AppealStatus.UNSUCCESSFUL, 'Unsuccessful'],
]);

export const mapAppealStatus = (reason: AppealStatus): string => {
  const mapped = reasonMap.get(reason);
  if (!mapped) {
    return '';
  }

  return mapped;
};

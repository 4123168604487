import React, { FC, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import {
  DateFieldProps as RADateFieldProps,
  sanitizeFieldRestProps,
} from 'react-admin';

export const DEFAULT_DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm';
export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
export const TIMEZONE = 'Europe/London';

export interface DateFieldProps extends RADateFieldProps {
  dateTimeFormat?: string;
}

export const DateTimeField: FC<DateFieldProps> = memo<DateFieldProps>(
  ({
    className,
    dateTimeFormat,
    emptyText,
    record,
    showTime = false,
    source,
    ...rest
  }) => {
    const formatDateTime = (date: Date): string => {
      const dateMoment = moment(date).tz(TIMEZONE);
      if (dateTimeFormat) {
        return dateMoment.format(dateTimeFormat);
      }
      if (showTime) {
        return dateMoment.format(DEFAULT_DATE_TIME_FORMAT);
      }
      return dateMoment.format(DEFAULT_DATE_FORMAT);
    };

    if (!record) {
      return null;
    }

    const value = record?.[source!];
    if (value == null) {
      return emptyText ? (
        <Typography
          className={className}
          component="span"
          variant="body2"
          {...rest}
        >
          {emptyText}
        </Typography>
      ) : null;
    }

    const dateString = formatDateTime(value);

    return (
      <Typography
        className={className}
        component="span"
        variant="body2"
        {...sanitizeFieldRestProps(rest)}
      >
        {dateString}
      </Typography>
    );
  },
);

DateTimeField.displayName = 'DateTimeField';

DateTimeField.defaultProps = {
  addLabel: true,
};

import React, { FC, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import LaunchIcon from '@material-ui/icons/Launch';
import { Button } from 'react-admin';
import styled from 'styled-components';

import { ViewUserDialog } from '../ViewUserDialog';

type Props = {
  userType: 'Student' | 'Employer';
};

export const ViewSpecificUserID: FC<Props> = ({ userType }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSubmit = (userID: string) => {
    if (userID) {
      document.location.hash = `#/StintUser/${userID}`;
    }
    setDialogOpen(false);
  };
  return (
    <>
      <DialogContainer>
        <Button
          label="View a specific user ID"
          onClick={() => {
            setDialogOpen(true);
          }}
          size="large"
          startIcon={<LaunchIcon />}
        />
        <Dialog
          aria-describedby="prompt-dialog-description"
          aria-labelledby="prompt-dialog-title"
          fullWidth
          onClose={() => null}
          open={dialogOpen}
        >
          <ViewUserDialog
            onClose={() => setDialogOpen(false)}
            onSubmit={userID => handleSubmit(userID)}
            userType={userType}
          />
        </Dialog>
      </DialogContainer>
    </>
  );
};

const DialogContainer = styled.div``;

import React from 'react';

import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

import { CountryFilter } from '../CountryFilter';

export const CountryList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<CountryFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="shortCode" />
        <TextField emptyText="-" source="regions" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

import endsWith from 'lodash/endsWith';
import isURL from 'validator/lib/isURL';
import isUUID from 'validator/lib/isUUID';

export const uuidVersion = 4;

export const validateUrl = (message = 'Must be a valid URL') => (
  url: string,
) => {
  return !url || isURL(url) ? undefined : message;
};

export const validateId = (message = 'Must be a valid ID') => (id: string) => {
  return !id || isUUID(id, uuidVersion) ? undefined : message;
};

export const validateRawIdList = (message = 'Some ids are not valid') => (
  ids: string[],
) => {
  if (!ids) {
    return undefined;
  }
  const invalidId = ids.find(id => !isUUID(id, uuidVersion));
  return invalidId ? `${message}: ${invalidId}` : undefined;
};

export const validateUniform = (value: string[]) => {
  const tops = ['WHITE_FORMAL_SHIRT', 'BLACK_FORMAL_SHIRT', 'BLACK_T_SHIRT'];
  const bottoms = ['BLACK_FORMAL_TROUSERS', 'BLACK_JEANS'];
  const shoes = ['DARK_TRAINERS', 'BLACK_FORMAL_SHOES'];

  if (!value || !value.length) {
    return undefined;
  }

  const hasTop = value.some(top => tops.includes(top));
  const hasBottom = value.some(bottom => bottoms.includes(bottom));
  const hasShoe = value.some(shoe => shoes.includes(shoe));

  if (!hasTop || !hasBottom || !hasShoe) {
    return 'A trouser, top and shoe option must all be selected';
  }
  if (hasTop && hasBottom && hasShoe) {
    return undefined;
  }
  return undefined;
};

export const csvValidator = (file: File) => {
  if (!endsWith(file?.name, '.csv')) {
    return {
      code: 'invalid-format',
      message: 'This file is not a csv',
    };
  }

  return null;
};

import React, { ComponentProps, FC } from 'react';

import ContentCreate from '@material-ui/icons/Create';
import { linkToRecord } from 'ra-core';
import { Button, EditButton } from 'react-admin';
import { Link } from 'react-router-dom';

const defaultIcon = <ContentCreate />;

type Props = ComponentProps<typeof EditButton> & {
  editIcon?: JSX.Element;
};

export const EditWithBackLinkButton: FC<Props> = ({
  basePath = '',
  editIcon = defaultIcon,
  label = 'ra.action.edit',
  record,
  ...rest
}) => {
  const link = linkToRecord(basePath, record?.id);
  return (
    <Button
      component={Link}
      label={label}
      onClick={event => event.stopPropagation()}
      to={`${link}?back=${window.location.hash.replace(/#/, '')}`}
      {...rest}
    >
      {editIcon}
    </Button>
  );
};

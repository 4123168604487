import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-admin';

interface EmployerDeactivationDialogProps {
  onClose: VoidFunction;
  onSave: VoidFunction;
}

export const EmployerDeactivationDialog: React.FC<EmployerDeactivationDialogProps> = ({
  onClose,
  onSave,
}) => {
  return (
    <>
      <DialogContent>
        <Typography gutterBottom variant="subtitle1">
          By disabling user login, the user will not be able to access their
          Stint account anymore. This action can be undone at any time on this
          page.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="default" label="Close" onClick={onClose} />
        <Button autoFocus color="primary" label="Confirm" onClick={onSave} />
      </DialogActions>
    </>
  );
};

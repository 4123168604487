import React from 'react';

import {
  BooleanField,
  Datagrid,
  EmailField,
  List,
  ListProps,
  ShowButton,
  TextField,
} from 'react-admin';

import { StaffFilter } from './StaffFilter';

export const StaffList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<StaffFilter />}>
      <Datagrid
        data-testid="staff-table"
        isRowSelectable={() => false}
        rowClick="show"
      >
        <TextField
          data-testid="staff-firstname"
          label="First name"
          sortable={false}
          source="firstName"
        />
        <TextField
          data-testid="staff-lastname"
          label="Last name"
          sortable={false}
          source="lastName"
        />
        <EmailField
          data-testid="staff-email"
          label="Email"
          sortable={false}
          source="email"
        />
        <BooleanField
          data-testid="staff-enabled"
          label="Enabled"
          sortable={false}
          source="enabled"
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

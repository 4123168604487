import React from 'react';

import {
  Datagrid,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  TextField,
} from 'react-admin';

import { PeriodField } from 'components';
import { UserRecord } from 'components/models/Users';
import { FormInputProps } from 'components/types';
import { STINT_STATUS_CHOICES } from 'utils';

import { StudentRecord } from '../Student.types';
import { StintReviewTypeField } from './StintReviewTypeField';
import { StudentStintsFilter } from './StudentStintsFilters';

export const StudentCompletedStintsList = (
  props: FormInputProps<StudentRecord | UserRecord>,
) => {
  /**
   * This component is used both on the StintUser page and Student page
   * this logic is to determine which data to use as the record.
   */
  const record = props?.record?.user || props?.record;
  return (
    <ReferenceManyField
      basePath="/StudentReview"
      filter={{
        student_id: record.id,
      }}
      fullWidth
      label=""
      pagination={<Pagination />}
      perPage={10}
      record={record}
      reference="StudentReview"
      sortBy="stint.date_from"
      target="student_id"
    >
      <>
        <StudentStintsFilter />
        <Datagrid data-testid="student-completed-stints-table">
          <ReferenceField
            data-testid="business-id"
            label="Business"
            reference="Business"
            source="stint.business_id"
          >
            <TextField source="name" />
          </ReferenceField>
          <PeriodField
            data-testid="stint-period"
            endDateFormat="HH:mm"
            endDateSource="stint.dateTo"
            label="Stint date & time"
            sortBy="stint.date_from"
            startDateFormat="DD/MM/YYYY HH:mm"
            startDateSource="stint.dateFrom"
          />
          <SelectField
            choices={STINT_STATUS_CHOICES}
            data-testid="stint-status"
            label="Status"
            source="stint.status"
          />
          <StintReviewTypeField data-testid="review-type" label="Review" />
          <NumberField
            data-testid="rating"
            emptyText="-"
            label="Rating"
            source="rating"
          />
        </Datagrid>
      </>
    </ReferenceManyField>
  );
};

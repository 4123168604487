import { StudentsEdit } from './StudentsEdit';
import { StudentsFilter } from './StudentsFilter';
import { StudentsList } from './StudentsList';
import { StudentsShow } from './StudentsShow';

export const list = StudentsList;
export const edit = StudentsEdit;
export const show = StudentsShow;
export const filter = StudentsFilter;

export type { StudentRecord } from './Student.types';

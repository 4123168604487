export const MODAL_OPEN_BUTTON_TEXT = 'Select Leave Time';
export const MODAL_TITLE = 'Select Leave Time';
export const ACTUAL_LEAVE_TIME_INPUT_LABEL = 'Actual Leave Time';
export const UPDATE_ACTUAL_LEAVE_TIME_SUCCESS_MSG =
  'Actual Leave Time has successfully been updated.';
export const UPDATE_ACTUAL_LEAVE_TIME_FAILED_MSG =
  'There was a problem updating the Actual Leave Time.';
export const ACTUAL_LEAVE_TIME_BEFORE_START_FAILED_MSG =
  'Please select a leave time that is after the starting time of the Stint.';
export const INVALID_ACTUAL_LEAVE_TIME_FROM_END_FAILED_MSG =
  'Please select a leave time that is no more than 8 hours from the ending time of the Stint.';
export const UPDATE_ACTUAL_LEAVE_TIME_HELP_TEXT =
  'The Actual Leave Time can not be set before the start time of the Stint and not more than 8 hours from the end time of the Stint.';
export const ACTUAL_LEAVE_TIME_BEFORE_START_ERROR_CODE =
  'ACTUAL_LEAVE_TIME_BEFORE_START';
export const INVALID_ACTUAL_LEAVE_TIME_FROM_END_ERROR_CODE =
  'INVALID_ACTUAL_LEAVE_TIME_FROM_END';

import React, { FC, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import { SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';

import { NotificationConfirmationDialog } from '../NotificationConfirmationDialog';

export const SaveWithConfirmationDialog: FC<any> = props => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const formState = useFormState();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSave = () => {
    handleClose();
    props.handleSubmit();
  };

  return (
    <>
      <SaveButton
        {...props}
        handleSubmitWithRedirect={() => setDialogOpen(true)}
      />
      <Dialog
        aria-describedby="confirmation-dialog-description"
        aria-labelledby="confirmation-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={dialogOpen}
      >
        {!!formState.values && (
          <NotificationConfirmationDialog
            onClose={handleClose}
            onSave={handleSave}
            values={formState.values}
          />
        )}
      </Dialog>
    </>
  );
};

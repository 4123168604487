import React, { FC } from 'react';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { SearchResultTextFragment } from './SearchResultText.styles';

interface Props {
  optionText: string;
  value: string;
}

export const SearchResultText: FC<Props> = ({ optionText, value }) => {
  const matches = match(optionText, value);
  const parts = parse(optionText, matches).map((part, key) => ({
    ...part,
    key,
  }));

  return (
    <>
      {parts.map(part => (
        <SearchResultTextFragment highlight={part.highlight} key={part.key}>
          {part.text}
        </SearchResultTextFragment>
      ))}
    </>
  );
};

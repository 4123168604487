import React, { ComponentProps, FC, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import { Button, useMutation } from 'react-admin';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';

import { colors } from 'styles';
import { STINT_STATUS } from 'utils';

import { ConfirmationDialog } from '../ConfirmationDialog';

type Props = {
  onCancelStint?: VoidFunction;
} & ComponentProps<typeof Button>;

export const CancelStintButton: FC<Props> = ({ onCancelStint }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const formState = useFormState();
  const stintId = formState?.values?.id;

  const [cancelStint, { loading, error }] = useMutation({
    type: 'update',
    resource: 'Stint',
    payload: {
      id: stintId,
      data: {
        id: stintId,
        status: STINT_STATUS.CANCELLED,
        controlCheck: false,
      },
    },
  });

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSave = () => {
    handleClose();
    cancelStint();
    onCancelStint?.();
  };

  return (
    <>
      <CancelButton
        color="default"
        label="Cancel"
        onClick={() => {
          setDialogOpen(true);
        }}
      />
      <Dialog
        aria-describedby="confirmation-dialog-description"
        aria-labelledby="confirmation-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={dialogOpen}
      >
        <ConfirmationDialog
          error={error}
          loading={loading}
          onClose={handleClose}
          onSave={handleSave}
          text="Are you sure you want to cancel this Stint?"
        />
      </Dialog>
    </>
  );
};

const CancelButton = styled(Button)`
  color: ${colors.errorDark};
`;

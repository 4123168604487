import React, { FC } from 'react';

import { Filter, FilterProps, TextInput } from 'react-admin';

import { EmploymentExceptionCategorySelectInput } from '../EmploymentExceptionCategorySelectInput';

export const EmploymentExceptionFilter: FC<
  Omit<FilterProps, 'children'>
> = props => (
  <Filter {...props}>
    <TextInput alwaysOn label="Name" source="name_istartswith" />
    <EmploymentExceptionCategorySelectInput alwaysOn allowEmpty />
  </Filter>
);

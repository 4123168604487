import * as React from 'react';

import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

import { CustomModalProps } from './CustomModal.types';

/**
 * It provides a random position (close to the center of the screen) for a new modal open.
 */
function getModalRandomPositionStyle(): {
  top: string;
  left: string;
  transform: string;
} {
  const top = 50 + Math.round(Math.random() * 20) - 10;
  const left = 50 + Math.round(Math.random() * 20) - 10;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

/**
 * Styles for the CustomModal
 */
const useStyles = makeStyles(theme => ({
  modalWindow: {
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${colors.content_border}`,
  },
}));

/**
 * CustomModal: a general purpose modal
 */
export const CustomModal: React.FC<CustomModalProps> = ({
  children,
  open,
  handleClose,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalRandomPositionStyle);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onClick={event => {
        event.stopPropagation();

        if (handleClose) {
          handleClose(event);
        }
      }}
    >
      <Box
        style={modalStyle}
        className={classes.modalWindow}
        onClick={event => event.stopPropagation()}
        role="modal"
      >
        {children}
      </Box>
    </Modal>
  );
};

import React, { FC } from 'react';

import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import { colors } from '../../../styles';

type Props = {
  labelText: string;
  size?: number;
};

export const Heading: FC<Props> = ({ labelText, size = 22 }: Props) => (
  <TypographyText size={size}> {labelText}</TypographyText>
);

const TypographyText = styled(Typography)<{ size: number }>`
  color: ${colors.neutralDark};
  font-size: ${({ size }) => size}px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.43;
`;

import React from 'react';

import { EditProps, FormTab, TabbedForm } from 'react-admin';

import { StintUser } from 'apollo/schema';
import { Edit } from 'components';
import { Performance } from 'components/models/Student/Performance';
import { StudentCompletedStintsList } from 'components/models/Student/StudentCompletedStintsList';
import { StudentEditAsideActions } from 'components/models/Student/StudentEditForm/StudentEditAsideActions';
import { transformStudentDataBeforeSave } from 'components/models/Student/StudentEditForm/utils';
import { RightToWork } from 'components/models/Student/StudentRightToWork';
import { StudentUpcomingStintsList } from 'components/models/Student/StudentUpcomingStintsList';

import { UserEditToolbar } from '../UserEditToolbar';
import { StudentDetailsTab } from './StudentDetailsTab';

export const StudentEditForm: React.FC<EditProps> = props => {
  const transformDataBeforeSave = (userData: StintUser) => {
    return transformStudentDataBeforeSave(userData);
  };

  return (
    <Edit
      {...props}
      aside={<StudentEditAsideActions />}
      transform={data => transformDataBeforeSave(data as StintUser)}
    >
      <TabbedForm toolbar={<UserEditToolbar />}>
        <FormTab data-testid="student-details-tab" label="Details">
          <StudentDetailsTab />
        </FormTab>
        <FormTab data-testid="upcoming-stints-tab" label="Upcoming Stints">
          <StudentUpcomingStintsList />
        </FormTab>
        <FormTab data-testid="completed-stints-tab" label="Completed Stints">
          <StudentCompletedStintsList />
        </FormTab>
        <FormTab label="Right To Work">
          <RightToWork />
        </FormTab>
        <FormTab data-testid="performance-tab" label="Performance">
          <Performance />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  FormWithRedirect,
  Record,
  ReferenceArrayInput,
  ResourceComponentProps,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import { Edit } from 'components';
import { EmojiInput, ImageUploadInput } from 'components/molecules';
import { validateUrl } from 'utils';

import { StintopediaEditToolbar } from '../StintopediaEditToolbar';

const useStyles = makeStyles(() => ({
  line: {
    'border-bottom': 'none',
  },
}));

export const StintopediaEdit: React.FC<ResourceComponentProps> = props => {
  const classes = useStyles();
  const dosAndDontsTitle = `Do's & Dont's`;

  const renderForm = useCallback(
    (formProps: any) => {
      return (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Box mb={{ xs: '2em' }}>
                    <Typography gutterBottom variant="h5">
                      {formProps?.record?.business?.name}
                    </Typography>
                  </Box>
                  <Box mb={{ xs: '0em' }}>
                    <ImageUploadInput
                      label="Business logo"
                      size={120}
                      source="businessLogo"
                    />
                  </Box>
                  <Box mb={{ xs: '2em' }} mt={{ xs: '2em' }}>
                    <EmojiInput
                      fullWidth
                      label="Business emoji"
                      source="emoji"
                    />
                    <ReferenceArrayInput
                      fullWidth
                      label="Preferred languages"
                      perPage={100}
                      reference="Language"
                      source="preferredLanguageIds"
                    >
                      <AutocompleteArrayInput
                        optionText="name"
                        suggestionLimit={5}
                      />
                    </ReferenceArrayInput>
                    <TextInput
                      fullWidth
                      label="QR code location"
                      source="qrCodeLocation"
                    />
                    <TextInput
                      data-testid="prep-video-url"
                      fullWidth
                      label="Prep video URL"
                      source="prepVideoUrl"
                      validate={validateUrl()}
                    />
                  </Box>
                  <Divider />
                  <Box mt={{ xs: '2em' }}>
                    <Typography variant="subtitle1">
                      <strong>Socials</strong>
                    </Typography>
                  </Box>
                  <Box mb={{ xs: '2em' }} mt={{ xs: '2em' }}>
                    <TextInput fullWidth label="Website" source="website" />
                    <TextInput fullWidth label="Instagram" source="instagram" />
                    <TextInput fullWidth label="Facebook" source="facebook" />
                    <TextInput fullWidth label="LinkedIn" source="linkedin" />
                  </Box>
                  <Divider />
                  <Box mt={{ xs: '2em' }}>
                    <Typography variant="subtitle1">
                      <strong>Business details</strong>
                    </Typography>
                  </Box>
                  <Box mb={{ xs: '2em' }} mt={{ xs: '2em' }}>
                    <TextInput
                      fullWidth
                      label="Business blurb"
                      multiline
                      rows={8}
                      source="aboutUs"
                    />
                    <ReferenceArrayInput
                      fullWidth
                      label="Characteristics"
                      perPage={100}
                      reference="Characteristic"
                      source="characteristicIds"
                    >
                      <AutocompleteArrayInput
                        optionText="name"
                        suggestionLimit={5}
                      />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput
                      fullWidth
                      label="Employment exceptions"
                      perPage={100}
                      reference="EmploymentException"
                      source="exceptionIds"
                    >
                      <AutocompleteArrayInput
                        optionText="name"
                        suggestionLimit={5}
                      />
                    </ReferenceArrayInput>
                  </Box>
                  <Divider />
                  <Box mt={{ xs: '2em' }}>
                    <Typography variant="subtitle1">
                      <strong>{dosAndDontsTitle}</strong>
                    </Typography>
                  </Box>
                  <Box mb={{ xs: '2em' }} mt={{ xs: '2em' }}>
                    <ArrayInput fullWidth label="General do's" source="dos">
                      <SimpleFormIterator classes={classes}>
                        <TextInput fullWidth label="" source="" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Box mb={{ xs: '2em' }} mt={{ xs: '2em' }}>
                    <ArrayInput fullWidth label="General dont's" source="donts">
                      <SimpleFormIterator classes={classes}>
                        <TextInput fullWidth label="" source="" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Divider />
                  <Box mt={{ xs: '2em' }}>
                    <Typography variant="subtitle1">
                      <strong>Other</strong>
                    </Typography>
                  </Box>
                  <Box mt={{ xs: '2em' }}>
                    <TextInput
                      fullWidth
                      label="Team structure"
                      multiline
                      rows={8}
                      source="teamStructure"
                    />
                    <TextInput
                      fullWidth
                      label="Internal notes"
                      multiline
                      rows={8}
                      source="internalNotes"
                    />
                  </Box>
                  <Divider />
                  <Box mt={{ xs: '2em' }}>
                    <Typography variant="subtitle1">
                      <strong>Case study agreements</strong>
                    </Typography>
                  </Box>
                  <Box mt={{ xs: '2em' }}>
                    <BooleanInput
                      label="Agreed to take part in marketing initiatives"
                      source="agreedTakePartInMarketingInitiatives"
                    />
                    <BooleanInput
                      label="Agreed to refer 3 friends/operators if we see an improvement in financial metrics"
                      source="agreedToReferFriendsIfFinancialMetricsImproved"
                    />
                    <BooleanInput
                      label="Agreed to operate staffing rota as per the Stint Labour Productivity Model"
                      source="agreedToOperateStaffingRota"
                    />
                    <BooleanInput
                      label="Access to transaction by transaction sales data?"
                      source="accessToTransactionSalesData"
                    />
                    <BooleanInput
                      label="Access to rota examples (including start/finish time and wages for FoH team)?"
                      source="accessToRotaExamples"
                    />
                    <BooleanInput
                      label="Access to annual P&L accounts?"
                      source="accessToAnnualPlAccounts"
                    />
                    <BooleanInput
                      label="Agreed to continue posting if we see desired results"
                      source="agreedToContinuePostingIfWeSeeDesireResults"
                    />
                  </Box>
                  <Divider />
                  <Box mt={{ xs: '1em' }}>
                    <BooleanInput
                      label="Onboarding complete"
                      source="onboardingComplete"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <ImageUploadInput
                      label="Photo of entrance"
                      size={240}
                      source="photoOfEntrance"
                    />
                  </Box>
                  <Box mt={{ xs: '2em' }}>
                    <ImageUploadInput
                      label="General photo 1"
                      size={240}
                      source="generalPhoto1"
                    />
                  </Box>
                  <Box>
                    <ImageUploadInput
                      label="General photo 2"
                      size={240}
                      source="generalPhoto2"
                    />
                  </Box>
                  <Box>
                    <ImageUploadInput
                      label="General photo 3"
                      size={240}
                      source="generalPhoto3"
                    />
                  </Box>
                  <Box>
                    <ImageUploadInput
                      label="General photo 4"
                      size={240}
                      source="generalPhoto4"
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <StintopediaEditToolbar
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              record={formProps?.record}
              saving={formProps.saving}
            />
          </form>
        </Card>
      );
    },
    [classes, dosAndDontsTitle],
  );

  const handleTransform = (data: Record) => {
    return {
      ...data,
      businessId: data?.business?.id,
    };
  };

  return (
    <Edit {...props} transform={handleTransform}>
      <FormWithRedirect {...props} render={renderForm} />
    </Edit>
  );
};

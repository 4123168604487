import React, { FC, useState } from 'react';

import {
  EmojiPeople,
  People,
  Security,
  Send,
  SupervisorAccount,
  ViewList,
  Work,
} from '@material-ui/icons';
import BallotIcon from '@material-ui/icons/Ballot';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import {
  getResources,
  MenuItemLink,
  MenuProps,
  ReduxState,
  ResourceProps,
} from 'react-admin';
import { useSelector } from 'react-redux';

import { openConsole } from 'utils';

import { SubMenu } from './SubMenu';

const RESOURCES_MIGRATED_TO_CONSOLE = [
  {
    name: 'PartnerNode',
    path: 'partners',
  },
];
const HIDDEN_RESOURCES: string[] = [
  'Appeal',
  'Employer',
  'ManageSurgeList',
  'Offence',
  'RTWScreening',
  'RTWStatus',
  'RTWStinterScreening',
  'Staff',
  'StintUser',
  'Student',
  'StudentReview',
];

type Options = {
  label?: string;
};

export const Menu: FC<MenuProps> = ({ dense, onMenuClick }) => {
  const [state, setState] = useState({
    menuUser: true,
  });

  const resources = useSelector<ReduxState, ResourceProps[]>(getResources);

  return (
    <>
      <SubMenu
        dense={!!dense}
        handleToggle={() => setState({ menuUser: !state.menuUser })}
        icon={<People />}
        isOpen={state.menuUser}
        name="Users"
      >
        <MenuItemLink
          leftIcon={<EmojiPeople />}
          primaryText="Students"
          sidebarIsOpen={!dense}
          to={{
            pathname: '/Student/',
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          leftIcon={<SupervisorAccount />}
          primaryText="Employers"
          sidebarIsOpen={!dense}
          to={{
            pathname: '/Employer/',
            state: { _scrollToTop: true },
          }}
          onClick={e => {
            e.preventDefault();
            openConsole('employers');
          }}
        />
        <MenuItemLink
          leftIcon={<Security />}
          primaryText="Staff"
          sidebarIsOpen={!dense}
          to={{
            pathname: '/Staff/',
            state: { _scrollToTop: true },
          }}
        />
      </SubMenu>
      <SubMenu
        dense={!!dense}
        handleToggle={() => setState({ menuUser: !state.menuUser })}
        icon={<Work />}
        isOpen={state.menuUser}
        name="Right To Work"
      >
        <MenuItemLink
          leftIcon={<PlaylistAddCheckIcon />}
          primaryText="Status"
          sidebarIsOpen={!dense}
          to={{
            pathname: '/RTWStatus/',
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          leftIcon={<BallotIcon />}
          primaryText="Screening"
          sidebarIsOpen={!dense}
          to={{
            pathname: '/RTWScreening/',
            state: { _scrollToTop: true },
          }}
        />
      </SubMenu>
      {resources
        ?.filter(resource => !HIDDEN_RESOURCES?.includes(resource?.name))
        ?.map(resource => (
          <MenuItemLink
            key={resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <ViewList />}
            onClick={e => {
              const resourceInConsoleApp = RESOURCES_MIGRATED_TO_CONSOLE.find(
                resourceInConsoleApp =>
                  resourceInConsoleApp.name == resource.name,
              );
              if (resourceInConsoleApp !== undefined) {
                e.preventDefault();
                openConsole(resourceInConsoleApp.path);
                return;
              }
              if (onMenuClick !== undefined) {
                onMenuClick();
              }
            }}
            primaryText={(resource.options as Options)?.label || resource.name}
            sidebarIsOpen={!dense}
            to={`/${resource.name}`}
          />
        ))}
      <MenuItemLink
        leftIcon={<Send />}
        onClick={onMenuClick}
        primaryText="Send notification"
        sidebarIsOpen={!dense}
        to="/Notification/create"
      />
      <MenuItemLink
        leftIcon={<Send />}
        onClick={onMenuClick}
        primaryText="Manage Surge List"
        sidebarIsOpen={!dense}
        to="/ManageSurgeList"
      />
    </>
  );
};

import gql from 'graphql-tag';

import { buildQuery } from './utils';

const EditableSettingFields = gql`
  fragment AdminEditableSettingFields on EditableSetting {
    id
    predictionLeadDays
    notificationBlockerWindowHours
    geoTrackingBufferTime
    advancedOperationalWindowStartTime
    advancedOperationalWindowEndTime
    automatedTestingEnabled
    isTimeManipulationEnabled
    serverDatetime
    featureFlagStudentGoingToBeLateSmsEnabled
    featureFlagStintSecondStartReminderEnabled
    availabilityMatchingBufferHours
    availabilityMatchingMaxOffersMonday
    availabilityMatchingMaxOffersTuesday
    availabilityMatchingMaxOffersWednesday
    availabilityMatchingMaxOffersThursday
    availabilityMatchingMaxOffersFriday
    availabilityMatchingMaxOffersSaturday
    availabilityMatchingMaxOffersSunday
    availabilityMatchingNotificationForNoOffersEnabled
    matchingAlgoNoOfferMaxCountPerTimeWindow
    matchingAlgoNoOfferTimeWindowSpan
    matchingAlgoNoOfferTitle
    matchingAlgoNoOfferMessage
    matchingAlgoNotificationDelayInMinutes
    featureFlagEtdNotificationsEnabled
    sendingStudentLeaveReminderAtRouteTimeEnabled
    studentCancellationOnHoldEmailEnabled
    etdCalculationBeforeMiddayOffsetInMinutes
    etdCalculationAfterMiddayOffsetInMinutes
    stintOfferExpiryThresholdInHours
    notificationTopUpThresholdInHours
  }
`;

const listQuery = gql`
  query allEditableSettings(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allEditableSettings(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...AdminEditableSettingFields
    }
    total: _allEditableSettingsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
      __typename
    }
  }
  ${EditableSettingFields}
`;

const singleItemQuery = gql`
  query EditableSetting($id: ID!) {
    data: EditableSetting(id: $id) {
      ...AdminEditableSettingFields
    }
  }
  ${EditableSettingFields}
`;

const updateQuery = gql`
  mutation updateEditableSetting(
    $predictionLeadDays: Int
    $notificationBlockerWindowHours: Float
    $geoTrackingBufferTime: Int
    $advancedOperationalWindowStartTime: AWSTime
    $advancedOperationalWindowEndTime: AWSTime
    $automatedTestingEnabled: Boolean
    $serverDatetime: AWSDateTime
    $featureFlagStudentGoingToBeLateSmsEnabled: Boolean
    $featureFlagStintSecondStartReminderEnabled: Boolean
    $availabilityMatchingBufferHours: Int
    $availabilityMatchingMaxOffersMonday: Int
    $availabilityMatchingMaxOffersTuesday: Int
    $availabilityMatchingMaxOffersWednesday: Int
    $availabilityMatchingMaxOffersThursday: Int
    $availabilityMatchingMaxOffersFriday: Int
    $availabilityMatchingMaxOffersSaturday: Int
    $availabilityMatchingMaxOffersSunday: Int
    $availabilityMatchingNotificationForNoOffersEnabled: Boolean
    $matchingAlgoNoOfferMaxCountPerTimeWindow: Int
    $matchingAlgoNoOfferTimeWindowSpan: Int
    $matchingAlgoNoOfferTitle: String
    $matchingAlgoNoOfferMessage: String
    $matchingAlgoNotificationDelayInMinutes: Int
    $featureFlagEtdNotificationsEnabled: Boolean
    $sendingStudentLeaveReminderAtRouteTimeEnabled: Boolean
    $studentCancellationOnHoldEmailEnabled: Boolean
    $etdCalculationBeforeMiddayOffsetInMinutes: Int
    $etdCalculationAfterMiddayOffsetInMinutes: Int
    $stintOfferExpiryThresholdInHours: Int
    $notificationTopUpThresholdInHours: Int
  ) {
    data: updateEditableSetting(
      predictionLeadDays: $predictionLeadDays
      notificationBlockerWindowHours: $notificationBlockerWindowHours
      geoTrackingBufferTime: $geoTrackingBufferTime
      advancedOperationalWindowStartTime: $advancedOperationalWindowStartTime
      advancedOperationalWindowEndTime: $advancedOperationalWindowEndTime
      automatedTestingEnabled: $automatedTestingEnabled
      featureFlagStintSecondStartReminderEnabled: $featureFlagStintSecondStartReminderEnabled
      serverDatetime: $serverDatetime
      featureFlagStudentGoingToBeLateSmsEnabled: $featureFlagStudentGoingToBeLateSmsEnabled
      availabilityMatchingBufferHours: $availabilityMatchingBufferHours
      availabilityMatchingMaxOffersMonday: $availabilityMatchingMaxOffersMonday
      availabilityMatchingMaxOffersTuesday: $availabilityMatchingMaxOffersTuesday
      availabilityMatchingMaxOffersWednesday: $availabilityMatchingMaxOffersWednesday
      availabilityMatchingMaxOffersThursday: $availabilityMatchingMaxOffersThursday
      availabilityMatchingMaxOffersFriday: $availabilityMatchingMaxOffersFriday
      availabilityMatchingMaxOffersSaturday: $availabilityMatchingMaxOffersSaturday
      availabilityMatchingMaxOffersSunday: $availabilityMatchingMaxOffersSunday
      availabilityMatchingNotificationForNoOffersEnabled: $availabilityMatchingNotificationForNoOffersEnabled
      matchingAlgoNoOfferMaxCountPerTimeWindow: $matchingAlgoNoOfferMaxCountPerTimeWindow
      matchingAlgoNoOfferTimeWindowSpan: $matchingAlgoNoOfferTimeWindowSpan
      matchingAlgoNoOfferTitle: $matchingAlgoNoOfferTitle
      matchingAlgoNoOfferMessage: $matchingAlgoNoOfferMessage
      matchingAlgoNotificationDelayInMinutes: $matchingAlgoNotificationDelayInMinutes
      featureFlagEtdNotificationsEnabled: $featureFlagEtdNotificationsEnabled
      sendingStudentLeaveReminderAtRouteTimeEnabled: $sendingStudentLeaveReminderAtRouteTimeEnabled
      studentCancellationOnHoldEmailEnabled: $studentCancellationOnHoldEmailEnabled
      etdCalculationBeforeMiddayOffsetInMinutes: $etdCalculationBeforeMiddayOffsetInMinutes
      etdCalculationAfterMiddayOffsetInMinutes: $etdCalculationAfterMiddayOffsetInMinutes
      stintOfferExpiryThresholdInHours: $stintOfferExpiryThresholdInHours
      notificationTopUpThresholdInHours: $notificationTopUpThresholdInHours
    ) {
      ...AdminEditableSettingFields
    }
  }
  ${EditableSettingFields}
`;

export const editableSettings = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
});

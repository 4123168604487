import styled from 'styled-components';

export const LogoContainer = styled.div<{ size: number }>`
  height: ${({ size }) => size}px;
  margin: auto;
  position: relative;
  width: ${({ size }) => size}px;
`;

export const LogoCropWrapper = styled.div`
  padding: 0 10px 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  padding: 0 5px 5px;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
`;

import React, { ComponentProps } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AppBar as RAAppBar } from 'react-admin';

import { IS_PRODUCTION } from 'config';
import { getServerSettings } from 'utils';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

export const AppBar: React.FC<ComponentProps<typeof RAAppBar>> = props => {
  const classes = useStyles();
  return (
    <RAAppBar {...props}>
      <Typography
        className={classes.title}
        color="inherit"
        id="react-admin-title"
        variant="h6"
      />
      {!IS_PRODUCTION && <small>Server: {getServerSettings().api}</small>}
      <span className={classes.spacer} />
    </RAAppBar>
  );
};

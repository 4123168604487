import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { colors } from './colors';

export const palette: PaletteOptions = {
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  error: {
    main: colors.error,
    light: colors.errorLight,
    dark: colors.errorDark,
  },
  success: {
    main: colors.success,
    light: colors.successLight,
    dark: colors.successDark,
  },
  warning: {
    main: colors.warning,
  },
  text: {
    disabled: colors.textDisabled,
    primary: colors.primary,
    secondary: colors.onSecondary,
  },
  background: {
    default: colors.background,
  },
};

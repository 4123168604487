import React from 'react';

import { useForm } from 'react-final-form';

import { useGoogleAutoComplete } from '../../../hooks';
import { AddressAutoComplete, AddressSearchResult } from '../../molecules';
import { Wrapper } from './SearchAddressField.styles';
import {
  ChangeEvent,
  SearchAddressFieldProps,
} from './SearchAddressField.types';

export const SearchAddressField = (props: SearchAddressFieldProps) => {
  const form = useForm();

  const { locations, onSearch, clearSearchResults } = useGoogleAutoComplete({});

  const handleChange = (
    event: ChangeEvent,
    location: AddressSearchResult | null,
  ) => {
    if (location) {
      clearSearchResults();
      form.change('placeId', location.placeId);
      form.change('displayAddress', location.displayAddress);
    }
  };

  const handleTextChange = (event: ChangeEvent, value: string) => {
    onSearch(value);
  };
  return (
    <Wrapper>
      <AddressAutoComplete
        addressSearchResults={locations}
        onChange={handleChange}
        onInputChange={handleTextChange}
        placeholder={props?.placeholder}
        label={props?.label ?? ''}
        validate={props.validate}
      />
    </Wrapper>
  );
};

import React from 'react';

import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const TagsCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);

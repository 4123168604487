import React from 'react';

import { Filter, FilterProps, TextInput } from 'react-admin';

export const LanguageFilter: React.FC<
  Omit<FilterProps, 'children'>
> = props => (
  <Filter {...props}>
    <TextInput alwaysOn label="Name" source="name_istartswith" />
  </Filter>
);

import React, { useState } from 'react';

import { Dialog } from '@material-ui/core';
import {
  BulkActionProps,
  Button,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

import { ConfirmationDialog } from 'components/molecules';

export const StintCancelStintsButton: React.FC<BulkActionProps> = ({
  selectedIds,
  resource,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [resolveCancelStint, { loading }] = useUpdateMany(
    resource || '',
    selectedIds || [],
    {
      stintRefs: selectedIds,
    },
    {
      onSuccess: () => {
        unselectAll(resource);
        refresh();
      },
      onFailure: e => {
        notify(e?.message, 'error');
        refresh();
      },
    },
  );

  const handleClose = () => setDialogOpen(false);

  const handleSave = () => {
    resolveCancelStint();
    handleClose();
  };

  return (
    <>
      <Button
        label="Cancel"
        onClick={() => {
          setDialogOpen(true);
        }}
      />
      <Dialog
        aria-describedby="confirmation-dialog-description"
        aria-labelledby="confirmation-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={dialogOpen}
      >
        <ConfirmationDialog
          loading={loading}
          onClose={handleClose}
          onSave={handleSave}
          text="Are you sure you want to cancel these Stints?"
        />
      </Dialog>
    </>
  );
};

import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import {
  ChipField,
  FunctionField,
  NumberField,
  Record,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

import { StintUser } from 'apollo/schema';
import { LabeledImageField } from 'components';
import { StudentCompletedStintsList } from 'components/models/Student/StudentCompletedStintsList';
import { StudentUpcomingStintsList } from 'components/models/Student/StudentUpcomingStintsList';
import {
  getUniversityLabel,
  STUDENT_EMPLOYEE_STATEMENT_CHOICES,
  STUDENT_LOAN_PLAN_CHOICES,
} from 'components/models/Student/utils';
import { getNullableBooleanDisplayValue } from 'utils';

export const StudentShowForm: React.FC<ShowProps> = props => {
  const { record: student } = useShowController<StintUser & Record>(props);

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab data-testid="student-details-tab" label="Details">
          <TextField source="id" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <FunctionField
            label={getUniversityLabel(student?.isEmailVerified)}
            render={() => student?.secondaryEmail || student?.email}
          />
          <TextField label="Uni Email - deprecated" source="email" />
          <TextField source="phoneNumber" />
          <FunctionField
            label="Can see surge"
            render={() =>
              `${getNullableBooleanDisplayValue(
                student?.user?.canSeeSurge ?? student?.canSeeSurge,
              )}`
            }
          />
          <TextField source="appVersion" />
          <ReferenceField
            label="University"
            reference="University"
            source="studentProfile.university_id"
          >
            <LabeledImageField
              imageSource="logo"
              label="Name"
              labelSource="name"
              sortBy="name"
            />
          </ReferenceField>
          <NumberField label="Average Rating" source="publicRating" />
          <ReferenceArrayField
            reference="EmploymentException"
            source="exceptionsIds"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <SelectField
            choices={STUDENT_LOAN_PLAN_CHOICES}
            label="Student Loan Plan"
            source="studentProfile.studentLoanPlan"
          />
          <SelectField
            choices={STUDENT_EMPLOYEE_STATEMENT_CHOICES}
            label="Student Employee Statement"
            source="studentProfile.employeeStatement"
          />
          <Box mt={{ xs: '2em' }}>
            <Divider />
          </Box>
          <Box my={{ xs: '2em' }}>
            <Typography variant="subtitle1">
              <strong>Bank Details</strong>
            </Typography>
          </Box>
          <TextField
            label="Account Name"
            source="studentProfile.paymentMethods.0.accountName"
          />
          <TextField
            label="Account Number"
            source="studentProfile.paymentMethods.0.accountNumber"
          />
          <TextField
            label="Sort Code"
            source="studentProfile.paymentMethods.0.sortCode"
          />
          <Box mt={{ xs: '2em' }}>
            <Divider />
          </Box>
          <Box my={{ xs: '2em' }}>
            <Typography variant="subtitle1">
              <strong>Emergency contact details</strong>
            </Typography>
          </Box>
          <TextField
            label="Name"
            source="studentProfile.emergencyContactName"
          />
          <TextField
            label="Contact number"
            source="studentProfile.emergencyContactNumber"
          />
          <TextField
            label="Relationship"
            source="studentProfile.emergencyContactRelation"
          />
        </Tab>
        <Tab data-testid="upcoming-stints-tab" label="Upcoming Stints">
          <StudentUpcomingStintsList />
        </Tab>
        <Tab data-testid="completed-stints-tab" label="Completed Stints">
          <StudentCompletedStintsList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { opacify } from 'polished';

import { dinProLight, dinProMedium } from '../typography/fonts';
import { colors } from './colors';
import { palette } from './palette';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey;
  }
}

declare module 'styled-components' {
  // Extend styled components default theme with the MUI and custom
  // theme props so they are accessible inside styled components
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: 'DINPro, Arial',
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: colors.hoverPrimary,
        },
        '&:disabled': {
          backgroundColor: colors.neutralLight,
          color: colors.primary,
        },
      },
      text: {
        fontWeight: 600,
        letterSpacing: '0.8px',
        lineHeight: 1.5,
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: colors.primary,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colors.hoverPrimary,
        },
        '&:disabled': {
          backgroundColor: colors.neutralLight,
          color: colors.primary,
        },
      },
      label: {
        color: 'inherit',
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '5px',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.onPrimary,
      },
      colorSecondary: {
        color: colors.secondary,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: colors.black,
      },
      paperAnchorLeft: {
        backgroundColor: colors.black,
      },
    },
    MuiFormHelperText: {
      contained: {
        fontSize: 13,
      },
    },
    MuiInputBase: {
      root: {
        caretColor: colors.secondary,
        color: colors.primary,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: colors.neutralLightest,
      },
      notchedOutline: {
        borderColor: 'rgba(0, 0, 0, 0.01)',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [dinProLight, dinProMedium],
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: opacify(-0.8, colors.hoverPrimary),
        },
      },
    },
    MuiTypography: {
      caption: {
        color: colors.neutralDark,
        fontSize: 12,
      },
      h4: {
        fontSize: 24,
        lineHeight: 1.33,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontSize: 20,
        lineHeight: 1.6,
        letterSpacing: 0.07,
      },
      subtitle2: {
        color: colors.neutralDark,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 8,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 16px 16px 16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px 16px',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.neutralDark60,
      },
    },
    MuiAutocomplete: {
      noOptions: {
        color: colors.neutralDark,
      },
      option: {
        color: colors.primary,
      },
    },
  },
});

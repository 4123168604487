import React from 'react';

import {
  BooleanField,
  SelectField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useQuery,
} from 'react-admin';

import { BusinessActionsToolbar } from '../BusinessActionsToolbar';
import { CancellationHistory } from '../CancellationHistory';
import { DownloadQRCode } from '../DownloadQRCode';
import { BUSINESS_STATUS_CHOICES } from '../utils';

export const BusinessShow: React.FC<ShowProps> = props => {
  const businessId = props?.id;
  const { data: business } = useQuery({
    type: 'getOne',
    resource: 'Business',
    payload: { id: businessId },
  });

  return (
    <Show {...props} actions={<BusinessActionsToolbar />}>
      <TabbedShowLayout>
        <Tab label="Detail">
          <TextField source="id" />
          <TextField source="name" />
          <TextField
            data-testid="business-phone-number"
            label="Contact number"
            source="phoneNumber"
          />
          <SelectField
            choices={BUSINESS_STATUS_CHOICES}
            label="Status"
            source="status"
          />
          <BooleanField
            data-testid="business-control-check"
            label="Control check"
            source="controlCheck"
          />
          <BooleanField
            label="Unlocked last minute Stints?"
            source="unlockedLastMinuteStints"
          />

          <BooleanField label="Integrated?" source="integrated" />
          <BooleanField source="exemptFromPaymentDetails" />
          <BooleanField
            label="Onboarding Meeting"
            source="stintopedia.bookedOnboardingMeeting"
          />
          <BooleanField
            label="Using Stint correctly away"
            source="stintopedia.usingStintCorrectly"
          />
          <BooleanField
            data-testid="repeat-stinters-enabled"
            label="Repeat Stinters Enabled"
            source="repeatStintersEnabled"
          />
          <DownloadQRCode data-testid="business-qr-code" source="qrCodeId" />
        </Tab>
        <Tab label="Cancellation History">
          <CancellationHistory />
        </Tab>
        <Tab label="Finance">
          <TextField
            emptyText={business?.city?.baseFee}
            label={
              !business?.rateOverride?.rate
                ? 'Current City Base Rate'
                : 'Current Business Override'
            }
            source="rateOverride.rate"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

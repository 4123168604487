import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  maxLength,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';

import { BodyText, Label } from 'components';
import {
  getUniversityLabel,
  STUDENT_EMPLOYEE_STATEMENT_CHOICES,
  STUDENT_LOAN_PLAN_CHOICES,
} from 'components/models/Student/utils';
import { getNullableBooleanDisplayValue } from 'utils';

export const StudentDetailsTab = () => {
  return (
    <Column>
      <TextInput disabled source="id" label="ID (from mono)" />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <FormDataConsumer>
        {data => (
          <TextInput
            format={(v: string) => v || data?.formData?.email}
            label={getUniversityLabel(data?.formData?.isEmailVerified)}
            source="secondaryEmail"
            validate={required()}
          />
        )}
      </FormDataConsumer>
      <TextInput disabled label="Uni Email - deprecated" source="email" />
      <TextInput source="phoneNumber" validate={required()} />
      <Label labelText="Can See Surge" />
      <FormDataConsumer>
        {({ formData }: any) => {
          const displayText = getNullableBooleanDisplayValue(
            formData?.user?.canSeeSurge ?? formData?.canSeeSurge,
          );
          return <BodyText data-testid="can-see-surge">{displayText}</BodyText>;
        }}
      </FormDataConsumer>
      <ReferenceInput
        filterToQuery={searchText => ({
          name_istartswith: searchText,
        })}
        fullWidth
        label="University"
        reference="University"
        sort={{ field: 'name', order: 'DESC' }}
        source="studentProfile.university_id"
      >
        <AutocompleteInput suggestionLimit={5} />
      </ReferenceInput>
      <NumberInput disabled label="Average Rating" source="publicRating" />
      <ReferenceArrayInput
        fullWidth
        label="Exceptions"
        perPage={400}
        reference="EmploymentException"
        source="exceptionsIds"
      >
        <AutocompleteArrayInput optionText="name" suggestionLimit={5} />
      </ReferenceArrayInput>
      <SelectInput
        choices={STUDENT_LOAN_PLAN_CHOICES}
        label="Student Loan Plan"
        source="studentProfile.studentLoanPlan"
      />
      <SelectInput
        allowEmpty
        choices={STUDENT_EMPLOYEE_STATEMENT_CHOICES}
        label="Student Employee Statement"
        source="studentProfile.employeeStatement"
      />
      <Divider />
      <Box my={{ xs: '2em' }}>
        <Typography variant="subtitle1">
          <strong>Bank Details</strong>
        </Typography>
      </Box>
      <TextInput
        label="Account Name"
        source="studentProfile.paymentMethods.0.accountName"
      />
      <TextInput
        label="Account Number"
        source="studentProfile.paymentMethods.0.accountNumber"
        validate={maxLength(8)}
      />
      <TextInput
        label="Sort Code"
        source="studentProfile.paymentMethods.0.sortCode"
        validate={maxLength(6)}
      />
      <Divider />
      <Box my={{ xs: '2em' }}>
        <Typography variant="subtitle1">
          <strong>Emergency contact details</strong>
        </Typography>
      </Box>
      <TextInput label="Name" source="studentProfile.emergencyContactName" />
      <TextInput
        label="Contact number"
        source="studentProfile.emergencyContactNumber"
      />
      <TextInput
        label="Relationship"
        source="studentProfile.emergencyContactRelation"
      />
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 512px;
`;

import React from 'react';

import {
  Datagrid,
  EditButton,
  EmailField,
  FunctionField,
  ListProps,
  TextField,
} from 'react-admin';

import { AvatarField } from 'components/atoms';
import { ViewSpecificUserID } from 'components/models/Users/ViewSpecificUserID';
import { USER_TYPE_STUDENT } from 'components/models/Users/ViewUserDialog';
import { UserList } from 'components/organisms';

import { StudentsFilter } from '../StudentsFilter';

export const StudentsList: React.FC<ListProps> = props => {
  return (
    <UserList
      {...props}
      actions={<ViewSpecificUserID userType={USER_TYPE_STUDENT} />}
      filters={<StudentsFilter />}
    >
      <Datagrid>
        <AvatarField label="" sortable={false} source="user.profilePhotoUrl" />
        <TextField sortable={false} source="firstName" />
        <TextField sortable={false} source="lastName" />
        <TextField sortable={false} source="user.dateOfBirth" />
        <EmailField sortable={false} source="email" />
        <TextField
          emptyText="-"
          label="App Version"
          sortable={false}
          source="user.appVersion"
        />
        <EditButton label="Edit" />
        <FunctionField
          label="Hollow account"
          render={(record: any) => (record?.user ? 'No' : 'Yes')}
          tooltip="Whether or not the user is still yet to validate their identity"
        />
      </Datagrid>
    </UserList>
  );
};

import React, { FC } from 'react';

import {
  AutocompleteInput,
  Filter,
  FilterProps,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';

import { DateInput } from 'components';
import { STINT_STATUS_CHOICES } from 'utils';

export const StintFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      filterToQuery={searchText => ({ search_phrase: searchText })}
      label="Business name"
      reference="Business"
      source="business_id"
    >
      <AutocompleteInput emptyText="-" optionText="name" />
    </ReferenceInput>

    <TextInput alwaysOn label="ID" source="idIcontains" />

    <ReferenceInput alwaysOn label="City" reference="City" source="city_id">
      <AutocompleteInput emptyText="-" optionText="name" />
    </ReferenceInput>

    <SelectInput
      alwaysOn
      choices={STINT_STATUS_CHOICES}
      label="Status"
      source="status"
    />
    <DateInput alwaysOn label="Start date" source="startFrom" />
    <NullableBooleanInput
      alwaysOn
      label="Is best effort"
      source="isBestEffort"
    />
    <NullableBooleanInput
      alwaysOn
      label="Control check"
      source="controlCheck"
    />
  </Filter>
);

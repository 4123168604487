export const getCurrentLocationMarker = () =>
  new google.maps.Marker({
    title: 'Current Location',
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillColor: 'white',
      fillOpacity: 1,
      strokeColor: 'hsl(241deg 58% 65%)',
      strokeOpacity: 1,
      strokeWeight: 5,
    },
  });

export const getInitialLocationMarker = () =>
  new google.maps.Marker({
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 5,
      fillColor: '#ddd',
      fillOpacity: 1,
      strokeColor: '#666',
      strokeOpacity: 1,
      strokeWeight: 2,
    },
    optimized: false,
  });

export const getStintLocationMarker = () =>
  new google.maps.Marker({
    title: 'Stint location',
    label: 'S',
    optimized: false,
  });

import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Box from '@material-ui/core/Box';

import { GoogleMap, LiveMapMarker, LiveMapTrail } from 'components/atoms';
import { Location } from 'types';

import {
  bindResizeListener,
  getCurrentLocationMarker,
  getInitialLocationMarker,
  getMapBounds,
  getStintLocationMarker,
} from './utils';

export interface Props {
  latestLocation?: Location;
  initialLocation?: Location;
  stintLocation?: Location;
  historicalLocations?: Location[];
}

export const GeotrackerLiveMap: FC<Props> = memo<Props>(
  ({ latestLocation, initialLocation, historicalLocations, stintLocation }) => {
    const mapRef = useRef<google.maps.Map>();
    const mapsRef = useRef<typeof google.maps>();
    const [googleApiLoaded, setGoogleApiLoaded] = useState(false);

    const currentLocationMarker = useMemo(getCurrentLocationMarker, []);
    const initialLocationMarker = useMemo(getInitialLocationMarker, []);
    const stintLocationMarker = useMemo(getStintLocationMarker, []);

    const getJourneyLocations = useCallback((): Location[] => {
      let locations = historicalLocations || [];
      if (initialLocation) {
        locations = locations.concat(initialLocation);
      }
      return locations;
    }, [historicalLocations, initialLocation]);

    const getAllLocations = useCallback((): Location[] => {
      let locations = getJourneyLocations();
      if (stintLocation) {
        locations = locations.concat([
          {
            longitude: Number(stintLocation.longitude),
            latitude: Number(stintLocation.latitude),
          },
        ]);
      }
      return locations;
    }, [stintLocation, getJourneyLocations]);

    const apiIsLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
      mapRef.current = map;
      mapsRef.current = maps;
      setGoogleApiLoaded(true);
    };

    const setMapBoundsAndListeners = useCallback(() => {
      const map = mapRef.current as google.maps.Map;
      const maps = mapsRef.current as typeof google.maps;

      if (!map) {
        return;
      }

      const allLocations = getAllLocations();
      const bounds = getMapBounds(maps, allLocations);
      if (!bounds?.isEmpty()) {
        map.fitBounds(bounds, 20);
      }
      bindResizeListener(map, maps, bounds);
    }, [getAllLocations]);

    useEffect(setMapBoundsAndListeners, [googleApiLoaded]);

    const journeyLocations = getJourneyLocations();
    const mapCenter = latestLocation || stintLocation || initialLocation;

    return (
      <Box height="400px" width="100%">
        <GoogleMap
          defaultCenter={mapCenter}
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        />
        <LiveMapTrail
          locations={journeyLocations}
          map={mapRef.current}
          trailColor="#b500e4"
        />
        <LiveMapMarker
          location={latestLocation}
          map={mapRef.current}
          marker={currentLocationMarker}
        />
        <LiveMapMarker
          location={stintLocation}
          map={mapRef.current}
          marker={stintLocationMarker}
        />
        <LiveMapMarker
          location={initialLocation}
          map={mapRef.current}
          marker={initialLocationMarker}
        />
      </Box>
    );
  },
);

GeotrackerLiveMap.displayName = 'GeotrackerLiveMap';

import gql from 'graphql-tag';

import { buildQuery } from './utils';

const PostalCodeFields = gql`
  fragment AdminPostalCodeFields on PostalCode {
    postalCode
    id
    city_id
  }
`;

const listQuery = gql`
  query allPostalCodes(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PostalCodeFilter
  ) {
    items: allPostalCodes(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminPostalCodeFields
    }
    total: _allPostalCodesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${PostalCodeFields}
`;

const singleItemQuery = gql`
  query PostalCode($id: ID!) {
    data: PostalCode(id: $id) {
      ...AdminPostalCodeFields
    }
  }
  ${PostalCodeFields}
`;

const createQuery = gql`
  mutation createPostalCode($postalCode: String!, $city_id: ID!) {
    data: createPostalCode(postalCode: $postalCode, city_id: $city_id) {
      ...AdminPostalCodeFields
    }
  }
  ${PostalCodeFields}
`;

const updateQuery = gql`
  mutation updatePostalCode($id: ID!, $postalCode: String, $city_id: ID) {
    data: updatePostalCode(
      id: $id
      postalCode: $postalCode
      city_id: $city_id
    ) {
      ...AdminPostalCodeFields
    }
  }
  ${PostalCodeFields}
`;

export const postalCodes = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
  updateQuery,
});

import React from 'react';

import { makeStyles } from '@material-ui/core';

import { Field } from 'components/atoms';

interface TextFieldProps {
  /** The label for the img */
  label: string;
  /** The img src */
  src: string;
  /** The alt text for the img */
  alt: string;
}
const useStyles = makeStyles(
  {
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
    },
  },
  { name: 'RaImageField' },
);

/**
 * The `ImageField` from react-admin does not render correctly unless it is a direct
 * child of the SimpleShowLayout. This makes it difficult to extract groups of fields
 * into separate components to be reusable. This component can be used instead to match
 * the visual appearance of the `ImageField`.
 */
export const ImageField = ({ label, src, alt }: TextFieldProps) => {
  const classes = useStyles();
  return (
    <Field label={label}>
      <img src={src} alt={alt} className={classes.image} />
    </Field>
  );
};

import AddressIcon from '@material-ui/icons/PostAddOutlined';

import { AddressCreate } from './AddressCreate';
import { AddressEdit } from './AddressEdit';
import { AddressList } from './AddressList';
import { AddressShow } from './AddressShow';

export const icon = AddressIcon;

export const create = AddressCreate;
export const list = AddressList;
export const edit = AddressEdit;
export const show = AddressShow;

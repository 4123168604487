import { maxLength, required } from 'react-admin';

import { StintTaskTaskType } from 'apollo/schema';

export const TASK_TYPE_CHOICES = [
  { id: StintTaskTaskType.STINT_APPROVED, name: 'Stint approved' },
  { id: StintTaskTaskType.CUSTOM, name: 'Custom' },
];

export const nameValidator = [
  required(),
  maxLength(75, 'Maximum 75 characters '),
];

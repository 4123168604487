import React from 'react';

import { Typography } from '@material-ui/core';
import { FunctionField } from 'react-admin';

import { colors } from 'styles';
import { rtwErrorMap } from 'utils/rtwErrorCode';

export const RTWStatusField = (prop: any) => (
  <FunctionField
    render={(record?: any) => {
      let color = colors.placeholderGrey;
      let status =
        record.status.charAt(0).toUpperCase() +
        record.status.substr(1).toLowerCase();
      if (record.error) {
        color = colors.errorDark;
        if (rtwErrorMap[record.error] !== undefined) {
          status += ` with an error: ${rtwErrorMap[record.error]}`;
        } else {
          status += ' with an unknown error. Please report for improvement';
        }
      } else if (record.status === 'COMPLETED') {
        color = colors.success;
      }
      return (
        <Typography
          style={{
            color,
          }}
        >
          {status}
        </Typography>
      );
    }}
    {...prop}
  />
);

import React, { ComponentProps } from 'react';

import { Layout as RALayout } from 'react-admin';

import { AppBar } from './AppBar';
import { Menu } from './Menu';

type Props = ComponentProps<typeof RALayout>;

export const Layout = (props: Props) => (
  <RALayout {...props} appBar={AppBar} menu={Menu} />
);

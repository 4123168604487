import gql from 'graphql-tag';

import { buildQuery } from './utils';

const listQuery = gql`
  query allNotificationStudentOrEmployerUsers($filter: StintUserFilter) {
    total: _allStudentOrEmployerUsersMeta(filter: $filter) {
      count
      __typename
    }
  }
`;

const listParseResponse = (response: any) => {
  return { data: [], total: response.data.total.count };
};

export const notificationUsers = buildQuery({
  listQuery,
  listParseResponse,
});

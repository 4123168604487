import React from 'react';

import { Toolbar } from 'react-admin';

import { SaveWithConfirmationDialog } from '../SaveWithConfirmationDialog';

export const NotificationCreateToolbar = ({ invalid, ...rest }: any) => (
  <Toolbar invalid={invalid} {...rest}>
    <SaveWithConfirmationDialog
      disabled={invalid}
      label="Send"
      redirect="list"
      submitOnEnter
    />
  </Toolbar>
);

import React from 'react';

import { Box } from '@material-ui/core';
import { required, SelectInput } from 'react-admin';
import { Form } from 'react-final-form';
import styled from 'styled-components';

import { BodyText, SaveButton } from 'components/atoms';
import { CSVUploadInput } from 'components/molecules';

import { FormProps } from '../ManageSurgeListList/ManageSurgeListList.types';
import { CAN_SEE_SURGE_CHOICES } from '../utils';

export const ManageSurgeListForm: React.FC<FormProps> = ({
  onSubmit,
  loading = false,
}) => (
  <Form
    onSubmit={onSubmit}
    render={formProps => (
      <FormElement onSubmit={formProps.handleSubmit}>
        <Box>
          <CSVUploadInput label="Student Ids:*" source="userFile" />
          <HelpTextContainer>
            <BodyText size="small">
              All students uploaded will be set to your selected setting. If a
              student already has this setting, there will be no change.
            </BodyText>
          </HelpTextContainer>
          <SelectInput
            choices={CAN_SEE_SURGE_CHOICES}
            fullWidth
            label="Students can receive surges"
            name="canSeeSurge"
            source="canSeeSurge"
            validate={required()}
          />
          <SaveButton
            disabled={!formProps.valid}
            label="Submit"
            loading={loading}
          />
        </Box>
      </FormElement>
    )}
  />
);

export const FormElement = styled.form`
  width: 400px;
`;

export const HelpTextContainer = styled(Box)`
  padding-top: 24px;
`;

import React, { ComponentProps } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from 'react-avatar';

import { colors } from '../../../styles';
import {
  CircleImageWrapper,
  CircularProgressWrapper,
} from './CircleImage.styles';

interface Props {
  avatarE2ETestId?: string;
  color?: 'primary' | 'secondary' | 'inherit';
  isLoading?: boolean;
  name?: string;
  size?: ComponentProps<typeof CircleImageWrapper>['size'];
  src?: string;
  variant?: 'determinate' | 'indeterminate' | 'static';
}

const imageSize = 120;

export const CircleImage: React.FC<Props> = ({
  avatarE2ETestId,
  color = 'primary',
  isLoading = false,
  size = imageSize,
  variant,
  ...rest
}) => {
  return (
    <CircleImageWrapper
      data-cy={avatarE2ETestId}
      isLoading={isLoading}
      size={size}
    >
      {isLoading && (
        <CircularProgressWrapper>
          <CircularProgress color={color} variant={variant} />
        </CircularProgressWrapper>
      )}
      <Avatar
        {...rest}
        color={colors.neutralLight}
        fgColor={colors.neutralDark}
        size="100%"
      />
    </CircleImageWrapper>
  );
};

import 'emoji-mart/css/emoji-mart.css';

import React, { FC } from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Loading } from 'react-admin';
import { ThemeProvider } from 'styled-components';

import { theme } from 'styles/theme';
import { keycloak, keycloakInitOptions, resetServerSettings } from 'utils';

import { Admin } from './Admin';

export const App: FC = () => {
  const onKeycloakEvent = (event: any, error: any) => {
    if (event === 'onInitError' || error) resetServerSettings();
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions}
      LoadingComponent={
        <>
          <br />
          <Loading
            loadingPrimary="Welcome"
            loadingSecondary="Your session is initialising..."
          />
        </>
      }
      onEvent={onKeycloakEvent}
    >
      <ThemeProvider theme={theme}>
        <Admin />
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

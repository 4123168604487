import React from 'react';

import { Filter, FilterProps } from 'react-admin';

import { SelectUserInput } from 'components/atoms';

export const DeviceLocationFilter: React.FC<
  Omit<FilterProps, 'children'>
> = props => (
  <Filter {...props}>
    <SelectUserInput alwaysOn label="Student" source="user_id" />
  </Filter>
);

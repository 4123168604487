import React, { ComponentProps, FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const useStyles = makeStyles(
  {
    suggestionsContainer: {
      zIndex: 100000,
    },
  },
  { name: 'AutocompleteInput' },
);

type Props = Omit<ComponentProps<typeof ReferenceInput>, 'reference'> & {
  source: string;
};

export const SelectUserInput: FC<Props> = props => {
  const classes = useStyles();

  return (
    <ReferenceInput
      filter={{ userType_istartswith: 'STUDENT ' }}
      reference="StintUser"
      {...props}
    >
      <AutocompleteInput
        options={{
          suggestionsContainerProps: {
            className: classes?.suggestionsContainer,
          },
        }}
        optionText={(value: any) => {
          return `${value?.firstName} ${value?.lastName} (${value.email})`;
        }}
        suggestionLimit={5}
      />
    </ReferenceInput>
  );
};

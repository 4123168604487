import React from 'react';

import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  Record,
  ReferenceField,
  TextField,
} from 'react-admin';
import styled from 'styled-components';

import { AvatarField } from 'components/atoms';

import { StintopediaFilter } from '../StintopediaFilter';

export const StintopediaList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<StintopediaFilter />}>
      <Datagrid rowClick="edit" style={{ tableLayout: 'fixed' }}>
        <ReferenceField
          label="Business"
          reference="Business"
          source="business.id"
        >
          <FunctionField
            render={(record?: Record) => (
              <BusinessNameContainer>
                <AvatarField record={record} size={24} source="logo" />
                <BusinessName>{record?.name}</BusinessName>
              </BusinessNameContainer>
            )}
            source="id"
          />
        </ReferenceField>
        <TextField emptyText="-" source="emoji" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const BusinessNameContainer = styled.div`
  display: flex;
`;

const BusinessName = styled.div`
  padding-left: 16px;
`;

import React, { ComponentProps } from 'react';

import { TextInput } from 'react-admin';

import { validateRawIdList } from 'utils';

type Props = ComponentProps<typeof TextInput> & {
  idValidationMessage?: string;
};

export const RawIdListInput: React.FC<Props> = ({
  validate = [],
  idValidationMessage,
  ...rest
}) => {
  let customValidators = validate;
  if (!Array.isArray(customValidators)) {
    customValidators = [customValidators];
  }
  return (
    <TextInput
      {...rest}
      format={(values: string[]) => values?.join('\n')}
      parse={(value: string) =>
        value?.replace(/(\n)|(;)|(,)/gi, ',')?.split(',')
      }
      validate={[...customValidators, validateRawIdList(idValidationMessage)]}
    />
  );
};

import React, { FC } from 'react';

import { Box, Card, CardContent, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { colors } from 'styles';

import { StinterStrikesAwardedProps } from './StinterStrikesAwarded.types';

export const StinterStrikesAwarded: FC<StinterStrikesAwardedProps> = ({
  strikesAwarded,
}) => {
  // Need to check explicitly for null and undefined as we need to display 0 strikes
  if (strikesAwarded === null || strikesAwarded === undefined) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          Strike Count
        </Typography>
        <Box flexGrow={1}>
          <Box display="flex" mb="1em">
            <Box mr="1em" mt="0.4em">
              {(strikesAwarded >= 3 && (
                <CancelIcon
                  color="disabled"
                  fontSize="small"
                  data-testid="strikes-awarded-cancel-icon"
                />
              )) || (
                <CheckCircleIcon
                  color="disabled"
                  fontSize="small"
                  data-testid="strikes-awarded-check-icon"
                />
              )}
            </Box>
            <Box flexGrow={1}>
              <Typography
                style={{
                  color:
                    strikesAwarded === 0
                      ? colors.success
                      : strikesAwarded >= 3
                      ? colors.errorDark
                      : colors.starYellow,
                }}
                variant="h5"
                component="p"
                data-testid="strikes-awarded"
              >
                {strikesAwarded}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

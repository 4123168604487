import styled from 'styled-components';

import { colors } from '../../../styles';

export const SearchResultTextFragment = styled.span<{ highlight?: boolean }>`
  color: ${props => (props.highlight ? colors.secondary : colors.primary)};
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 1.38;
  white-space: pre-wrap;
`;

import React, { FC } from 'react';

import {
  ArrayField,
  DateField,
  ReferenceField,
  FunctionField,
  Record,
  SelectField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import styled from 'styled-components';

import {
  TextField as TextFieldComponent,
  Heading,
  PeriodField,
} from 'components';
import { STINT_STATUS_CHOICES, getNullableBooleanDisplayValue } from 'utils';

export const StintShow: FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <HeadingContainer>
        <Heading labelText="Stint Details:" />
      </HeadingContainer>
      <TextField source="id" />
      <TextField source="name" />
      <SelectField
        choices={STINT_STATUS_CHOICES}
        label="Status"
        source="status"
      />
      <DateField label="Posted Date" source="createdAt" />
      <TextFieldComponent label="Stint Times" />
      <PeriodField
        endDateFormat="HH:mm"
        endDateSource="dateTo"
        sortBy="dateFrom"
        startDateFormat="DD/MM/YYYY HH:mm"
        startDateSource="dateFrom"
      />
      <FunctionField
        addLabel={false}
        render={(record?: Record) => {
          if (record?.checkInDate == null) {
            return null;
          }
          const checkInTime = new Date(record?.checkInDate).toLocaleTimeString(
            'en-GB',
            {
              hour: 'numeric',
              minute: 'numeric',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },
          );
          return (
            <TextFieldComponent label="Check In Time" value={checkInTime} />
          );
        }}
      />
      <FunctionField
        addLabel={false}
        render={(record?: Record) => {
          if (record?.actualLeaveTime == null) {
            return null;
          }
          const actualLeaveTime = new Date(
            record?.actualLeaveTime,
          ).toLocaleTimeString('en-GB', {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          });
          return (
            <TextFieldComponent
              label="Actual Leave Time"
              value={actualLeaveTime}
            />
          );
        }}
      />
      <FunctionField
        data-testid="trained"
        addLabel={false}
        render={(record?: Record) => {
          const value = getNullableBooleanDisplayValue(record?.trained);
          return <TextFieldComponent label="Trained" value={value} />;
        }}
      />
      <FunctionField
        addLabel={false}
        render={(record?: Record) => {
          const value = getNullableBooleanDisplayValue(record?.controlCheck);
          return <TextFieldComponent label="Control Check" value={value} />;
        }}
      />
      <FunctionField
        addLabel={false}
        render={(record?: Record) => {
          const value = getNullableBooleanDisplayValue(record?.isBestEffort);
          return <TextFieldComponent label="Best Effort" value={value} />;
        }}
      />
      <TextField label="Special Notes" source="notes" />
      <ArrayField
        basePath="/StintTask"
        fieldKey="id"
        label="Primary Tasks"
        source="primaryTasks"
      >
        <SingleFieldList>
          <ChipField sortable={false} source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField
        basePath="/StintTask"
        fieldKey="id"
        label="Secondary Tasks"
        source="secondaryTasks"
      >
        <SingleFieldList>
          <ChipField sortable={false} source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField
        basePath="/EmploymentException"
        fieldKey="id"
        label="Exceptions"
        source="exceptions"
      >
        <SingleFieldList>
          <ChipField sortable={false} source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Uniform" source="uniform">
        <SingleFieldList>
          <FunctionField
            label="Name"
            render={(value?: unknown) => (
              <ChipField
                record={{ id: value as string }}
                sortable={false}
                source="id"
              />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      <HeadingContainer>
        <Heading labelText="Site Details:" />
      </HeadingContainer>
      <ReferenceField label="Site" reference="Business" source="business_id">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Address" source="displayAddress" />
      <HeadingContainer>
        <Heading labelText="Stinter Details:" />
      </HeadingContainer>
      <ReferenceField label="Stinter" reference="StintUser" source="student_id">
        <TextField source="fullname" />
      </ReferenceField>
      <HeadingContainer>
        <Heading labelText="Site Monetary Values:" />
      </HeadingContainer>
      <TextField
        data-testid="total-fee-with-vat"
        label="Site Total Fee With VAT"
        source="stintRates.totalFeeWithVat"
      />
      <FunctionField
        data-testid="base-fee-override"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.baseFeeOverride ?? 'N/A';
          return (
            <TextFieldComponent
              label="Site Hourly Fee Override"
              value={value}
            />
          );
        }}
      />
      <FunctionField
        data-testid="cancellation-fee"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.cancellationFee ?? 'N/A';
          return <TextFieldComponent label="Cancellation Fee" value={value} />;
        }}
      />
      <FunctionField
        data-testid="cancellation-fee-applied"
        addLabel={false}
        render={(record?: Record) => {
          const value = getNullableBooleanDisplayValue(
            record?.stintRates?.cancellationFeeApplied,
          );
          return (
            <TextFieldComponent
              label="Cancellation Fee Overridden"
              value={value}
            />
          );
        }}
      />
      <HeadingContainer>
        <Heading labelText="Stinter Monetary Values:" />
      </HeadingContainer>
      <TextField
        data-testid="base-pay"
        label="Stinter Base Pay"
        source="stintRates.basePay"
      />
      <FunctionField
        data-testid="base-rate-override"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.baseRateOverride ?? 'N/A';
          return (
            <TextFieldComponent
              label="Stinter Base Hourly Rate Override"
              value={value}
            />
          );
        }}
      />
      <FunctionField
        data-testid="surge-pay"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.surgePay ?? 'N/A';
          return <TextFieldComponent label="Stinter Surge Pay" value={value} />;
        }}
      />
      <FunctionField
        data-testid="surge-rate"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.surgeRate ?? 'N/A';
          return (
            <TextFieldComponent
              label="Stinter Hourly Surge Rate"
              value={value}
            />
          );
        }}
      />
      <FunctionField
        data-testid="surge-applied"
        addLabel={false}
        render={(record?: Record) => {
          const value = getNullableBooleanDisplayValue(
            record?.stintRates?.surgeApplied,
          );
          return <TextFieldComponent label="Surge Pay Applied" value={value} />;
        }}
      />
      <FunctionField
        data-testid="bonus-pay"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.bonusPay ?? 'N/A';
          return <TextFieldComponent label="Stinter Boost Pay" value={value} />;
        }}
      />
      <FunctionField
        data-testid="bonus-rate"
        addLabel={false}
        render={(record?: Record) => {
          const value = record?.stintRates?.bonusRate ?? 'N/A';
          return (
            <TextFieldComponent
              label="Stinter Hourly Boost Rate"
              value={value}
            />
          );
        }}
      />
      <FunctionField
        data-testid="bonus-applied"
        addLabel={false}
        render={(record?: Record) => {
          const value = getNullableBooleanDisplayValue(
            record?.stintRates?.bonusApplied,
          );
          return <TextFieldComponent label="Boost Pay Applied" value={value} />;
        }}
      />
    </SimpleShowLayout>
  </Show>
);

const HeadingContainer = styled.div`
  margin-bottom: 12px;
  margin-top: 24px;
`;

import { Location } from 'types';

export const getMapBounds = (maps: typeof google.maps, places: Location[]) => {
  const bounds = new maps.LatLngBounds();

  places.forEach(place => {
    bounds.extend(new maps.LatLng(place.latitude, place.longitude));
  });

  return bounds;
};

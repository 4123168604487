import React from 'react';

import {
  AutocompleteInput,
  EditProps,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Edit } from 'components/organisms';

export const PostalCodeEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="postalCode" validate={required()} />
      <ReferenceInput
        alwaysOn
        label="City"
        reference="City"
        source="city_id"
        validate={required()}
      >
        <AutocompleteInput emptyText="-" optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

import React, { FC } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  DateTimeInput,
  EditProps,
  Record,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Edit } from 'components';

import { GeotrackingCustomToolbar } from '../GeotrackingEditToolbar';
import { LATE_REASON_CHOICES, useDrawerStyles } from '../utils';

interface Props extends EditProps {
  onCancel: VoidFunction;
}

export const GeotrackingEditDrawer: FC<Props> = ({ onCancel, ...props }) => {
  const classes = useDrawerStyles();
  const transform = (data: Record) => ({
    ...data,
    differentStartTimeReasons: data.stint.differentStartTimeReasons,
    checkInDate: data.stint.checkInDate,
  });

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Edit Details</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <Edit {...props} transform={transform}>
        <SimpleForm
          className={classes.form}
          toolbar={<GeotrackingCustomToolbar />}
        >
          <DateTimeInput
            fullWidth
            label="Estimated Departure Time"
            source="estimatedTimeOfDeparture"
          />
          <DateTimeInput
            fullWidth
            label="Estimated Arrival Time"
            source="estimatedTimeOfArrival"
          />
          <DateTimeInput
            fullWidth
            label="Check In"
            source="stint.checkInDate"
          />
          <SelectInput
            alwaysOn
            choices={LATE_REASON_CHOICES}
            fullWidth
            label="Late Reason"
            source="stint.differentStartTimeReasons"
          />
          <TextInput fullWidth label="Late Note" multiline source="lateNote" />
          <TextInput
            fullWidth
            label="Internal Note"
            multiline
            source="internalNotes"
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};

import React, { ReactElement } from 'react';

import { Grid, Box } from '@material-ui/core';
import { TopToolbar, ShowButton, EditButton, Record } from 'react-admin';

import { ViewButton } from 'components/atoms';
import { StintCreate } from 'components/models/Stints/StintCreate';
import { EditActionsProps, ShowActionsProps } from 'components/types';

import { BusinessQueryRecord } from '../Business.types';

interface BusinessActionsToolbarComponent {
  (props: EditActionsProps<BusinessQueryRecord>): ReactElement;
  (props: ShowActionsProps<BusinessQueryRecord>): ReactElement;
}

export const BusinessActionsToolbar: BusinessActionsToolbarComponent = ({
  basePath,
  className,
  data,
  ...rest
}) => {
  const hasEdit = 'hasEdit' in rest ? rest.hasEdit : null;
  const hasShow = 'hasShow' in rest ? rest.hasShow : null;
  if (!data) return <></>;
  return (
    <TopToolbar className={className}>
      <Grid container direction="row" alignItems="center">
        <Box mr={2}>
          <ViewButton
            label="Stintopedia"
            recordTo={(record: Record) =>
              record?.stintopedia?.id
                ? `/Stintopedia/${record.stintopedia.id}/show`
                : null
            }
          />
        </Box>
        {hasShow && (
          <Box ml="auto">
            <ShowButton basePath={basePath} record={data} />
          </Box>
        )}
        {hasEdit && (
          <Box ml="auto">
            <StintCreate businessId={data.id} />
            <EditButton basePath={basePath} record={data} />
          </Box>
        )}
      </Grid>
    </TopToolbar>
  );
};

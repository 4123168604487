import React, { useEffect } from 'react';

import { Location } from 'types';

interface Props {
  location?: Location;
  marker: google.maps.Marker;
  map?: google.maps.Map;
}

export const LiveMapMarker: React.FC<Props> = ({ location, marker, map }) => {
  useEffect(() => {
    if (!map || !marker) {
      return;
    }

    const markerPosition = location
      ? new google.maps.LatLng(location.latitude, location.longitude)
      : null;

    marker.setMap(map);
    marker.setPosition(markerPosition);
  }, [map, location, marker]);

  return null;
};

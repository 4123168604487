import React, { FC, useCallback, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  BooleanField,
  DateField,
  EditProps,
  FunctionField,
  LinearProgress,
  Record,
  ReferenceField,
  TextField,
} from 'react-admin';

import { BodyText } from 'components/atoms';
import {
  GeotrackerLiveMap,
  UserAvatarWithFullNameField,
} from 'components/index';
import { LIVE_MAP_LOCATION_REFRESH_RATE_IN_SECONDS } from 'config';
import { useInterval } from 'hooks';

import { useLocations } from '../hooks';
import { getLocationCreatedAfterThreshold } from '../utils';

interface Props extends EditProps {
  record: Record;
}

export const GeotrackingMapMonolith: FC<Props> = ({ record }) => {
  const {
    fetchRecentLocations,
    fetchInitialLocations,
    latestLocation,
    historicalLocations,
    locationsLoading,
    latestInitialLocation,
    setLocationsLoading,
  } = useLocations();

  useInterval(() => {
    fetchRecentLocations({
      studentId: record?.student_id,
      createdAfter: getLocationCreatedAfterThreshold(record?.stint?.dateFrom),
    });
  }, LIVE_MAP_LOCATION_REFRESH_RATE_IN_SECONDS * 1000);

  const fetchInitialLocationsCallback = useCallback(fetchInitialLocations, []);

  useEffect(() => {
    setLocationsLoading(true);
    fetchInitialLocationsCallback({
      studentId: record?.student_id,
      createdAfter: getLocationCreatedAfterThreshold(record?.stint?.dateFrom),
    });
  }, [record, fetchInitialLocationsCallback, setLocationsLoading]);

  if (!record?.id) return <LinearProgress />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BodyText gutterBottom>Journey ID</BodyText>
          <TextField record={record} source="id" />
        </Grid>
      </Grid>
      <Box m={2} />
      <Grid container spacing={2}>
        <Grid alignItems="center" container item xs={6}>
          <ReferenceField
            basePath="/StintUser"
            label="Student"
            record={record}
            reference="StintUser"
            resource="StintJourney"
            source="student_id"
          >
            <UserAvatarWithFullNameField />
          </ReferenceField>
        </Grid>
        <Grid alignItems="center" container item xs={6}>
          <BodyText>Last location update</BodyText>
          <DateField
            emptyText="-"
            record={latestLocation || latestInitialLocation}
            showTime
            source="recordedAt"
          />
        </Grid>
      </Grid>
      <Box m={2} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <BodyText>Business</BodyText>
          <ReferenceField
            basePath="/Business"
            label="Business name"
            record={record}
            reference="Business"
            resource="StintJourney"
            source="stint.business_id"
          >
            <TextField source="name" />
          </ReferenceField>
        </Grid>
        <Grid item xs={3}>
          <BodyText>Confirmed</BodyText>
          <BooleanField
            emptyText="-"
            label="Confirmation"
            record={record}
            source="confirmationScreenAccepted"
          />
        </Grid>
        <Grid item xs={3}>
          <BodyText>Check-In</BodyText>
          <DateField
            emptyText="No"
            label="Check-In"
            record={record}
            showTime
            source="stint.checkInDate"
          />
        </Grid>
        <Grid item xs={3}>
          <BodyText>Phone Number</BodyText>
          <ReferenceField
            basePath="/StintUser"
            label="Student"
            record={record}
            reference="StintUser"
            resource="StintJourney"
            source="student_id"
          >
            <TextField emptyText="-" record={record} source="phoneNumber" />
          </ReferenceField>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!locationsLoading ? (
            <GeotrackerLiveMap
              historicalLocations={historicalLocations}
              initialLocation={record?.studentInitialLocation}
              latestLocation={latestLocation}
              stintLocation={record?.stint}
            />
          ) : (
            <LinearProgress />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <BodyText>Geofence Zone Entry (45m)</BodyText>
          <TextField
            emptyText="-"
            record={record}
            source="stint.address.name"
          />
        </Grid>
        <Grid item xs={6}>
          <BodyText>Business address</BodyText>
          <TextField
            emptyText="-"
            record={record}
            source="stint.address.name"
          />
        </Grid>
        <Grid item xs={6}>
          <BodyText>Location Setting</BodyText>
          <ReferenceField
            basePath="/StintUser"
            label="Student"
            record={record}
            reference="StintUser"
            resource="StintJourney"
            source="student_id"
          >
            <TextField source="locationSetting" />
          </ReferenceField>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Granular Tracking</BodyText>
          <FunctionField
            record={record}
            render={(r?: Record) =>
              r?.isGranularLocationTrackingEnabled ? 'On' : 'Off'
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

import React, { FC } from 'react';

import {
  NumberField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { DateTimeField } from 'components/atoms';

export const NotificationShow: FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateTimeField label="Sent at" showTime source="createdAt" />
      <TextField source="title" />
      <TextField label="Body text" source="message" />
      <NumberField label="Recipients count" source="recipientsCount" />
      <ReferenceField
        label="Business name"
        reference="Business"
        source="business.id"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField emptyText="-" label="Stint Id" source="stint.id" />
      <TextField emptyText="-" label="Stint Type" source="stint.name" />
      <NumberField label="Open count" source="openedCount" />
      <TextField label="Category" source="notificationType" />
      <TextField emptyText="-" source="errorMessage" />
    </SimpleShowLayout>
  </Show>
);

import * as React from 'react';
import { ReactElement, ReactNode } from 'react';

import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ReduxState, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(2),
    },
  },
}));

interface Props {
  dense: boolean;
  handleToggle: VoidFunction;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

export const SubMenu: React.FC<Props> = ({
  handleToggle,
  isOpen,
  name,
  icon,
  children,
  dense,
}: Props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const sidebarIsOpen = useSelector<ReduxState, boolean>(
    state => state.admin.ui.sidebarOpen,
  );

  const header = (
    <MenuItem button dense={dense} onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography color="textSecondary" variant="inherit">
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip placement="right" title={translate(name)}>
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
          component="div"
          dense={dense}
          disablePadding
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

import React from 'react';

import {
  BooleanField,
  ChipField,
  ReferenceArrayField,
  ResourceComponentProps,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin';

export const CityShow: React.FC<ResourceComponentProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField label="Verification URL" source="sessionUrl" />
      <TextField label="region" />
      <TextField label="City code" source="shortCode" />
      <BooleanField source="isActive" />
      <ReferenceArrayField
        label="Postal codes"
        perPage={400}
        reference="PostalCode"
        source="postalCodeIds"
      >
        <SingleFieldList>
          <ChipField source="postalCode" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

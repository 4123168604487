import React from 'react';

import {
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from 'react-admin';

import { TopTipFilter } from '../TopTipFilter';

export const TopTipList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<TopTipFilter />}>
      <Datagrid rowClick="edit">
        <TextField label="Name" source="name" />
        <ReferenceField label="Task" reference="StintTask" source="task_id">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

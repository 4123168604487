import React from 'react';

import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  ResourceComponentProps,
  TextField,
  UrlField,
} from 'react-admin';

import { CityFilter } from '../CityFilter';
import { DisplayListOfPostalCodes } from '../DisplayListOfPostalCodes';

export const CityList: React.FC<ResourceComponentProps> = props => {
  return (
    <List {...props} filters={<CityFilter />}>
      <Datagrid expand={<DisplayListOfPostalCodes />}>
        <TextField source="name" />
        <TextField emptyText="-" label="Code" source="shortCode" />
        <TextField emptyText="-" label="Region" source="region.name" />
        <TextField emptyText="-" label="Country" source="region.country.name" />
        <BooleanField source="isActive" />
        <TextField emptyText="-" label="Business Base Fee" source="baseFee" />
        <TextField
          emptyText="-"
          label="Student Base Fee"
          source="baseFeeStudent"
        />
        <BooleanField source="isAvailabilityEnabled" />
        <UrlField emptyText="-" source="sessionUrl" target="_blank" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

import { StintUser } from 'apollo/schema';

type StintUserExtended = StintUser &
  Partial<{
    exceptionsIds: string[];
    user?: StintUser & { exceptionsIds: string[] };
    enabled?: boolean;
  }>;

export const transformStudentDataBeforeSave = (userData: StintUserExtended) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email: _mono_email, ...legacy_profile } = userData?.user || userData;
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    id: _auth_id,
    email: _auth_email,
    ...cleaned_up_user_data
  } = userData;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  return {
    ...legacy_profile,
    ...cleaned_up_user_data,
    secondaryEmail: userData.email,
    studentProfile: {
      emergencyContactName:
        legacy_profile?.studentProfile?.emergencyContactName,
      emergencyContactNumber:
        legacy_profile?.studentProfile?.emergencyContactNumber,
      emergencyContactRelation:
        legacy_profile?.studentProfile?.emergencyContactRelation,
      employeeStatement: legacy_profile?.studentProfile?.employeeStatement,
      studentLoanPlan: legacy_profile?.studentProfile?.studentLoanPlan,
      universityId: legacy_profile?.studentProfile?.university_id,
    },
    paymentMethod: {
      accountName:
        legacy_profile?.studentProfile?.paymentMethods?.[0]?.accountName,
      accountNumber:
        legacy_profile?.studentProfile?.paymentMethods?.[0]?.accountNumber,
      sortCode: legacy_profile?.studentProfile?.paymentMethods?.[0]?.sortCode,
    },
    exceptions:
      legacy_profile.exceptionsIds?.map((id: string) => ({
        id,
      })) || [],
  };
};

import React from 'react';

import { EditProps, required, SimpleForm, TextInput } from 'react-admin';

import { Edit } from 'components/organisms';

import { EmploymentExceptionCategorySelectInput } from '../EmploymentExceptionCategorySelectInput';

export const EmploymentExceptionEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <EmploymentExceptionCategorySelectInput validate={required()} />
    </SimpleForm>
  </Edit>
);

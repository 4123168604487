import React from 'react';

import { InputProps, Labeled, useInput } from 'react-admin';

import { LogoUpload } from '../LogoUpload';

export type ImageUploadInputProps = InputProps & {
  size?: number;
};

export const ImageUploadInput = ({ size, ...rest }: ImageUploadInputProps) => {
  // Custom parser allows React Admin to set empty value to string
  // Our API only accepts empty string and doesn't work with null
  const parse = (value: string) => value;

  const {
    input: { name, onChange, value },
  } = useInput({ ...rest, parse });

  return (
    <Labeled label={rest?.label}>
      <LogoUpload name={name} onLoad={onChange} size={size} src={value} />
    </Labeled>
  );
};

import React, { FC } from 'react';

import { EditProps, required, SimpleForm, TextInput } from 'react-admin';

import { Edit, ImageUploadInput } from 'components';

export const UniversityEdit: FC<EditProps> = props => (
  <Edit {...props}>
    <SimpleForm>
      <ImageUploadInput label="Logo" size={120} source="logo" />
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="domain" validate={required()} />
    </SimpleForm>
  </Edit>
);

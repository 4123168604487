import React from 'react';

import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

export const EmployerShowForm: React.FC<ShowProps> = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phoneNumber" />
        <TextField source="appVersion" />
      </SimpleShowLayout>
    </Show>
  );
};

import React, { ComponentProps } from 'react';

import GoogleMapReact from 'google-map-react';

import { DEFAULT_MAP_ZOOM_LEVEL, GOOGLE_PLACES_API_KEY } from 'config';
import { Location } from 'types';

type Props = Omit<ComponentProps<typeof GoogleMapReact>, 'defaultCenter'> & {
  defaultCenter?: Location;
};

export const GoogleMap: React.FC<Props> = ({
  defaultCenter,
  defaultZoom = DEFAULT_MAP_ZOOM_LEVEL,
  ...rest
}) => {
  const getCenter = (location?: Location) => {
    if (!location?.latitude || !location?.longitude) {
      return undefined;
    }
    return {
      lat: Number(location.latitude),
      lng: Number(location.longitude),
    };
  };

  return (
    <GoogleMapReact
      {...rest}
      bootstrapURLKeys={GOOGLE_PLACES_API_KEY}
      defaultCenter={getCenter(defaultCenter)}
      defaultZoom={defaultZoom}
    />
  );
};

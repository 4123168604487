import React, { ComponentProps, FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ChipField as RAChipField } from 'react-admin';

const useStyles = makeStyles(
  {
    chip: {
      margin: '6px 6px 6px 0',
      cursor: 'inherit',
    },
  },
  { name: 'RaChipField' },
);

type Props = ComponentProps<typeof RAChipField>;

export const ChipField: FC<Props> = props => {
  const classes = useStyles();
  return <RAChipField className={classes.chip} {...props} />;
};

import React, { FC } from 'react';

import {
  DateInput,
  Filter,
  FilterProps,
  SelectInput,
  TextInput,
} from 'react-admin';

import { STINT_STATUS_CHOICES } from 'utils';

export const StudentStintsFilter: FC<Omit<FilterProps, 'children'>> = props => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn label="Business ID" source="business_id" />
      <DateInput alwaysOn label="Start date" source="start_from" />
      <SelectInput
        alwaysOn
        choices={STINT_STATUS_CHOICES}
        label="Status"
        source="stint_status"
      />
    </Filter>
  );
};

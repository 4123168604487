import moment from 'moment-timezone';

import { TabsType } from './types';

const timezone = 'Europe/London';

export const filterDefaults = {
  statusGroup: TabsType.ALL,
  startDate: moment().tz(timezone).format('YYYY-MM-DD'),
};

export const sortDefaults = {
  field: 'stint.dateFrom',
  order: 'ASC',
};

export const colors = {
  background: '#eaeaec',
  black: '#000000',
  boxShadow: 'rgba(0, 0, 0, 0.2)',
  content_border: '#EBEDF8',
  darkText: 'rgba(0, 0, 0, 0.87)',
  dashboardBg: '#f5f5f5',
  error: '#d71d34',
  errorDark: '#9a1919',
  errorLight: '#f0d1d1',
  extremelyBusyColor: '#a49ef2',
  graphBottom: '#ceff7b',
  graphTop: '#6dd400',
  hoverPrimary: '#4a90e2',
  moderatelyBusyColor: '#69cdd7',
  neutralDark: '#686868',
  neutralDark60: 'rgba(104, 104, 104, 0.6)',
  neutralDarkest: '#37393c',
  neutralLight: '#eaeaec',
  neutralLightest: '#f6f6f6',
  neutralLightest60: 'rgba(246, 246, 246, 0.6)',
  notBusyColor: '#c1eaee',
  onPrimary: '#ffffff',
  onSecondary: '#ffffff',
  opacity: 'rgba(0,0,0,0.4)',
  placeholderGrey: '#a4a4a4',
  primary_hover: '#7c7c7c',
  primary: '#303234',
  secondary_hover: '#000000',
  secondary: '#61b4f2',
  secondary60: 'rgba(74, 144, 226, 0.6)',
  shadeBlue: '#3f51b5',
  starYellow: '#ebb12e',
  stintPeach: '#a49ef2',
  success: '#6dd400',
  successDark: '#176404',
  successLight: '#d4f0d1',
  surface: '#ffffff',
  tango: '#fa6400',
  textDisabled: '#f6f6f6',
  textPrimary: '#303234',
  transparent: 'transparent',
  veryBusyColor: '#61b4f2',
  warning: '#ff5555',
  white: '#ffffff',
  whiteTwo: 'f7f7f7',
};

import { Button } from 'react-admin';
import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 512px;
`;

export const WrapButton = styled(Button)`
  color: #fff;
  background-color: #3f51b5;
  .Mui-disabled {
    background-color: #e1e1e1;
  }
  :hover {
    background-color: #3f6eb5;
  }
  width: 100%;
`;

export const Container = styled.div`
  padding-bottom: 10px;
`;

import React, { FC } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  AutocompleteArrayInput,
  DateTimeInput,
  EditProps,
  FormDataConsumer,
  Record,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { EditTemplate, SearchAddressField } from 'components';
import { UNIFORM, validateUniform } from 'utils';

import { GeotrackingCustomToolbar } from '../GeotrackingEditToolbar';
import { useDrawerStyles } from '../utils';

interface Props extends EditProps {
  onCancel: VoidFunction;
}

const transform = (data: Record) => ({
  ...data,
  primaryTasks: data.primaryTasks.map((id: string) => ({ id })),
  exceptions: data.exceptions?.map((id: string) => ({ id })) || [],
  cancellationFeeApplied: null,
});

const transformRecord = (record: any) => {
  return {
    ...record,
    exceptions: record?.exceptions?.map((exception: any) => exception.id),
    primaryTasks: record?.primaryTasks?.map((task: any) => task.id),
    cancellationFeeApplied: null,
  };
};

export const GeotrackingStintEditDrawer: FC<Props> = ({
  onCancel,
  ...props
}) => {
  const classes = useDrawerStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Stint Details</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditTemplate
        {...props}
        transform={transform}
        transformRecord={transformRecord}
      >
        <SimpleForm
          className={classes.form}
          toolbar={<GeotrackingCustomToolbar />}
        >
          <TextInput
            fullWidth
            label="Stint Name"
            source="name"
            validate={required()}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <SearchAddressField
                  placeholder={formData.displayAddress}
                  source="displayAddress"
                  {...formData}
                />
                <Box component="div" display="none">
                  {formData?.placeId && (
                    <TextInput label="Place Id" source="placeId" />
                  )}
                </Box>
              </>
            )}
          </FormDataConsumer>
          <DateTimeInput
            alwaysOn
            fullWidth
            label="Stint Start Time"
            showTime
            source="dateFrom"
          />
          <DateTimeInput
            alwaysOn
            fullWidth
            label="Stint End Time"
            showTime
            source="dateTo"
          />
          <TextInput fullWidth label="Special Notes" source="notes" />

          <FormDataConsumer>
            {() => {
              return (
                <ReferenceArrayInput
                  label="Tasks"
                  perPage={100}
                  reference="StintTask"
                  source="primaryTasks"
                  validate={required()}
                >
                  <AutocompleteArrayInput
                    optionText="name"
                    suggestionLimit={5}
                  />
                </ReferenceArrayInput>
              );
            }}
          </FormDataConsumer>

          <ReferenceArrayInput
            label="Exceptions"
            perPage={400}
            reference="EmploymentException"
            source="exceptions"
          >
            <AutocompleteArrayInput
              fullWidth
              optionText="name"
              suggestionLimit={5}
            />
          </ReferenceArrayInput>
          <AutocompleteArrayInput
            choices={UNIFORM}
            fullWidth
            optionText="name"
            source="uniform"
            translateChoice={false}
            validate={validateUniform}
          />
        </SimpleForm>
      </EditTemplate>
    </div>
  );
};

import React, { FC } from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  ShowButton,
  TextField,
} from 'react-admin';

import { EditWithBackLinkButton } from 'components/atoms';

export const CancellationHistory: FC<any> = ({ record }) => {
  return (
    <ReferenceManyField
      basePath="/Stint"
      filter={{ status: 5 }}
      fullWidth
      label=""
      pagination={<Pagination />}
      perPage={10}
      record={record}
      reference="Stint"
      target="business_id"
    >
      <Datagrid>
        <TextField
          emptyText="-"
          label="Notice given"
          sortable={false}
          source="cancelledTimeAhead"
        />
        <DateField
          emptyText="-"
          label="Cancellation date"
          source="cancelledAt"
        />
        <ReferenceField
          emptyText="-"
          label="Employer"
          reference="StintUser"
          source="cancelled_by_id"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <TextField label="Stint ID" source="id" />
        <TextField label="Role" source="name" />
        <ReferenceField
          emptyText="-"
          label="Student"
          reference="StintUser"
          source="student_id"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <NumberField
          emptyText="-"
          label="Cancellation charge"
          source="stintRates.cancellationFee"
        />
        <BooleanField
          label="Cancellation charge override"
          source="stintRates.cancellationFeeApplied"
        />
        <EditWithBackLinkButton />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  );
};

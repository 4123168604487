import React, { FC } from 'react';

import {
  DateInput,
  Filter,
  FilterProps,
  SelectInput,
  TextInput,
} from 'react-admin';

import { getUpcomingStintAllowedStatuses } from 'components/models/Student/utils';

const ALLOWED_STATUSES = getUpcomingStintAllowedStatuses();

export const StudentUpcomingStintsFilter: FC<
  Omit<FilterProps, 'children'>
> = props => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn label="Business ID" source="business_id" />
      <DateInput alwaysOn label="Start date" source="startFrom" />
      <SelectInput
        alwaysOn
        choices={ALLOWED_STATUSES}
        label="Status"
        source="status"
      />
    </Filter>
  );
};

import React from 'react';

import { EditProps, required, SimpleForm, TextInput } from 'react-admin';

import { StintUser } from 'apollo/schema';
import { BodyText, Edit } from 'components';
import { UserEditToolbar } from 'components/models/Users/UserEditToolbar';

import { EmployerEditAsideActions } from './EmployerEditAsideActions';
import { transformEmployerDataBeforeSave } from './utils';

type EmployerEditFormProp = {
  record: StintUser & { user?: StintUser };
};

export const EmployerEditForm: React.FC<
  EditProps & EmployerEditFormProp
> = props => {
  const transformDataBeforeSave = (userData: StintUser) => {
    return transformEmployerDataBeforeSave(userData);
  };
  const { record: stintUser, ...restProp } = props;

  return (
    <Edit
      {...restProp}
      aside={<EmployerEditAsideActions />}
      transform={data => transformDataBeforeSave(data as StintUser)}
    >
      <SimpleForm toolbar={<UserEditToolbar />}>
        {(!stintUser?.user && (
          <BodyText bold>
            The user has not yet logged in for the first time, so they cannot be
            edited
          </BodyText>
        )) || (
          <TextInput
            disabled
            label="ID (from mono)"
            source="user.id"
            fullWidth
          />
        )}
        <TextInput
          disabled
          label="Authentication ID (from Keycloak)"
          source="id"
          fullWidth
        />
        <TextInput source="firstName" validate={required()} fullWidth />
        <TextInput source="lastName" validate={required()} fullWidth />
        <TextInput
          label="Email"
          source="email"
          validate={required()}
          fullWidth
        />
        <TextInput
          label="Phone number"
          source="user.phoneNumber"
          validate={required()}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Confirm, SaveButton, Toolbar, ToolbarProps } from 'react-admin';
import { useForm } from 'react-final-form';

import { CancelStintButton } from 'components/molecules';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const CustomToolbar: React.FC<Omit<ToolbarProps, 'width'>> = props => {
  const form = useForm();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const isLongerThan12Hours = () => {
    const formData = form.getState().values;
    const stintStartDate = moment(formData.dateFrom);
    const stintEndDate = moment(formData.dateTo);
    const duration = stintEndDate.diff(stintStartDate, 'minutes');
    return duration > 12 * 60;
  };

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton
        data-testid="stint-edit-save-button"
        handleSubmitWithRedirect={() => {
          if (isLongerThan12Hours()) setIsConfirmDialogOpen(true);
          else props.handleSubmitWithRedirect?.();
        }}
      />
      <Confirm
        cancel="No"
        confirm="Yes"
        content="This Stint is now longer than 12 hours. Are you sure you want to continue?"
        isOpen={isConfirmDialogOpen}
        onClose={() => {
          setIsConfirmDialogOpen(false);
        }}
        onConfirm={() => props.handleSubmitWithRedirect?.()}
        title="Are you sure?"
      />
      <CancelStintButton />
    </Toolbar>
  );
};

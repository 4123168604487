import React from 'react';

import { InjectedFieldProps } from 'ra-ui-materialui/src/field/types';
import { TextField, ArrayField, Datagrid } from 'react-admin';

import { DateTimeField } from 'components/atoms';
import { AppealStatusField } from 'components/models/Appeal/AppealStatusField';

import { OffenceQueryRecord } from '../../Offence/Offence.types';

export const AppealTable = (props: InjectedFieldProps<OffenceQueryRecord>) => (
  <ArrayField
    {...props}
    source="appeals"
    label="Appeal history"
    data-testid="appeals"
  >
    <Datagrid rowClick="">
      <AppealStatusField
        source="status"
        label="Status"
        data-testid="status"
        sortable={false}
      />
      <DateTimeField
        source="date"
        label="Date"
        showTime
        data-testid="date"
        sortable={false}
      />
      <TextField
        source="notes"
        label="Notes"
        data-testid="notes"
        sortable={false}
      />
      <DateTimeField
        source="decisionAt"
        label="Decision Date"
        showTime
        data-testid="decisionAt"
        sortable={false}
      />
      <TextField
        source="decisionBy.fullname"
        label="Decision By"
        data-testid="decisionBy"
        sortable={false}
      />
    </Datagrid>
  </ArrayField>
);

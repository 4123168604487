import React from 'react';

import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { FormApi } from 'final-form';
import { CardContentInner, Labeled, useNotify } from 'react-admin';

import {
  useBulkUpdateCanSeeSurgeMutation,
  useCountStudentsCanSeeSurgeQuery,
} from 'apollo/schema';
import { BodyText } from 'components/atoms';
import { readFileAsDataURL } from 'hooks/useCrop/useCrop';

import { ManageSurgeListForm } from '../ManageSurgeListForm';
import { FormValues } from './ManageSurgeListList.types';

export const ManageSurgeListList: React.FC = () => {
  const [onSubmitCanSeeSurge, { loading }] = useBulkUpdateCanSeeSurgeMutation({
    fetchPolicy: 'no-cache',
  });
  const { data: canSeeSurgeData, refetch } = useCountStudentsCanSeeSurgeQuery();
  const notify = useNotify();

  const onSubmit = async (
    values: FormValues,
    form: FormApi<FormValues, Partial<Record<string, any>>>,
  ) => {
    try {
      const base64UserFile = await readFileAsDataURL(values.userFile as File);

      const { data } = await onSubmitCanSeeSurge({
        variables: {
          canSeeSurge: values.canSeeSurge === 'yes',
          userFile: base64UserFile,
        },
      });

      const message = data?.bulkUpdateCanSeeSurge?.message;

      if (data?.bulkUpdateCanSeeSurge?.success && message) {
        notify(message);
      }

      refetch();
      form.restart();
    } catch (e) {
      notify((e as Error).message, 'error');
      form.restart();
    }
  };

  return (
    <Card>
      <CardContentInner>
        <Box>
          <Labeled label="Amount of students who can currently receive surges">
            <BodyText>{canSeeSurgeData?.countStudentsCanSeeSurge}</BodyText>
          </Labeled>
          <ManageSurgeListForm loading={loading} onSubmit={onSubmit} />
        </Box>
      </CardContentInner>
    </Card>
  );
};

import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { BaseEmoji, Picker as EmojiPicker } from 'emoji-mart';
import { InputProps, TextInput, useInput } from 'react-admin';
import styled from 'styled-components';

export type EmojiInputProps = InputProps;

export const EmojiInput = (props: EmojiInputProps) => {
  const [emojiPickerHidden, setEmojiPickerHidden] = useState(true);
  const {
    input: { onChange, value },
  } = useInput(props);

  const handleEmojiOnSelect = ({ native: emoji }: BaseEmoji) => {
    if (emoji) {
      onChange(`${value}${emoji}`);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setEmojiPickerHidden(true)}>
      <div>
        <TextInput
          autoComplete="off"
          onFocus={() => setEmojiPickerHidden(false)}
          {...props}
        />
        <EmojiWrapper>
          <EmojiPickerDropdown hidden={emojiPickerHidden}>
            <EmojiPicker
              emoji={value}
              native
              onSelect={handleEmojiOnSelect}
              title="Skin color"
            />
          </EmojiPickerDropdown>
        </EmojiWrapper>
      </div>
    </ClickAwayListener>
  );
};

const EmojiWrapper = styled.div`
  position: relative;
  z-index: 10;
`;

const EmojiPickerDropdown = styled.div<{ hidden: boolean }>`
  & {
    position: absolute;
    top: 0;
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  }
`;

import React, { FC } from 'react';

import { FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    label: {
      position: 'relative',
    },
    value: {
      fontFamily: theme.typography.fontFamily,
      color: 'currentColor',
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1) / 2}px`,
      border: 0,
      boxSizing: 'content-box',
      verticalAlign: 'middle',
      background: 'none',
      margin: 0, // Reset for Safari
      display: 'block',
      width: '100%',
    },
  }),
  { name: 'RaLabeled' },
);

interface FieldProps {
  /** The label to be displayed for the field */
  label: string;
}

/**
 * The field components such as TextField and ImageField from react-admin does not render
 * correctly unless it is a direct child of the SimpleShowLayout.
 * This makes it difficult to extract groups of fields into separate components to be reusable.
 * This component is used maintain a consist appearance of custom fields when used along side
 * react-admin fields. It is an outer layer that can be reused to render different children
 * (e.g. text or images)
 */
export const Field: FC<FieldProps> = ({ label, children }) => {
  const classes = useStyles();
  return (
    <div>
      <FormControl margin="dense">
        <InputLabel shrink className={classes.label}>
          {label}
        </InputLabel>
        <div className={classes.value}>{children}</div>
      </FormControl>
    </div>
  );
};

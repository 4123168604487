import React from 'react';

import { EditProps, required, SimpleForm, TextInput } from 'react-admin';

import { Edit } from 'components/organisms';

export const TagsEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);

import {
  ComponentType,
  // useEffect,
  // useState
} from 'react';

// import { useKeycloak } from '@react-keycloak/web';
import { LDClient } from 'launchdarkly-js-client-sdk';
import {
  useFlags,
  useLDClient,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';

import { FeatureFlags, FeatureFlagTrackEvents } from './feature-flags.types';

interface ExtendedWindow extends Omit<Window, 'LOCAL_SETTINGS'> {
  LOCAL_SETTINGS: {
    ENV: string;
    LAUNCH_DARKLY_CLIENT_ID: string;
  };
}

declare let window: ExtendedWindow;
const ENV = window?.LOCAL_SETTINGS?.ENV;
const LAUNCH_DARKLY_CLIENT_ID = window?.LOCAL_SETTINGS?.LAUNCH_DARKLY_CLIENT_ID;

const customAttributes = { browser: navigator.appName, platform: 'web' };
const anonymous = 'anonymous';

if (ENV === 'dev' && !LAUNCH_DARKLY_CLIENT_ID) {
  // eslint-disable-next-line no-console
  console.error(
    'The Launch Darkly Client ID is missing, Please consider adding this in your local settings configuration if you require this',
  );
}

export const withFeatureFlags = (
  App: ComponentType,
  custom?: { application: 'Business' | 'React Admin' | 'Old Admin' },
) =>
  withLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
    reactOptions: { useCamelCaseFlagKeys: true },
    user: {
      anonymous: true,
      custom: { ...customAttributes, ...custom },
      key: anonymous,
    },
  })(App);

/**
 * Most commonly used hook that has access to all of the feature flags stored in FeatureFlagContext.Provider.
 * Provides real-time updates.
 *
 * Example Usage:
 * ```tsx
 * const { someFeature, anotherFeature } = useFeatureFlags();
 * ```
 *
 */
export const useFeatureFlags = () => useFlags() as FeatureFlags;

/**
 * @param event - The name of the event, which may correspond to a goal in A/B tests
 * @param data - Optional additional information to associate with the event
 * @param metricValue - Optional numeric value to attach to the tracked event
 *
 * Track events to use in goals or A/B tests.
 */
export const useTrackFeatureFlag = () => {
  const ldClient = useLDClient();
  return (
    event: FeatureFlagTrackEvents,
    data?: Parameters<LDClient['track']>[1],
    metricValue?: Parameters<LDClient['track']>[2],
  ) => {
    ldClient?.track(event, data, metricValue);
  };
};

/**
 * @param user - The user for evaluation and event reporting
 *
 * If your app is used by multiple users on a single device, you may want to change users and
 * have separate flag settings for each user. To achieve this, the SDK will store the last 5
 * user contexts on a single device, with support for switching between different user contexts.
 *
 * The user key is the only mandatory user attribute. The key should also uniquely identify
 * each user. You can use any value such as a primary key, an email address, or a hash, as long
 * as the same user always has the same key. We recommend using a hash if possible.
 */
export const useFeatureFlagIdentity = () => {
  const ldClient = useLDClient();
  return (
    user: Parameters<LDClient['identify']>[0],
    hash?: Parameters<LDClient['identify']>[1],
    onDone?: Parameters<LDClient['identify']>[2],
  ) =>
    ldClient?.identify(
      {
        ...user,
        anonymous: false,
        custom: { ...user.custom, ...customAttributes },
      },
      hash,
      onDone,
    );
};

/**
 * Reset the user to anonymous mode when unauthenticated.
 */
export const useClearUserFromFeatureFlag = () => {
  const ldClient = useLDClient();
  return () =>
    ldClient?.identify({
      anonymous: true,
      key: anonymous,
      custom: customAttributes,
    });
};

/**
 * This hook will take the currently authenticated user id and use it when fetching flags
 * from Launch Darkly to get flags that are enabled/disabled for the specific user.
 * On cleanup it will reset the user in the LDProvider back to anonymous.
 */
// FIXME: keycloak user ID is not the same ID as the one provided by the API
// export const useFeatureFlagKeycloak = () => {
//   const { subject: userID } = useKeycloak().keycloak;
//   const [flags, setFlags] = useState<FeatureFlags>();
//   const ldClient = useLDClient();

//   useEffect(() => {
//     const identify = (key: string) =>
//       ldClient?.identify({
//         key,
//         anonymous: key === anonymous,
//         custom: customAttributes,
//       });

//     const fetchAndSetFlags = async () => {
//       try {
//         const response = await identify(userID!);
//         setFlags(response as FeatureFlags);
//       } catch (error) {
//         // eslint-disable-next-line no-console
//         console.error(
//           `Failed to fetch flags with user id ${userID}, defaulting to ${anonymous}: ${error}`,
//         );
//       }
//     };
//     fetchAndSetFlags();
//     return () => {
//       identify(anonymous);
//     };
//   }, [ldClient, userID]);
//   return flags;
// };

declare global {
  interface Window {
    LOCAL_SETTINGS: {
      ENV: 'prod' | 'dev';
      API_BASE: string;
      AUTH_REALM?: string;
      AUTH_CLIENT: string;
      AUTH_SERVICE: string;
      GOOGLE_PLACES_API_KEY: string;
    };
  }
}
declare let window: Window;

export const REACT_ADMIN_GRAPHQL_ENDPOINT = '/graphql/';
export const DEFAULT_GRAPHQL_URL: string = window.LOCAL_SETTINGS?.API_BASE.replace(
  '/api/v1/',
  REACT_ADMIN_GRAPHQL_ENDPOINT,
);

export const CONSOLE_BASE_URL: string = window.LOCAL_SETTINGS?.API_BASE.replace(
  '/api/v1/',
  '/',
).replace('/app.', '/console.');

export const GEOTRACKING_DATA_REFRESH_SECONDS = 30;
export const { GOOGLE_PLACES_API_KEY } = (window as any)?.LOCAL_SETTINGS || {};

// Authentication
export const {
  AUTH_REALM = 'StintAdmin',
  AUTH_CLIENT = '',
  AUTH_SERVICE = '',
} = window.LOCAL_SETTINGS || {};

// No offer custom push notification text limitations
export const NO_OFFER_ALGO_PUSH_NOTIFICATION_TITLE_MAX_LENGTH = 25;
export const NO_OFFER_ALGO_PUSH_NOTIFICATION_MESSAGE_MAX_LENGTH = 60;

// Google map config
export const DEFAULT_MAP_ZOOM_LEVEL = 10;

// Device Location
export const DEVICE_LOCATION_MAX_DISPLAYED_ACCURACY_METERS = 750;

// Default pagination size (number items per page)
export const DEFAULT_PER_PAGE_ITEMS_COUNT = 10;

import React, { FC } from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ListProps,
  NumberField,
  TextField,
} from 'react-admin';

export const PeriodicTaskList: FC<ListProps> = props => {
  return (
    <List {...props} perPage={25}>
      <Datagrid>
        <TextField source="name" />
        <BooleanField source="enabled" />
        <DateField emptyText="-" showTime source="lastRunAt" />
        <NumberField emptyText="-" source="priority" />
        <NumberField emptyText="-" source="totalRunCount" />
      </Datagrid>
    </List>
  );
};

import { StintUser } from 'apollo/schema';

type StintUserExtended = StintUser &
  Partial<{
    user?: StintUser;
  }>;

export const transformEmployerDataBeforeSave = (
  userData: StintUserExtended,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email: _mono_email, ...legacy_profile } = userData?.user || userData;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _auth_id, ...cleaned_up_user_data } = userData;
  return {
    ...legacy_profile,
    ...cleaned_up_user_data,
  };
};

import { StintJourneyStatus } from 'apollo/schema';

export const STATUS_TO_BACKGROUND_COLOR_MAPPING = {
  [StintJourneyStatus.NO_CONFIRMATION]: '#fec402',
  [StintJourneyStatus.TRACKING_OFF]: '#057dd9',
  [StintJourneyStatus.HAS_NOT_LEFT]: '#c166ff',
  [StintJourneyStatus.HAS_NOT_ARRIVED]: '#f22b2b',
  [StintJourneyStatus.GOING_TO_BE_LATE]: '#ffa361',
  [StintJourneyStatus.ARRIVED]: '#28cc97',
  [StintJourneyStatus.CHECKED_IN]: '#28cc97',
  [StintJourneyStatus.ON_TRACK]: '#17e990',
};

export const STATUS_TO_TEXT_COLOR_MAPPING = {
  [StintJourneyStatus.NO_CONFIRMATION]: '#ffffff',
  [StintJourneyStatus.TRACKING_OFF]: '#ffffff',
  [StintJourneyStatus.HAS_NOT_LEFT]: '#ffffff',
  [StintJourneyStatus.HAS_NOT_ARRIVED]: '#ffffff',
  [StintJourneyStatus.GOING_TO_BE_LATE]: '#ffffff',
  [StintJourneyStatus.ARRIVED]: '#0a6799',
  [StintJourneyStatus.CHECKED_IN]: '#ffffff',
  [StintJourneyStatus.ON_TRACK]: '#ffffff',
};

export const STATUS_TO_LABEL_MAPPING = {
  [StintJourneyStatus.NO_CONFIRMATION]: 'No confirmation',
  [StintJourneyStatus.TRACKING_OFF]: 'Tracking off',
  [StintJourneyStatus.HAS_NOT_LEFT]: "Hasn't left",
  [StintJourneyStatus.HAS_NOT_ARRIVED]: "Hasn't arrived",
  [StintJourneyStatus.GOING_TO_BE_LATE]: 'Going to be late',
  [StintJourneyStatus.ARRIVED]: 'Arrived',
  [StintJourneyStatus.CHECKED_IN]: 'Checked in',
  [StintJourneyStatus.ON_TRACK]: 'On track',
};

export const STATUS_CHOICES = [
  { id: StintJourneyStatus.NO_CONFIRMATION, name: 'No confirmation' },
  { id: StintJourneyStatus.TRACKING_OFF, name: 'Tracking off' },
  { id: StintJourneyStatus.HAS_NOT_LEFT, name: "Hasn't left" },
  { id: StintJourneyStatus.HAS_NOT_ARRIVED, name: "Hasn't arrived" },
  { id: StintJourneyStatus.GOING_TO_BE_LATE, name: 'Going to be late' },
  { id: StintJourneyStatus.ARRIVED, name: 'Arrived' },
  { id: StintJourneyStatus.CHECKED_IN, name: 'Checked in' },
  { id: StintJourneyStatus.ON_TRACK, name: 'On track' },
];

export const basePath = '/StintJourney';

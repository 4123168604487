import gql from 'graphql-tag';

import { updateQuery } from './users';
import { buildQuery } from './utils';

const EmployerUserFields = gql`
  fragment EmployerUserFields on UserRepresentationType {
    id
    firstName
    lastName
    email
    emailVerified
    enabled
    user {
      id
      profilePhotoUrl
      phoneNumber
      deactivated
      permanentDeactivated
      appVersion
      __typename
    }
  }
`;

const singleItemQuery = gql`
  query EmployerUser($id: ID!) {
    data: employerUser(userId: $id) {
      ...EmployerUserFields
    }
  }
  ${EmployerUserFields}
`;

const listQuery = gql`
  query allEmployerUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: String
  ) {
    items: allEmployerUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...EmployerUserFields
    }
    total: _allEmployerUserMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${EmployerUserFields}
`;

export const employers = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
});

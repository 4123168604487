import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StaffUserFields = gql`
  fragment StaffUserFields on UserRepresentationType {
    id
    username
    firstName
    lastName
    email
    enabled
  }
`;

const singleItemQuery = gql`
  query AdminUser($id: ID!) {
    data: adminUser(userId: $id) {
      ...StaffUserFields
    }
  }
  ${StaffUserFields}
`;

const listQuery = gql`
  query allAdminUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: String
  ) {
    items: allAdminUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...StaffUserFields
    }
    total: _allAdminUserMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StaffUserFields}
`;

export const staffs = buildQuery({
  listQuery,
  singleItemQuery,
});

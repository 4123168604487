import { useState } from 'react';

import { useNotify, useRefresh } from 'react-admin';

import {
  StintJourney,
  useManualGeotrackingStatusUpdateMutation,
} from 'apollo/schema';
import { getManualGeotrackingDisabled } from 'components/models/Geotracking/utils';

export const useManualGeoStatusUpdate = (record?: Partial<StintJourney>) => {
  const [
    manualGeotrackingStatusUpdate,
  ] = useManualGeotrackingStatusUpdateMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const isManualGeoStatusUpdateDisabled = getManualGeotrackingDisabled(record);

  const triggerManualGeoStatusUpdate = async () => {
    if (!record?.id) {
      return;
    }
    setLoading(true);
    try {
      await manualGeotrackingStatusUpdate({
        variables: {
          id: record?.id?.toString(),
        },
      });
    } catch (err) {
      if (err instanceof Error) {
        notify(err.message, 'error');
      }
      setLoading(false);
      return;
    }
    refresh();
    setLoading(false);
  };

  return {
    loading,
    triggerManualGeoStatusUpdate,
    isManualGeoStatusUpdateDisabled,
  };
};

import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Button, EditProps, Record, useShowController } from 'react-admin';

import { StintJourney } from 'apollo/schema';
import { Heading } from 'components/atoms';

import { GeoTrackingLiveMapDrawerContent } from '../GeoTrackingLiveMapDrawerContent';
import { useManualGeoStatusUpdate } from '../hooks';
import { useDrawerStyles } from '../utils';

interface Props extends EditProps {
  onCancel: VoidFunction;
}

export const GeotrackingLiveMapDrawer: React.FC<Props> = ({
  onCancel,
  ...props
}) => {
  const classes = useDrawerStyles();
  const { record, loading: stintJourneyLoading } = useShowController<
    StintJourney & Record
  >(props);
  const {
    triggerManualGeoStatusUpdate,
    isManualGeoStatusUpdateDisabled,
    loading: manualGeoStatusUpdateLoading,
  } = useManualGeoStatusUpdate(record);
  const loading = stintJourneyLoading || manualGeoStatusUpdateLoading;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Heading labelText="Live location" />
        <Button
          data-testid="manual-geo-status-update-button"
          disabled={isManualGeoStatusUpdateDisabled || loading}
          label="Refresh"
          onClick={triggerManualGeoStatusUpdate}
          variant="outlined"
        >
          <RefreshIcon />
        </Button>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.content}>
        <Grid container justify="center" spacing={2}>
          <GeoTrackingLiveMapDrawerContent
            loading={loading}
            manualGeoStatusUpdateLoading={manualGeoStatusUpdateLoading}
            record={record}
          />
        </Grid>
      </div>
    </div>
  );
};

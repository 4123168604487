import { OffenceReason, ValidOffenceReason } from 'apollo/schema';

type ValidOffenceReasonChoice = { id: ValidOffenceReason; name: string };
type ValidRedemptionReasonChoice = { id: ValidOffenceReason; name: string };

const reasonMap = new Map<OffenceReason, string>([
  [OffenceReason.UNIFORM, 'Uniform'],
  [OffenceReason.LATENESS, 'Lateness'],
  [OffenceReason.LEAVING_EARLY, 'Leaving early'],
  [OffenceReason.CANCELLATION_THRESHOLD_1, 'Cancellation <2 hours'],
  [OffenceReason.CANCELLATION_THRESHOLD_2, 'Cancellation 2-6 hours'],
  [OffenceReason.CANCELLATION_THRESHOLD_3, 'Cancellation 6-24 hours'],
  [OffenceReason.CANCELLATION_THRESHOLD_1_V2, 'Cancellation <6 hours'],
  [OffenceReason.CANCELLATION_THRESHOLD_2_V2, 'Cancellation 6-12 hours'],
  [OffenceReason.CANCELLATION_THRESHOLD_3_V2, 'Cancellation 12-24 hours'],
  [OffenceReason.NO_SHOW, 'No show'],
  [OffenceReason.NO_CONFIRMATION, 'No confirmation'],
  [OffenceReason.UNRESPONSIVE_STANDBY, 'Unresponsive standby'],
  [OffenceReason.GROSS_MISCONDUCT, 'Gross misconduct'],
  [OffenceReason.PERFORMANCE, 'Performance'],
  [OffenceReason.ONE_LEGACY_STRIKE, 'One legacy strike'],
  [OffenceReason.TWO_LEGACY_STRIKES, 'Two legacy strikes'],
  [OffenceReason.THREE_LEGACY_STRIKES, 'Three legacy strikes'],
  [OffenceReason.LEGACY_CANCELLATION, 'Legacy cancellation'],
  [OffenceReason.PERFORMANCE_SENT_HOME, 'Performance - sent home'],
  [OffenceReason.UNAUTHORISED_DEPARTURE, 'Unauthorised departure'],
  [OffenceReason.ONE_STRIKE_REDEEMED, 'One strike redeemed'],
  [OffenceReason.TWO_STRIKE_REDEEMED, 'Two strikes redeemed'],
  [OffenceReason.THREE_STRIKE_REDEEMED, 'Three strikes redeemed'],
]);

const validOffenceReasonMap = new Map<ValidOffenceReason, string>([
  [ValidOffenceReason.LATENESS, 'Lateness'],
  [ValidOffenceReason.LEAVING_EARLY, 'Leaving early'],
  [ValidOffenceReason.UNIFORM, 'Uniform'],
  [ValidOffenceReason.CANCELLATION_THRESHOLD_1_V2, 'Cancellation <6 hours'],
  [ValidOffenceReason.CANCELLATION_THRESHOLD_2_V2, 'Cancellation 6-12 hours'],
  [ValidOffenceReason.CANCELLATION_THRESHOLD_3_V2, 'Cancellation 12-24 hours'],
  [ValidOffenceReason.NO_SHOW, 'No show'],
  [ValidOffenceReason.NO_CONFIRMATION, 'No confirmation'],
  [ValidOffenceReason.UNRESPONSIVE_STANDBY, 'Unresponsive standby'],
  [ValidOffenceReason.GROSS_MISCONDUCT, 'Gross misconduct'],
  [ValidOffenceReason.PERFORMANCE, 'Performance'],
  [ValidOffenceReason.PERFORMANCE_SENT_HOME, 'Performance - sent home'],
  [ValidOffenceReason.UNAUTHORISED_DEPARTURE, 'Unauthorised departure'],
]);

const validRedemptionReasonMap = new Map<ValidOffenceReason, string>([
  [ValidOffenceReason.ONE_STRIKE_REDEEMED, 'One strike redeemed'],
  [ValidOffenceReason.TWO_STRIKE_REDEEMED, 'Two strikes redeemed'],
  [ValidOffenceReason.THREE_STRIKE_REDEEMED, 'Three strikes redeemed'],
]);

export const mapOffenceReason = (reason: OffenceReason): string => {
  const mapped = reasonMap.get(reason);
  if (!mapped) {
    return '';
  }

  return mapped;
};

export const validOffenceReasonChoices = (): ValidOffenceReasonChoice[] =>
  Array.from(validOffenceReasonMap).map(([key, value]) => ({
    id: key,
    name: value,
  }));

export const validRedemptionReasonChoices = (): ValidRedemptionReasonChoice[] =>
  Array.from(validRedemptionReasonMap).map(([key, value]) => ({
    id: key,
    name: value,
  }));

import React, { FC, useState } from 'react';

import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-admin';

export const USER_TYPE_STUDENT = 'Student';
export const USER_TYPE_EMPLOYER = 'Employer';

type Props = {
  userType: 'Student' | 'Employer';
  onClose: VoidFunction;
  onSubmit: FunctionStringCallback;
};

export const ViewUserDialog: FC<Props> = ({ onSubmit, onClose, userType }) => {
  const [value, setValue] = useState('');
  const dialogTitle = `${userType} ID`;
  return (
    <>
      <DialogTitle>
        <Typography variant="h6">{dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="standard-basic"
          onChange={e => setValue(e?.target.value)}
          value={value}
        />
      </DialogContent>
      <DialogActions>
        <Button color="default" label="Cancel" onClick={onClose} />
        <Button color="default" label="View" onClick={() => onSubmit(value)} />
      </DialogActions>
    </>
  );
};

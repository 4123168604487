import React, { FC } from 'react';

import {
  EditProps,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { Edit } from 'components';

import { TaskTopTipInput } from '../TaskTopTipInput';
import { nameValidator, TASK_TYPE_CHOICES } from '../utils';

export const TaskEdit: FC<EditProps> = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={nameValidator} />
      <SelectInput
        choices={TASK_TYPE_CHOICES}
        defaultValue="STINT_APPROVED"
        label="Type"
        source="taskType"
        validate={required()}
      />
      <TaskTopTipInput label="Name" source="topTips" />
    </SimpleForm>
  </Edit>
);

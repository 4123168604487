import React from 'react';

import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

import { RegionFilter } from '../RegionFilter';

export const RegionList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<RegionFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField label="Country" source="country.name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import {
  StinterOffencesTable,
  StinterStrikesAwarded,
} from 'components/models/Offence';
import { OffenceModal } from 'components/models/Offence/OffenceModal';
import { RedemptionModal } from 'components/models/Offence/RedemptionModal';
import { UserRecord } from 'components/models/Users';
import { FormInputProps } from 'components/types';
import { theme } from 'styles';

import {
  ListQueryStudentRecord,
  StudentRecord,
  isListQueryStudentRecord,
  isStudentRecord,
} from '../Student.types';

/**
 * When navigating to the Performance tab from the Student list, the Student object is injected
 * as a prop, however the injected object is the one from the List query, not the Single Item Query.
 * The Single Item Query will be executed on the Student Edit page and once the data is resolved,
 * The component will rerender with the Single Item Query version of the Student object.
 */
export const Performance = (
  props: FormInputProps<StudentRecord | UserRecord | ListQueryStudentRecord>,
) => {
  /**
   * The List Query Student object injected when navigating from the Students list
   * does not contain the required Stinter profile, to display the component so return null
   * and when the get Single Item Query completes the record will be updated on rerender.
   */
  if (!props?.record || isListQueryStudentRecord(props.record)) return null;

  /**
   * This component is used both on the StintUser page and Student page
   * this logic is to determine which data to use as the record.
   */
  const stinterProfile = isStudentRecord(
    props.record as StudentRecord | UserRecord,
  )
    ? props.record?.user?.studentProfile
    : props.record?.studentProfile;

  return (
    <div>
      <Typography component="h2">Status</Typography>
      <StinterStrikesAwarded strikesAwarded={stinterProfile?.strikesAwarded} />
      <Typography component="h2" style={{ marginTop: theme.spacing() }}>
        Offences
      </Typography>
      <StinterOffencesTable stinterProfile={stinterProfile} />
      <Grid container spacing={1}>
        <Grid item>
          <OffenceModal stinterProfileId={stinterProfile.id} />
        </Grid>
        <Grid item>
          <RedemptionModal stinterProfileId={stinterProfile.id} />
        </Grid>
      </Grid>
    </div>
  );
};

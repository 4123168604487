import React, { FC } from 'react';

import {
  Datagrid,
  List,
  ListProps,
  NumberField,
  ReferenceField,
  ShowButton,
  TextField,
} from 'react-admin';

import { DateTimeField } from 'components/atoms';

import { NotificationFilter } from '../NotificationFilter';

export const NotificationList: FC<ListProps> = props => {
  return (
    <List {...props} bulkActionButtons={false} filters={<NotificationFilter />}>
      <Datagrid rowClick="show">
        <DateTimeField label="Sent at" showTime source="createdAt" />
        <TextField source="title" />
        <TextField label="Body text" source="message" />
        <NumberField label="Recipients count" source="recipientsCount" />
        <ReferenceField
          label="Business name"
          reference="Business"
          source="business.id"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField emptyText="-" label="Stint Id" source="stint.id" />
        <TextField emptyText="-" label="Stint Type" source="stint.name" />
        <NumberField label="Open count" source="openedCount" />
        <TextField label="Category" source="notificationType" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

import React from 'react';

import { Datagrid, List, ListProps, TextField } from 'react-admin';

export const StudentReviewsList: React.FC<ListProps> = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
      </Datagrid>
    </List>
  );
};

import React from 'react';

import {
  AutocompleteInput,
  Filter,
  FilterProps,
  ReferenceInput,
  TextInput,
} from 'react-admin';

export const PostalCodeFilter: React.FC<
  Omit<FilterProps, 'children'>
> = props => (
  <Filter {...props}>
    <TextInput alwaysOn label="Postal code" source="postalCode_istartswith" />
    <ReferenceInput alwaysOn label="City" reference="City" source="city_id">
      <AutocompleteInput emptyText="-" optionText="name" />
    </ReferenceInput>
  </Filter>
);

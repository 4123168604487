import { startCase } from 'lodash';

/**
 * A helper function that converts a string to title case.
 * @param text a string of the following format: camel, snake, kebab, or pascal case.
 * @returns a title case string
 */
export const toTitleCase = (text: string) => {
  return startCase(text);
};

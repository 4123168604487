import React from 'react';

import { Chip as MuiChip } from '@material-ui/core';
import { useTheme } from 'styled-components';

import { ChipProps } from './Chip.types';

/**
 * Custom styled material ui Chip component to support success and error variants.
 * Example: <Chip label="Hello World" variant="success" />
 * https://mui.com/material-ui/react-chip/
 */
export const Chip = ({ variant = 'default', ...rest }: ChipProps) => {
  const theme = useTheme();

  const commonStyles: React.CSSProperties = {
    height: theme.spacing(3),
    borderRadius: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: theme.typography.caption.fontSize,
  };

  const variants = {
    success: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.dark,
      ...commonStyles,
    },
    error: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
      ...commonStyles,
    },
    default: {
      ...commonStyles,
    },
  };

  return <MuiChip {...rest} style={variants[variant]} />;
};

import { useEffect } from 'react';

import { useRefresh } from 'react-admin';

export const useResourcePooling = (seconds: number, active = true) => {
  const refresh = useRefresh();

  useEffect(() => {
    if (active) {
      const interval = setInterval(() => refresh(), seconds * 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [active, refresh, seconds]);
};

import React, { FC } from 'react';

import {
  AutocompleteArrayInput,
  Filter,
  FilterProps,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';

import { DateInput, TimeInput } from 'components';

import { STATUS_CHOICES, useMultiselectStyles } from '../utils';

export const GeotrackingFilter: FC<Omit<FilterProps, 'children'>> = props => {
  const classes = useMultiselectStyles();
  return (
    <Filter {...props}>
      <DateInput alwaysOn label="Date" source="startDate" />
      <TimeInput alwaysOn label="Time From" source="startTimeFrom" />
      <TimeInput alwaysOn label="Time To" source="startTimeTo" />
      <ReferenceArrayInput
        alwaysOn
        classes={classes}
        label="City"
        perPage={100}
        reference="City"
        source="city_id_in"
      >
        <AutocompleteArrayInput
          fullWidth
          optionText="name"
          suggestionLimit={5}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        alwaysOn
        classes={classes}
        filter={{ userType_istartswith: 'STUDENT ' }}
        label="Student"
        perPage={100}
        reference="StintUser"
        resource="StintJourney"
        source="student_in"
      >
        <AutocompleteArrayInput
          fullWidth
          optionText="fullname"
          suggestionLimit={5}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        alwaysOn
        classes={classes}
        label="Business"
        perPage={100}
        reference="Business"
        resource="StintJourney"
        source="business_in"
      >
        <AutocompleteArrayInput
          fullWidth
          optionText="name"
          suggestionLimit={5}
        />
      </ReferenceArrayInput>
      <SelectArrayInput
        alwaysOn
        choices={STATUS_CHOICES}
        classes={classes}
        label="Status"
        source="status_in"
      />
    </Filter>
  );
};

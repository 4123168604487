import React, { ComponentProps, FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { AvatarField } from '../AvatarField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

type Props = ComponentProps<typeof AvatarField>;

export const CompanyNameField: FC<Props> = ({ record, size }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField
        className={classes.avatar}
        record={record}
        size={size}
        source="logoUrl"
      />
      {record.name}
    </div>
  ) : null;
};

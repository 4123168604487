import React from 'react';

import {
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  ShowButton,
  TextField,
} from 'react-admin';

import { DateTimeField } from 'components/atoms';

import { DeviceLocationFilter } from '../DeviceLocationFilter';

export const DeviceLocationList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<DeviceLocationFilter />}>
      <Datagrid rowClick="edit">
        <DateTimeField showTime source="recordedAt" />
        <ReferenceField label="User" reference="StintUser" source="user_id">
          <TextField source="fullname" />
        </ReferenceField>
        <TextField source="heading" />
        <TextField source="speed" />
        <TextField source="latitude" />
        <TextField source="longitude" />
        <TextField emptyText="-" label="Accuracy [meters]" source="accuracy" />
        <TextField source="batteryLevel" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

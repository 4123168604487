import gql from 'graphql-tag';

import { buildQuery } from './utils';

const DeviceLocationFields = gql`
  fragment AdminDeviceLocationFields on DeviceLocation {
    id
    accuracy
    batteryLevel
    recordedAt
    longitude
    latitude
    heading
    speed
  }
`;

const listQuery = gql`
  query allDeviceLocations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: DeviceLocationFilter
  ) {
    items: allDeviceLocations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminDeviceLocationFields
    }
    total: _allDeviceLocationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${DeviceLocationFields}
`;

const singleItemQuery = gql`
  query DeviceLocation($id: ID!) {
    data: DeviceLocation(id: $id) {
      ...AdminDeviceLocationFields
    }
  }
  ${DeviceLocationFields}
`;

export const deviceLocations = buildQuery({
  listQuery,
  singleItemQuery,
});

import React, { ReactElement } from 'react';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles(theme => ({
  logoDropZoneWrapper: {
    position: 'absolute',
    right: -theme.spacing(2),
    top: -theme.spacing(2),
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  inputE2ETestId?: string;
  onLoad: (value: string) => void;
}

const DEFAULT_IMAGE = '';

export const LogoControlButtons: React.FC<Props> = ({
  inputE2ETestId,
  onLoad,
}): ReactElement => {
  const classes = useStyles();

  const onRemoveImage = (event: React.MouseEvent) => {
    event.stopPropagation();
    onLoad(DEFAULT_IMAGE);
  };

  return (
    <div className={classes.logoDropZoneWrapper}>
      <IconButton
        aria-label="upload picture"
        color="secondary"
        data-cy={inputE2ETestId}
        size="small"
      >
        <AddCircle fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="remove picture"
        color="secondary"
        data-cy={inputE2ETestId}
        onClick={onRemoveImage}
        size="small"
      >
        <RemoveCircle fontSize="small" />
      </IconButton>
    </div>
  );
};

import gql from 'graphql-tag';

import { buildQuery } from './utils';

const OffenceFields = gql`
  fragment OffenceBasicFields on Offence {
    id
    appealStatus
    business {
      id
      name
    }
    reason
    stint {
      id
      stintTimesDisplay
    }
    strikesAwarded
  }
`;

const createQuery = gql`
  mutation CreateOffence($createInputs: CreateOffenceInput!) {
    data: createOffence(createInputs: $createInputs) {
      id
      appealStatus
      business {
        id
        name
      }
      createdAt
      reason
      stint {
        id
        stintTimesDisplay
      }
      strikesAwarded
      createdBy {
        id
        fullname
      }
    }
  }
`;

const singleOffenceQuery = gql`
  query Offence($id: ID!) {
    data: offence(offenceId: $id) {
      ...OffenceBasicFields
      createdAt
      reason
      createdBy {
        id
        fullname
      }
      appeals {
        id
        status
        date
        notes
        decisionAt
        decisionBy {
          id
          fullname
        }
      }
    }
  }
  ${OffenceFields}
`;

const listStinterOffences = gql`
  query stinterOffences($id: ID!, $perPage: Int!) {
    data: stinterOffences(stinterProfileId: $id, first: $perPage) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...OffenceBasicFields
        }
      }
    }
  }
  ${OffenceFields}
`;

export const stinterOffences = buildQuery({
  createQuery,
  singleItemQuery: singleOffenceQuery,
  listQuery: listStinterOffences,
  listParseResponse: (response: any) => {
    const offences = (response.data.data.edges ?? []).map(
      (edge: any) => edge.node,
    );
    return { data: offences, total: offences.length };
  },
});

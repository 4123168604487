import React from 'react';

import { FieldProps } from 'react-admin';
import styled from 'styled-components';

import { StintJourneyStatus } from 'apollo/schema';

import {
  STATUS_TO_BACKGROUND_COLOR_MAPPING,
  STATUS_TO_LABEL_MAPPING,
  STATUS_TO_TEXT_COLOR_MAPPING,
} from '../utils';

export const GeotrackingStatusField: React.FC<FieldProps> = ({
  source,
  record,
}) => {
  const status: StintJourneyStatus = record?.[source || ''];
  return (
    <StatusChip status={status}>{STATUS_TO_LABEL_MAPPING[status]}</StatusChip>
  );
};

const StatusChip = styled.div<{ status: StintJourneyStatus }>`
  ${({ status }) => `
    background: ${STATUS_TO_BACKGROUND_COLOR_MAPPING[status] || 'gray'};
    color: ${STATUS_TO_TEXT_COLOR_MAPPING[status] || 'white'};
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
  `}
`;

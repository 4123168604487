import React from 'react';

import {
  Datagrid,
  EditButton,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from 'react-admin';

import { PostalCodeFilter } from '../PostalCodeFilter';

export const PostalCodeList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<PostalCodeFilter />}>
      <Datagrid>
        <TextField label="Postal code" source="postalCode" />
        <ReferenceField label="City" reference="City" source="city_id">
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

import React from 'react';

import {
  Resource,
  ResourceProps,
  useAuthenticated,
  useAuthState,
} from 'react-admin';

export const AuthResource = (props: ResourceProps) => {
  const { loading, authenticated } = useAuthState();
  useAuthenticated({ silentMode: true });

  if (!loading && authenticated) {
    return <Resource {...props} />;
  }
  return <></>;
};

AuthResource.defaultProps = Resource.defaultProps;

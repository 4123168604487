import React from 'react';

import { EditProps, Loading, useEditController } from 'react-admin';

import { EmployerEditForm } from '../EmployerEditForm';
import { StudentEditForm } from '../StudentEditForm';

export const UsersEdit: React.FC<EditProps> = props => {
  const { record: stintUser } = useEditController(props);

  if (!stintUser) {
    return <Loading />;
  }

  if (stintUser?.isStudent) {
    return <StudentEditForm {...props} />;
  }

  return <EmployerEditForm {...props} />;
};

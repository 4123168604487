import { useKeycloak } from '@react-keycloak/web';
import { UserIdentity } from 'react-admin';

import { AllowedUserTypes } from './userTypes';

declare let location: any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAuthProvider = (_clientID: string) => {
  const { keycloak, initialized } = useKeycloak();
  return {
    checkAuth: async (): Promise<void> => {
      if (!keycloak?.authenticated || !initialized) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkError: (_error: Error): Promise<void> => {
      return keycloak.authenticated && keycloak.token
        ? Promise.resolve()
        : Promise.reject(Error('Failed to obtain access token.'));
    },
    getPermissions: (): Promise<boolean> => {
      if (keycloak?.authenticated && keycloak.token) {
        const { groups }: { groups: string[] } = keycloak.idTokenParsed as any;
        if (
          groups.some(g =>
            Object.values(AllowedUserTypes).includes(
              g.toUpperCase() as AllowedUserTypes,
            ),
          )
        ) {
          return Promise.resolve(true);
        }
      }
      return Promise.resolve(false);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login: (_payload: any): Promise<void> => {
      return keycloak.login();
    },
    getIdentity: (): Promise<UserIdentity> => {
      try {
        if (!keycloak || !keycloak.authenticated) {
          return Promise.reject();
        }
        return Promise.resolve({
          id: `${keycloak.subject}`,
          fullName: (keycloak.idTokenParsed as any)?.['name'] || '',
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    logout: (): Promise<void> => {
      if (keycloak?.authenticated) {
        return keycloak.logout({
          redirectUri: location.href.replace(location.hash, '#/login'),
        });
      }
      return Promise.resolve();
    },
    keycloak,
  };
};

import React from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import { Loading, ShowProps, useShowController } from 'react-admin';

import { EmployerShowForm } from '../EmployerShowForm';
import { StudentShowForm } from '../StudentShowForm';

export const icon = BusinessIcon;

export const UsersShow: React.FC<ShowProps> = props => {
  const { record } = useShowController(props);

  if (!record) {
    return <Loading />;
  }

  if (record?.isStudent) {
    return <StudentShowForm {...props} />;
  }

  return <EmployerShowForm {...props} />;
};

import React, { MouseEventHandler } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { colors } from 'styles';

/**
 * Styles for the CustomModal
 */
const useStyles = makeStyles(() => ({
  title: {
    backgroundColor: colors.dashboardBg,
    padding: '0.5rem 1rem',
    display: 'flex',

    justifyContent: 'space-between',
    '& > h2': {
      color: '#000',
      fontWeight: '600',
      fontSize: '1rem',
      lineHeight: '3rem',
      paddingRight: '0.2rem',
    },
  },
}));

export interface CustomModalTitleProps {
  title: string;
  handleClose?: MouseEventHandler;
}

export const CustomModalTitle: React.FC<CustomModalTitleProps> = props => {
  const { title, handleClose } = props;

  const classes = useStyles();

  return (
    <>
      <Box className={classes.title}>
        <Typography variant="h2">{title}</Typography>
        {handleClose ? (
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
    </>
  );
};

import { EditProps, useNotify, useRedirect, useRefresh } from 'react-admin';
import { useHistory } from 'react-router-dom';

export const useOnSuccessSameShowTab = ({ id }: EditProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const history = useHistory();

  const onSuccess = () => {
    const link = history?.location?.pathname?.replace(String(id), `${id}/show`);
    notify('ra.notification.updated', 'info', { smart_count: 1 }, true);
    redirect(link);
    refresh();
  };

  return {
    onSuccess,
  };
};

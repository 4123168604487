import React, { ComponentProps } from 'react';

import get from 'lodash/get';
import moment from 'moment-timezone';
import { FunctionField, Record } from 'react-admin';

const timezone = 'Europe/London';

type Props = Omit<ComponentProps<typeof FunctionField>, 'source' | 'render'> & {
  endDateFormat: string;
  endDateSource: string;
  startDateFormat: string;
  startDateSource: string;
};

export const PeriodField: React.FC<Props> = ({
  endDateFormat,
  endDateSource,
  startDateFormat,
  startDateSource,
  ...rest
}) => {
  const handleRender = (record?: Record) => {
    const startDateFormatted = moment(get(record, startDateSource))
      .tz(timezone)
      .format(startDateFormat);
    const endDateFormatted = moment(get(record, endDateSource))
      .tz(timezone)
      .format(endDateFormat);
    return `${startDateFormatted} - ${endDateFormatted}`;
  };

  return <FunctionField label="Name" render={handleRender} {...rest} />;
};

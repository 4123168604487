import React, { FC } from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { sanitizeFieldRestProps } from 'react-admin';
import styled from 'styled-components';

import { colors } from '../../../styles';

interface Props extends TypographyProps {
  isSmall?: boolean;
  labelText: string;
}

export const Label: FC<Props> = styled(({ labelText, ...rest }: Props) => (
  <Typography {...sanitizeFieldRestProps(rest)}>{labelText}</Typography>
)).withConfig({
  shouldForwardProp: prop => !['isSmall'].includes(prop),
})<{
  isSmall?: boolean;
}>`
  color: ${colors.neutralDark60};
  font-size: ${props => (props.isSmall ? '12px' : '14px')};
  font-weight: 600;
  letter-spacing: ${props => (props.isSmall ? '0.4px' : '0.8px')};
  line-height: ${props => (props.isSmall ? '1.33' : '1.43')};
`;

import React from 'react';

import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

import { AddressFilter } from '../AddressFilter';

export const AddressList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<AddressFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="id" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

import { Record } from 'react-admin';

type Props = Record;

export const transformBusinessEditData = (data: Props) => {
  return {
    ...data,
    rateOverride: data?.rateOverride || {},
    stintopedia: {
      bookedOnboardingMeeting: data?.stintopedia?.bookedOnboardingMeeting,
      usingStintCorrectly: data?.stintopedia?.usingStintCorrectly,
    },
  } as Record;
};

import { useState } from 'react';

import { useDataProvider } from 'react-admin';

import { DeviceLocation } from 'apollo/schema';
import {
  convertValidDeviceLocationToLocationRecord,
  isValidDeviceLocation,
  isWithinAccuracyThreshold,
} from 'components/models/Geotracking/utils';
import {
  LocationRecord,
  ValidDeviceLocation,
} from 'components/models/Geotracking/utils/types';
import {
  NUMBER_OF_INITIAL_HISTORICAL_LOCATION_SAMPLES_TO_FETCH,
  NUMBER_OF_RECENT_HISTORICAL_LOCATION_SAMPLES_TO_FETCH,
} from 'config';

export const useLocations = () => {
  const dataProvider = useDataProvider();
  const [latestLocation, setLatestLocation] = useState<LocationRecord>();
  const [initialHistoricalLocations, setInitialHistoricalLocations] = useState<
    LocationRecord[]
  >();
  const [historicalLocations, setHistoricalLocations] = useState<
    LocationRecord[]
  >();
  const [
    latestInitialLocation,
    setLatestInitialLocation,
  ] = useState<LocationRecord>();
  const [locationsLoading, setLocationsLoading] = useState(true);

  const combineInitialHistoricalAndRecentLocations = (
    initialLocations: LocationRecord[],
    recentLocations: LocationRecord[],
  ) => {
    const initialLocationsById = initialLocations.reduce(
      (memo, location) => {
        return {
          ...memo,
          [location?.id]: location,
        };
      },
      {} as {
        [id: string]: LocationRecord;
      },
    );
    const newLocations =
      recentLocations.filter(
        location => !initialLocationsById?.[location?.id],
      ) || [];
    return newLocations.concat(initialLocations);
  };

  const getLocations = async (params: {
    createdAfter?: string;
    studentId: string;
    perPage: number;
  }): Promise<LocationRecord[]> => {
    if (!params?.studentId) {
      return [];
    }
    try {
      const data = await dataProvider.getList<DeviceLocation>(
        'DeviceLocation',
        {
          pagination: {
            page: 1,
            perPage: params?.perPage,
          },
          filter: {
            user_id: params?.studentId,
            createdAt_gte: params?.createdAfter,
          },
          sort: { field: 'id', order: 'DESC' },
        },
      );

      return (data?.data || [])
        .filter<ValidDeviceLocation>(isValidDeviceLocation)
        .filter(isWithinAccuracyThreshold)
        .map(convertValidDeviceLocationToLocationRecord);
    } catch (error) {
      return [];
    }
  };

  const fetchInitialLocations = async (params: {
    studentId: string;
    createdAfter: string;
  }) => {
    const locations = await getLocations({
      studentId: params?.studentId,
      createdAfter: params?.createdAfter,
      perPage: NUMBER_OF_INITIAL_HISTORICAL_LOCATION_SAMPLES_TO_FETCH,
    });
    const lastLocation = await getLocations({
      studentId: params?.studentId,
      perPage: 1,
    });
    setLatestInitialLocation(lastLocation?.[0]);
    setInitialHistoricalLocations(locations);
    setHistoricalLocations(locations);
    setLatestLocation(locations?.[0]);
    setLocationsLoading(false);
  };

  const fetchRecentLocations = async (params: {
    studentId: string;
    createdAfter: string;
  }) => {
    if (!initialHistoricalLocations?.length) {
      return;
    }
    const recentLocations = await getLocations({
      studentId: params?.studentId,
      createdAfter: params?.createdAfter,
      perPage: NUMBER_OF_RECENT_HISTORICAL_LOCATION_SAMPLES_TO_FETCH,
    });
    const studentInitialHistoricalLocations = initialHistoricalLocations.filter(
      location => location?.studentId === params?.studentId,
    );
    const newHistoricalLocations = combineInitialHistoricalAndRecentLocations(
      studentInitialHistoricalLocations,
      recentLocations,
    );

    setInitialHistoricalLocations(newHistoricalLocations);
    setHistoricalLocations(newHistoricalLocations);
    setLatestLocation(newHistoricalLocations?.[0]);
    setLocationsLoading(false);
  };

  return {
    fetchRecentLocations,
    fetchInitialLocations,
    latestLocation,
    historicalLocations,
    locationsLoading,
    latestInitialLocation,
    setLocationsLoading,
  };
};

import React from 'react';

import moment from 'moment';
import { DateInput as RADateInput } from 'react-admin';

export const DateInput: React.FC<any> = ({
  format = 'YYYY-MM-DD',
  ...rest
}) => {
  return (
    <RADateInput
      {...rest}
      parse={(value: string) => {
        return value ? moment(value).format(format) : value;
      }}
    />
  );
};

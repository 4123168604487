import moment from 'moment-timezone';

import { NUMBER_OF_MINUTES_BEFORE_STINT_START_TO_ALLOW_LOCATIONS_ON_MAP } from 'config';

export const getLocationCreatedAfterThreshold = (
  stintStartTime: string,
): string => {
  return moment(stintStartTime)
    .subtract(
      NUMBER_OF_MINUTES_BEFORE_STINT_START_TO_ALLOW_LOCATIONS_ON_MAP,
      'minutes',
    )
    .toISOString();
};

import React, { ComponentProps } from 'react';

import { SelectInput } from 'react-admin';

import { EmploymentExceptionCategory } from 'apollo/schema';
import { toTitleCase } from 'utils';

interface Option {
  id: EmploymentExceptionCategory;
  name: string;
}

const choices = Object.entries(EmploymentExceptionCategory).reduce(
  (prev: Option[], current: [string, EmploymentExceptionCategory]) => {
    return [
      ...prev,
      {
        name: toTitleCase(current[0]),
        id: current[1],
      },
    ];
  },
  [] as Option[],
);

export const EmploymentExceptionCategorySelectInput: React.FC<
  ComponentProps<typeof SelectInput>
> = props => <SelectInput source="category" choices={choices} {...props} />;

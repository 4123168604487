import { DEVICE_LOCATION_MAX_DISPLAYED_ACCURACY_METERS } from 'config';

import { ValidDeviceLocation } from './types';

/**
 * Checks if a ValidDeviceLocation is within the acceptable accuracy threshold
 * @param deviceLocation
 */
export const isWithinAccuracyThreshold = (
  deviceLocation: ValidDeviceLocation,
) => {
  return (
    deviceLocation.accuracy <= DEVICE_LOCATION_MAX_DISPLAYED_ACCURACY_METERS
  );
};

import React from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import { Loading, ShowProps, useShowController } from 'react-admin';

import { StaffShowForm } from './StaffShowForm';

export const icon = BusinessIcon;

export const StaffShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) {
    return <Loading />;
  }

  return <StaffShowForm {...props} />;
};

import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import {
  ChipField,
  FunctionField,
  NumberField,
  Record,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

import { StintUser } from 'apollo/schema';
import { BodyText, LabeledImageField } from 'components';
import {
  getUniversityLabel,
  STUDENT_EMPLOYEE_STATEMENT_CHOICES,
  STUDENT_LOAN_PLAN_CHOICES,
} from 'components/models/Student/utils';
import { getNullableBooleanDisplayValue } from 'utils';

import { StudentCompletedStintsList } from '../StudentCompletedStintsList';
import { StudentEditAsideActions } from '../StudentEditForm/StudentEditAsideActions';
import { StudentUpcomingStintsList } from '../StudentUpcomingStintsList';

export const StudentShowForm: React.FC<ShowProps> = props => {
  const { record: student } = useShowController<StintUser & Record>(props);

  return (
    <Show
      {...props}
      aside={<StudentEditAsideActions />}
      hasEdit={!!student?.user}
    >
      <TabbedShowLayout>
        <Tab data-testid="student-details-tab" label="Details">
          {(!student?.user && (
            <BodyText bold>
              The user has not yet logged in for the first time, so they cannot
              be edited
            </BodyText>
          )) || (
            <>
              <TextField label="ID (from mono)" source="user.id" />
            </>
          )}
          <TextField label="Authentication ID (from Keycloak)" source="id" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField
            label={getUniversityLabel(student?.emailVerified)}
            source="email"
          />
          <TextField label="Date of Birth" source="user.dateOfBirth" />
          {(student?.user &&
            (
              <>
                <TextField label="Uni Email - deprecated" source="user.email" />
                <TextField source="user.phoneNumber" />
                <FunctionField
                  label="Can see surge"
                  render={() =>
                    `${getNullableBooleanDisplayValue(
                      student?.user?.canSeeSurge ?? student?.canSeeSurge,
                    )}`
                  }
                />
                <TextField source="user.appVersion" />
                <ReferenceField
                  label="University"
                  reference="University"
                  source="user.studentProfile.university_id"
                >
                  <LabeledImageField
                    imageSource="logo"
                    label="Name"
                    labelSource="name"
                    sortBy="name"
                  />
                </ReferenceField>
                <NumberField
                  label="Average Rating"
                  source="user.publicRating"
                />
                <ReferenceArrayField
                  reference="EmploymentException"
                  source="user.exceptionsIds"
                >
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
                <SelectField
                  choices={STUDENT_LOAN_PLAN_CHOICES}
                  label="Student Loan Plan"
                  source="user.studentProfile.studentLoanPlan"
                />
                <SelectField
                  choices={STUDENT_EMPLOYEE_STATEMENT_CHOICES}
                  label="Student Employee Statement"
                  source="user.studentProfile.employeeStatement"
                />
                <Box mt={{ xs: '2em' }}>
                  <Divider />
                </Box>
                <Box my={{ xs: '2em' }}>
                  <Typography variant="subtitle1">
                    <strong>Bank Details</strong>
                  </Typography>
                </Box>
                <TextField
                  label="Account Name"
                  source="user.studentProfile.paymentMethods.0.accountName"
                />
                <TextField
                  label="Account Number"
                  source="user.studentProfile.paymentMethods.0.accountNumber"
                />
                <TextField
                  label="Sort Code"
                  source="user.studentProfile.paymentMethods.0.sortCode"
                />
                <Box mt={{ xs: '2em' }}>
                  <Divider />
                </Box>
                <Box my={{ xs: '2em' }}>
                  <Typography variant="subtitle1">
                    <strong>Emergency contact details</strong>
                  </Typography>
                </Box>
                <TextField
                  label="Name"
                  source="user.studentProfile.emergencyContactName"
                />
                <TextField
                  label="Contact number"
                  source="user.studentProfile.emergencyContactNumber"
                />
                <TextField
                  label="Relationship"
                  source="user.studentProfile.emergencyContactRelation"
                />
              </>
            ).props.children) || (
            <FunctionField
              label="Phone Number"
              render={() =>
                student?.attributes.find((attr: any) => attr.name === 'phone')
                  ?.value || 'N/A'
              }
            />
          )}
        </Tab>
        {student?.user && (
          <Tab data-testid="upcoming-stints-tab" label="Upcoming Stints">
            <StudentUpcomingStintsList />
          </Tab>
        )}
        {student?.user && (
          <Tab data-testid="completed-stints-tab" label="Completed Stints">
            <StudentCompletedStintsList />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

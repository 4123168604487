export const STINT_CREATE_INPUT_LABELS = {
  legend: 'Create Stints',
  businessId: 'Business ID',
  dateFrom: 'Date from',
  dateTo: 'Date to',
  stintTemplate: 'Stint Role',
  quantity: 'Quantity',
};

export const STINT_CREATE_MODAL_BUTTON = 'Create Stints';

export const STINT_CREATE_SUCCESS = 'Stints have been created successfully.';

export const STINT_CREATE_FAILED =
  'There has been an issue creating Stints. Please try again.';

import React, { FC } from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import { Filter, FilterProps, TextInput } from 'react-admin';

export const icon = BusinessIcon;

export const StudentsFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <TextInput alwaysOn label="Search by email or names" source="query" />
  </Filter>
);

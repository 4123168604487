import React from 'react';

import { EditProps, Loading, useEditController } from 'react-admin';

import { StintUser } from 'apollo/schema';

import { EmployerEditForm } from '../EmployerEditForm';

export const EmployersEdit: React.FC<EditProps> = props => {
  const { record: stintEmployer } = useEditController(props);

  if (!stintEmployer) {
    return <Loading />;
  }

  return <EmployerEditForm record={stintEmployer as StintUser} {...props} />;
};

import React, { FC, useCallback, useMemo } from 'react';

import { TablePagination, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ComponentPropType,
  sanitizeListRestProps,
  useListPaginationContext,
  useTranslate,
} from 'ra-core';
import {
  PaginationActions,
  PaginationLimit,
  PaginationProps,
} from 'react-admin';

const Pagination: FC<PaginationProps> = props => {
  const { rowsPerPageOptions, actions, limit, ...rest } = props;
  const {
    loading,
    page,
    perPage,
    total,
    setPage,
    setPerPage,
  } = useListPaginationContext(props);
  const translate = useTranslate();

  const totalPages = useMemo(() => {
    if (total === -1) {
      return page + 1;
    }
    return Math.ceil(total / perPage) || 1;
  }, [page, perPage, total]);

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, reqPage) => {
      if (event) {
        event.stopPropagation();
      }
      if (reqPage < 0 || reqPage > totalPages - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: reqPage + 1,
          }),
        );
      }
      setPage(reqPage + 1);
    },
    [totalPages, setPage, translate],
  );

  const handlePerPageChange = useCallback(
    event => {
      setPerPage(event.target.value);
    },
    [setPerPage],
  );

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading || limit === undefined ? <Toolbar variant="dense" /> : limit;
  }

  return (
    <TablePagination
      ActionsComponent={actions}
      component="span"
      count={total}
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      page={page - 1}
      rowsPerPage={perPage}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
};

Pagination.propTypes = {
  actions: ComponentPropType,
  limit: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  rowsPerPageOptions: PropTypes.array,
};

Pagination.defaultProps = {
  actions: PaginationActions,
  limit: <PaginationLimit />,
  rowsPerPageOptions: [5, 10, 25],
};

export const LazyPagination = React.memo<PaginationProps>(Pagination);

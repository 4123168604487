import gql from 'graphql-tag';

import { buildQuery } from './utils';

const CharacteristicFields = gql`
  fragment AdminCharacteristicFields on Characteristic {
    id
    name
  }
`;

const listQuery = gql`
  query allCharacteristics(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CharacteristicFilter
  ) {
    items: allCharacteristics(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminCharacteristicFields
    }
    total: _allCharacteristicsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${CharacteristicFields}
`;

const singleItemQuery = gql`
  query Characteristic($id: ID!) {
    data: Characteristic(id: $id) {
      ...AdminCharacteristicFields
    }
  }
  ${CharacteristicFields}
`;

export const characteristics = buildQuery({
  listQuery,
  singleItemQuery,
});

import React from 'react';

import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  ListProps,
  NumberField,
  TextField,
} from 'react-admin';

export const EditableSettingList: React.FC<ListProps> = props => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <NumberField source="notificationBlockerWindowHours" />
        <TextField
          label="Matching start time"
          source="advancedOperationalWindowStartTime"
        />
        <TextField
          label="Matching end time"
          source="advancedOperationalWindowEndTime"
        />
        <BooleanField
          label="Matching test mode"
          source="automatedTestingEnabled"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

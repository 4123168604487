import React, { FC } from 'react';

import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-admin';

type Props = {
  otp?: string;
  onClose: VoidFunction;
};

export const GetOTPDialog: FC<Props> = ({ otp, onClose }) => {
  return (
    <>
      <DialogTitle>
        <Typography variant="h6">OTP</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField id="standard-basic" value={otp} />
      </DialogContent>
      <DialogActions>
        <Button color="default" label="Close" onClick={onClose} />
      </DialogActions>
    </>
  );
};

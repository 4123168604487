import { Identifier } from 'react-admin';

import { DeviceLocation, DifferentStartTimeReason } from 'apollo/schema';
import { Location } from 'types';
import { MakeRequiredProperties } from 'types/utilityTypes';

export type ValidDeviceLocation = MakeRequiredProperties<
  DeviceLocation,
  'id' | 'latitude' | 'longitude' | 'accuracy' | 'recordedAt' | 'user_id'
>;

export type LocationRecord = Location & {
  id: Identifier;
  studentId: string;
  accuracy: number;
  recordedAt: string;
};

export enum TabsType {
  ALL = 'STATUS_GROUP_ALL',
  ACTION_REQUIRED = 'STATUS_GROUP_ACTION_REQUIRED',
}

export enum Drawers {
  TRACKING_DETAILS = 'tracking',
  STINT_DETAILS = 'stint',
  LIVE_MAP = 'map',
}

export const LATE_REASON_CHOICES = [
  {
    id: DifferentStartTimeReason.DIFFERENT_START_TIME_LATE_ASSIGNMENT,
    name: 'Late Assignment',
  },
  {
    id: DifferentStartTimeReason.DIFFERENT_START_TIME_LAST_MINUTE,
    name: 'Last-Minute Post',
  },
  {
    id: DifferentStartTimeReason.DIFFERENT_START_TIME_LATE_TRANSPORT,
    name: 'Transport',
  },
  {
    id: DifferentStartTimeReason.DIFFERENT_START_TIME_LEFT_TO_LATE,
    name: 'Left Too Late',
  },
  {
    id: DifferentStartTimeReason.DIFFERENT_START_TIME_WRONG_BUSINESS_ADDRESS,
    name: 'Business Address Wrong',
  },
  {
    id: DifferentStartTimeReason.DIFFERENT_START_TIME_OTHER,
    name: 'Other / Unknown',
  },
];

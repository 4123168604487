import React from 'react';

import {
  BooleanField,
  NumberField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import styled from 'styled-components';

import { Heading } from 'components';

export const EditableSettingShow: React.FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="predictionLeadDays" />
      <NumberField source="notificationBlockerWindowHours" />
      <NumberField source="geoTrackingBufferTime" />
      <TextField
        label="Matching start time"
        source="advancedOperationalWindowStartTime"
      />
      <TextField
        label="Matching end time"
        source="advancedOperationalWindowEndTime"
      />
      <BooleanField
        label="Matching test mode"
        source="automatedTestingEnabled"
      />
      <BooleanField
        label="ETD notifications enabled"
        source="featureFlagEtdNotificationsEnabled"
      />
      <BooleanField
        label="Leave reminder at route time enabled"
        source="sendingStudentLeaveReminderAtRouteTimeEnabled"
      />
      <BooleanField
        data-testid="student-cancellation-on-hold-email-enabled"
        label="Student Cancellation On Hold Email Enabled"
        source="studentCancellationOnHoldEmailEnabled"
      />
      <NumberField
        label="ETD calculation (before midday)"
        source="etdCalculationBeforeMiddayOffsetInMinutes"
      />
      <NumberField
        label="ETD calculation (after midday)"
        source="etdCalculationAfterMiddayOffsetInMinutes"
      />

      <GroupHeadingContainer>
        <Heading labelText="Stintmatcher service:" size={18} />
      </GroupHeadingContainer>

      <NumberField
        label="No offer count limit"
        source="matchingAlgoNoOfferMaxCountPerTimeWindow"
      />
      <NumberField
        label="No offer time span [minutes]"
        source="matchingAlgoNoOfferTimeWindowSpan"
      />
      <TextField source="matchingAlgoNoOfferTitle" />
      <TextField source="matchingAlgoNoOfferMessage" />
      <NumberField
        label="No offer/Offer Notification Delay"
        source="matchingAlgoNotificationDelayInMinutes"
      />
      <BooleanField
        label="Availability matching notification for no offer matches enabled"
        source="availabilityMatchingNotificationForNoOffersEnabled"
      />
      <NumberField source="availabilityMatchingBufferHours" />
      <NumberField
        label="Availability Matching Max Offers - Mondays"
        source="availabilityMatchingMaxOffersMonday"
        title="The max number of offers that are allowed to be sent to a student on Mondays"
      />
      <NumberField
        label="Availability Matching Max Offers - Tuesdays"
        source="availabilityMatchingMaxOffersTuesday"
        title="The max number of offers that are allowed to be sent to a student on Tuesdays"
      />
      <NumberField
        label="Availability Matching Max Offers - Wednesdays"
        source="availabilityMatchingMaxOffersWednesday"
        title="The max number of offers that are allowed to be sent to a student on Wednesdays"
      />
      <NumberField
        label="Availability Matching Max Offers - Thursdays"
        source="availabilityMatchingMaxOffersThursday"
        title="The max number of offers that are allowed to be sent to a student on Thursdays"
      />
      <NumberField
        label="Availability Matching Max Offers - Fridays"
        source="availabilityMatchingMaxOffersFriday"
        title="The max number of offers that are allowed to be sent to a student on Fridays"
      />
      <NumberField
        label="Availability Matching Max Offers - Saturdays"
        source="availabilityMatchingMaxOffersSaturday"
        title="The max number of offers that are allowed to be sent to a student on Saturdays"
      />
      <NumberField
        label="Availability Matching Max Offers - Sundays"
        source="availabilityMatchingMaxOffersSunday"
        title="The max number of offers that are allowed to be sent to a student on Sundays"
      />
      <NumberField
        label="Offer expiry threshold - Timing by which Stint Offer expires in hours"
        source="stintOfferExpiryThresholdInHours"
      />
      <NumberField
        label="Offer notification threshold - The number of hours between each top up or manual offer"
        source="notificationTopUpThresholdInHours"
      />
    </SimpleShowLayout>
  </Show>
);

const GroupHeadingContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

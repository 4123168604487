import React from 'react';

import {
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

export const PostalCodeShow: React.FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="postalCode" />
      <ReferenceField label="City" reference="City" source="cityId">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

import React, { Dispatch, SetStateAction } from 'react';

import {
  Button,
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from 'react-admin';

import { validOffenceReasonChoices } from 'components/models/Offence/OffenceReasonField/mapping';
import { Modal } from 'components/molecules';
import { validateId } from 'utils';

import {
  CREATE_OFFENCE_FAILED_MSG,
  CREATE_OFFENCE_SUCCESS_MSG,
  MODAL_OPEN_BUTTON_TEXT,
  MODAL_TITLE,
  REASON_INPUT_LABEL,
  STINT_REF_INPUT_LABEL,
  STINT_REF_INPUT_VALIDATION,
  STINTER_PROFILE_REF_INPUT_LABEL,
} from './OffenceModal.constants';
import { OffenceModalProps } from './OffenceModal.types';
const validOffenceReasonChoiceEntries = validOffenceReasonChoices();

export const OffenceModal = ({ stinterProfileId }: OffenceModalProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = (setOpen: Dispatch<SetStateAction<boolean>>) => {
    setOpen(false);
    notify(CREATE_OFFENCE_SUCCESS_MSG, 'info');
    refresh();
  };

  const onFailure = (setOpen: Dispatch<SetStateAction<boolean>>) => {
    setOpen(false);
    notify(CREATE_OFFENCE_FAILED_MSG, 'error');
  };

  return (
    <Modal
      title={MODAL_TITLE}
      openComponent={
        <Button
          label={MODAL_OPEN_BUTTON_TEXT}
          color="primary"
          variant="contained"
          style={{
            padding: '0.5rem 2rem',
            marginTop: '1rem',
          }}
        />
      }
    >
      {({ setOpen }) => (
        <Create
          basePath="/Offence"
          resource="Offence"
          onSuccess={() => {
            onSuccess(setOpen);
          }}
          onFailure={() => {
            onFailure(setOpen);
          }}
        >
          <SimpleForm redirect={false} variant="filled" sanitizeEmptyValues>
            <TextInput
              label={STINTER_PROFILE_REF_INPUT_LABEL}
              source="createInputs.stinterProfileRef"
              defaultValue={stinterProfileId}
              disabled
              style={{
                display: 'none',
              }}
              data-testid="offence-stint-profile-id"
            />

            <TextInput
              label={STINT_REF_INPUT_LABEL}
              source="createInputs.stintRef"
              validate={[required(), validateId(STINT_REF_INPUT_VALIDATION)]}
              data-testid="offence-stint-ref"
            />

            <SelectInput
              choices={validOffenceReasonChoiceEntries}
              label={REASON_INPUT_LABEL}
              source="createInputs.reason"
              validate={required()}
              data-testid="offence-reason-id"
            />
          </SimpleForm>
        </Create>
      )}
    </Modal>
  );
};

import React from 'react';

import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import {
  BulkActionProps,
  Button,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';

export const GeotrackingResolveStatusButton: React.FC<BulkActionProps> = ({
  selectedIds = [],
  resource = 'StintJourney',
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [resolveStintJourneyStatues, { loading }] = useUpdateMany(
    resource,
    selectedIds,
    null,
    {
      onSuccess: () => {
        notify('All student journey statues resolved.');
        unselectAll(resource);
        refresh();
      },
      onFailure: err =>
        notify(
          `Error: Student journey statuses not resolved. Reason: ${err}`,
          'error',
          null,
          false,
          30000,
        ),
    },
  );

  return (
    <Button
      disabled={loading}
      label="Resolve"
      onClick={resolveStintJourneyStatues}
    >
      <TrendingDownIcon />
    </Button>
  );
};

import React, { FC } from 'react';

import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

import { LabeledImageField } from 'components';

import { UniversityFilter } from '../UniversityFilter';

export const UniversityList: FC<ListProps> = props => {
  return (
    <List {...props} filters={<UniversityFilter />}>
      <Datagrid rowClick="edit">
        <LabeledImageField
          imageSource="logo"
          label="Name"
          labelSource="name"
          sortBy="name"
        />
        <TextField source="domain" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

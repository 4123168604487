import React from 'react';

import {
  Datagrid,
  Pagination,
  ReferenceManyField,
  TextField,
} from 'react-admin';

import { DateTimeField } from 'components/atoms';
import { RTWStatusField } from 'components/models/RightToWork/StatusField';
import { UserRecord } from 'components/models/Users';
import { FormInputProps } from 'components/types';

import { StudentRecord } from '../Student.types';

export const RightToWork = (
  props: FormInputProps<StudentRecord | UserRecord>,
) => {
  return (
    <ReferenceManyField
      basePath="/RTWStinterScreening"
      fullWidth
      label=""
      pagination={<Pagination />}
      perPage={10}
      record={props?.record?.user || props?.record}
      reference="RTWStinterScreening"
      target="id"
    >
      <Datagrid rowClick="toggleSelection">
        <TextField label="Screening ID" sortable={false} source="id" />
        <TextField sortable={false} source="type" />
        <DateTimeField
          label="Last change at"
          showTime
          sortable={false}
          source="updatedAt"
        />
        <RTWStatusField label="Status" source="status" />
      </Datagrid>
    </ReferenceManyField>
  );
};

import React, { ComponentProps, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { useKeycloak } from '@react-keycloak/web';
import {
  Loading,
  Login,
  useAuthState,
  userLogin as userLoginAction,
} from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { ServerLink } from 'components';
import { IS_PRODUCTION } from 'config';

const styles = ({ spacing }: { spacing: any }) =>
  createStyles({
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

const mapDispatchToProps = {
  userLogin: userLoginAction,
};

export const LoginPage: React.FC<ComponentProps<typeof Login>> = connect(
  undefined,
  mapDispatchToProps,
)(
  withStyles(styles)(
    ({ classes, userLogin, ...props }: { classes: any; userLogin: any }) => {
      const { loading } = useAuthState();
      const { keycloak } = useKeycloak();
      const [loginInProgress, setLoginInProgress] = useState(false);

      const handleLogin = () => {
        setLoginInProgress(true);
        userLogin();
      };

      if (loading || keycloak.authenticated) {
        return (
          <>
            {keycloak.authenticated && <Redirect to="/" />}
            <br />
            <Loading
              loadingPrimary="Welcome"
              loadingSecondary="Your session is initialising..."
            />
          </>
        );
      }

      return (
        <>
          {!IS_PRODUCTION && (
            <Box m={2}>
              <ServerLink />
            </Box>
          )}
          <Login {...props}>
            <div>
              <CardActions>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={loginInProgress}
                  onClick={handleLogin}
                  type="submit"
                  variant="contained"
                >
                  {loginInProgress && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  Login with your stint.co account
                </Button>
              </CardActions>
            </div>
          </Login>
        </>
      );
    },
  ),
);

import styled from 'styled-components';

export const CircleImageWrapper = styled.div<{
  isLoading: boolean;
  size: number;
}>`
  ${({ isLoading, size }) =>
    `
    position: relative;
    overflow: hidden;
    opacity: ${isLoading ? 0.5 : 1};
    border-radius: 50%;
    height: ${size}px;
    width: ${size}px;
  `}
`;

export const CircularProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

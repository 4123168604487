import React, { FC } from 'react';

import { DateTimeInput, Filter, FilterProps, TextInput } from 'react-admin';

export const NotificationFilter: FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <TextInput
      alwaysOn
      data-testid="notification-recipient-id-filter"
      label="Recipient ID"
      source="recipient_id"
    />
    <TextInput
      alwaysOn
      data-testid="notification-title-filter"
      label="Title"
      source="title_istartswith"
    />
    <TextInput
      alwaysOn
      data-testid="notification-message-filter"
      label="Message"
      source="message_istartswith"
    />
    <DateTimeInput
      alwaysOn
      data-testid="notification-sent-from-filter"
      label="Sent on from"
      source="createdAt_gte"
    />
    <DateTimeInput
      alwaysOn
      data-testid="notification-sent-to-filter"
      label="Sent on to"
      source="createdAt_lte"
    />
  </Filter>
);

import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
		box-shadow: 0 0 0 0 rgba(116, 114, 218, 0.9);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(116, 114, 218, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(116, 114, 218, 0);
	}
`;

export const PulseCircle = styled.div`
  background: #7472da;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -10px;
  right: -10px;
  box-shadow: 0 0 0 0 #7472da;
  animation: ${pulse} 2s infinite;
`;

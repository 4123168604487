import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StintFields = gql`
  fragment AdminStintFields on Stint {
    # Main fields used by Show & Edit components as well as any references using reference="Stint".
    id
    name
    status
    createdAt
    dateFrom
    dateTo
    checkInDate
    actualLeaveTime
    trained
    controlCheck
    isBestEffort
    notes
    primaryTasks {
      id
      name
    }
    exceptions {
      id
      name
    }
    uniform
    business_id
    displayAddress
    student_id
    stintRates {
      baseFeeOverride
      totalFeeWithVat
      cancellationFee
      cancellationFeeApplied
      baseRateOverride
      basePay
      surgeRate
      surgePay
      surgeApplied
      bonusRate
      bonusPay
      bonusApplied
    }
    # CancellationHistory fields.
    cancelledTimeAhead
    cancelledAt
    cancelled_by_id
  }
`;

const StintListFields = gql`
  fragment AdminStintListFields on Stint {
    id
    business_id
    createdAt
    dateFrom
    dateTo
    student_id
    status
    isBestEffort
    controlCheck
    city_id
  }
`;

const listQuery = gql`
  query allStints(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintFilter
  ) {
    items: allStints(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintListFields
    }
    total: _allStintsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StintListFields}
`;

const singleItemQuery = gql`
  query Stint($id: ID!) {
    data: Stint(id: $id) {
      ...AdminStintFields
    }
  }
  ${StintFields}
`;

const updateQuery = gql`
  mutation updateStint(
    $cancellationFee: Float
    $cancellationFeeApplied: Boolean
    $controlCheck: Boolean
    $dateFrom: AWSDateTime
    $dateTo: AWSDateTime
    $checkInDate: AWSDateTime
    $displayAddress: String
    $exceptions: [StintTemplateExceptionInput]
    $isBestEffort: Boolean
    $id: ID!
    $name: String
    $status: Int
    $notes: String
    $placeId: String
    $primaryTasks: [StintTemplateTaskInput]
    $student_id: ID
    $studentUnassignedReasons: [StudentUnassignedReasons]
    $topTips: [StintTemplateTopTipInput]
    $uniform: [StintUniform]
    $baseFeeOverride: Float
    $baseRateOverride: Float
    $surgeRate: Float
  ) {
    data: updateStint(
      cancellationFee: $cancellationFee
      cancellationFeeOverride: $cancellationFeeApplied
      controlCheck: $controlCheck
      dateFrom: $dateFrom
      dateTo: $dateTo
      checkInDate: $checkInDate
      displayAddress: $displayAddress
      exceptions: $exceptions
      id: $id
      isBestEffort: $isBestEffort
      name: $name
      status: $status
      notes: $notes
      placeId: $placeId
      primaryTasks: $primaryTasks
      student_id: $student_id
      studentUnassignedReasons: $studentUnassignedReasons
      topTips: $topTips
      uniform: $uniform
      overrideBaseFee: $baseFeeOverride
      studentBaseFeeOverrideGrossAmount: $baseRateOverride
      studentSurgeBaseFeeGrossAmount: $surgeRate
    ) {
      ...AdminStintFields
    }
  }
  ${StintFields}
`;

const updateManyQuery = gql`
  mutation cancelStints($stintRefs: [ID]!) {
    data: cancelStints(stintRefs: $stintRefs) {
      entries {
        id
      }
    }
  }
`;

/**
 * This query is not used by react admin since it does not support create many.
 * This is used by apollo client.
 */
export const createManyQuery = gql`
  mutation CreateStints($createInputs: CreateStintsInput!) {
    createStints(createInputs: $createInputs) {
      id
    }
  }
`;

export const stints = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
  updateManyQuery,
});

import React from 'react';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Error, useQuery } from 'react-admin';

interface NotificationConfirmationDialogProps {
  values: any;
  onClose: () => void;
  onSave: () => void;
}

export const NotificationConfirmationDialog: React.FC<NotificationConfirmationDialogProps> = ({
  values,
  onClose,
  onSave,
}) => {
  const { total, loading, error } = useQuery({
    type: 'getMany',
    resource: 'NotificationStintUser',
    payload: {
      ids: values?.student_ids,
      userType_istartswith: values.stint_id ? 'STUDENT' : null,
    },
  });

  if (loading) {
    return (
      <DialogContent>
        <Typography display="block" gutterBottom variant="body1">
          Loading...
        </Typography>
      </DialogContent>
    );
  }
  if (error) return <Error error="An error has ocurred" />;

  if (!values?.sendPushNotification) {
    return (
      <>
        <DialogContent>
          <Typography gutterBottom variant="h6">
            No push notifications
          </Typography>
          <Typography display="block" gutterBottom variant="body1">
            Only offers will be sent to students. No push notification is sent.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Close
          </Button>
          <Button autoFocus color="primary" onClick={onSave}>
            Confirm
          </Button>
        </DialogActions>
      </>
    );
  }

  if (!total) {
    return (
      <>
        <DialogContent>
          <Typography gutterBottom variant="h6">
            No Students Found
          </Typography>
          <Typography display="block" gutterBottom variant="body1">
            We could not import any students from the list of ids that you
            provided.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            OK
          </Button>
        </DialogActions>
      </>
    );
  }
  return (
    <>
      <DialogContent>
        <Typography gutterBottom variant="subtitle1">
          Notification Type
        </Typography>
        <Typography gutterBottom variant="body2">
          {values.stint_id ? 'Manual stint offer' : 'General notification'}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Recipients
        </Typography>
        <Typography gutterBottom variant="body2">
          {total}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Title
        </Typography>
        <Typography gutterBottom variant="body2">
          {values.title}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Message
        </Typography>
        <Typography gutterBottom variant="body2">
          {values.message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={onClose}>
          Close
        </Button>
        <Button autoFocus color="primary" onClick={onSave}>
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

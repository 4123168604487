import { EmployeeStatement, StudentLoanPlan } from 'apollo/schema';
import { STINT_STATUS } from 'utils';

export const UNIVERSITY_EMAIL_VERIFIED_LABEL = 'Email (verified)';
export const UNIVERSITY_EMAIL_NOT_VERIFIED_LABEL = 'Email (not verified)';

export const STUDENT_LOAN_PLAN_CHOICES = [
  { id: StudentLoanPlan.NO_PLAN, name: 'No Plan' },
  { id: StudentLoanPlan.PLAN_ONE, name: 'Plan One' },
  { id: StudentLoanPlan.PLAN_TWO, name: 'Plan Two' },
];

export const STUDENT_EMPLOYEE_STATEMENT_CHOICES = [
  { id: EmployeeStatement.A, name: 'Statement A' },
  { id: EmployeeStatement.B, name: 'Statement B' },
  { id: EmployeeStatement.C, name: 'Statement C' },
];

export const ALLOWED_UPCOMING_STINT_STATUSES = [
  STINT_STATUS.ACCEPTED,
  STINT_STATUS.CHECKED_IN,
  STINT_STATUS.COMPLETED_IN_REVIEW,
];

import { useState } from 'react';

import {
  GooglePlacesPredictionsType,
  GooglePlacesReturnType,
  useSearchGooglePlacesMutation,
} from 'apollo/schema';

import { debounce } from '../../utils';

type Props = {
  debounce?: number;
  returnType?: GooglePlacesReturnType;
};

export const useGoogleAutoComplete = ({
  returnType,
  debounce: wait = 500,
}: Props) => {
  const [locations, setLocations] = useState<
    GooglePlacesPredictionsType['predictions'] | undefined
  >(undefined);

  const [searchGooglePlacesMutation] = useSearchGooglePlacesMutation();

  const clearSearchResults = () => {
    setLocations(undefined);
  };

  const onSearch = (searchTerm: string) => {
    debounce(async () => {
      try {
        if (searchTerm.length < 3) {
          clearSearchResults();
          return;
        }

        const { data } = await searchGooglePlacesMutation({
          variables: { searchTerm, returnType },
        });

        const predictions = data?.searchGooglePlaces?.predictions;

        if (predictions?.length) {
          setLocations(predictions);
        } else {
          setLocations([]);
        }
      } catch {
        setLocations([]);
      }
    }, wait);
  };

  return {
    locations,
    onSearch,
    clearSearchResults,
  };
};

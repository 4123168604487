import React from 'react';

import {
  BooleanField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  Record,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';

import { ViewButton } from 'components/atoms';

import { BusinessFilter } from '../BusinessFilter';
import { DownloadQRCode } from '../DownloadQRCode';
import { BUSINESS_STATUS_CHOICES } from '../utils';

export const BusinessList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={<BusinessFilter />}>
      <Datagrid>
        <TextField source="name" />
        <SelectField choices={BUSINESS_STATUS_CHOICES} source="status" />
        <BooleanField source="exemptFromPaymentDetails" />
        <ReferenceField
          label="Sales Representative"
          reference="StintUser"
          source="salesRepresentitive.id"
        >
          <FunctionField
            render={(record?: Record) =>
              `${record?.firstName} ${record?.lastName}`
            }
            source="id"
          />
        </ReferenceField>
        <TextField label="Rating" source="averageRating" />
        <DownloadQRCode source="qrCodeId" />
        <EditButton label="Edit" />
        <ViewButton
          label="Stintopedia"
          recordTo={(record: Record) =>
            record?.stintopedia?.id
              ? `/Stintopedia/${record.stintopedia.id}/show`
              : null
          }
        />
        <ViewButton
          label="Partner Site"
          recordTo={(record: Record) =>
            record?.id ? `/PartnerSiteProfile/${record.id}/show` : null
          }
        />
      </Datagrid>
    </List>
  );
};

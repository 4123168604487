import React, { ComponentProps } from 'react';

import { useRecordContext, Record, ShowButton } from 'react-admin';

interface ViewButtonProps extends ComponentProps<typeof ShowButton> {
  label: string;
  recordTo: (record: Record) => string | null;
}

/*
ViewButton provides a simple way to add a button that navigates using the react router.

Usage:
  <ViewButton
    label="Stintopedia"
    recordTo={(record: Record) => record?.stintopedia?.id ? `/Stintopedia/${record.stintopedia.id}/show` : null}
  />
*/
export const ViewButton = ({ label, recordTo, ...props }: ViewButtonProps) => {
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  const linkTo = recordTo(record);
  if (!linkTo) {
    return null;
  }

  return <ShowButton label={label} to={linkTo} {...props} />;
};

import React from 'react';

import { EditProps, Loading, useEditController } from 'react-admin';

import { StudentRecord } from '../Student.types';
import { StudentEditForm } from '../StudentEditForm';

export const StudentsEdit: React.FC<EditProps> = props => {
  const { record: stintUser } = useEditController(props);

  if (!stintUser) {
    return <Loading />;
  }
  return <StudentEditForm record={stintUser as StudentRecord} {...props} />;
};

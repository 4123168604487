import React from 'react';

import { EmailField, FunctionField, ListProps, TextField } from 'react-admin';

import { UserDatagrid, UserList } from 'components';
import { AvatarField } from 'components/atoms';
import { ViewSpecificUserID } from 'components/models/Users/ViewSpecificUserID';
import { USER_TYPE_EMPLOYER } from 'components/models/Users/ViewUserDialog';

import { EmployersFilter } from '../EmployersFilter';

export const EmployersList: React.FC<ListProps> = props => {
  return (
    <UserList
      {...props}
      actions={<ViewSpecificUserID userType={USER_TYPE_EMPLOYER} />}
      filters={<EmployersFilter />}
    >
      <UserDatagrid>
        <AvatarField label="" sortable={false} source="user.profilePhotoUrl" />
        <TextField sortable={false} source="firstName" />
        <TextField sortable={false} source="lastName" />
        <EmailField sortable={false} source="email" />
        <TextField
          emptyText="-"
          label="App Version"
          sortable={false}
          source="user.appVersion"
        />
        <FunctionField
          label="Hollow account"
          render={(record: any) => (record?.user ? 'No' : 'Yes')}
          tooltip="Whether or not the user is still yet to validate their identity"
        />
      </UserDatagrid>
    </UserList>
  );
};

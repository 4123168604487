import './index.css';

import React from 'react';

import ReactDOM from 'react-dom';

import { withFeatureFlags } from 'services/feature-flags';

import { App } from './components';

const AppWithFeatureFlags = withFeatureFlags(App, {
  application: 'React Admin',
});

ReactDOM.render(
  <React.StrictMode>
    <AppWithFeatureFlags />
  </React.StrictMode>,
  document.getElementById('root'),
);

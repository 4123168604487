import styled from 'styled-components';

import { BodyText } from 'components/atoms';
import { colors } from 'styles';

export const Container = styled.div`
  background-color: ${colors.neutralLightest};
  border-color: ${colors.neutralLight};
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 4px;
  outline: none;
  padding-bottom: 16px;
  padding: 16px;
`;

export const ErrorTextContainer = styled.div`
  padding-left: 16px;
`;

export const Text = styled(BodyText)`
  color: ${colors.placeholderGrey};
  text-align: center;
`;

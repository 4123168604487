import React from 'react';

import { Typography } from '@material-ui/core';

import { Field } from 'components/atoms';

interface TextFieldProps {
  label: string;
  value?: string | number;
}

/**
 * The `TextField` from react-admin does not render correctly unless it is a direct
 * child of the SimpleShowLayout. This makes it difficult to extract groups of fields
 * into separate components to be reusable. This component can be used instead to match
 * the visual appearance of the `TextField`.
 */
export const TextField = ({ label, value }: TextFieldProps) => {
  return (
    <Field label={label}>
      <Typography component="span" variant="body2">
        {value}
      </Typography>
    </Field>
  );
};

import React, { ComponentProps } from 'react';

import { SaveButton, Toolbar } from 'react-admin';
import styled from 'styled-components';

type Props = ComponentProps<typeof Toolbar> & {
  onClose?: () => void;
  onSave?: ComponentProps<typeof SaveButton>['onSave'];
  closeLabel?: string;
};

export const DialogToolbar: React.FC<Props> = ({ onSave, ...props }) => {
  return (
    <StyledToolbar {...props}>
      <SaveButton onSave={onSave} />
    </StyledToolbar>
  );
};

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
`;

import React, { FC } from 'react';

import {
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from 'react-admin';

import { AvatarField, DateTimeField } from 'components';
import { LazyPagination } from 'components/organisms/LazyPagination';

import { RTWStatusField } from './StatusField';

export const RightToWorkScreeningList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      pagination={<LazyPagination />}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
    >
      <Datagrid>
        <ReferenceField
          label=""
          reference="StintUser"
          sortable={false}
          source="stinter.id"
        >
          <AvatarField sortable={false} source="profilePhotoUrl" />
        </ReferenceField>
        <ReferenceField
          label="Stinter"
          reference="StintUser"
          source="stinter.id"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <DateTimeField
          label="Last change at"
          sortable={false}
          source="updatedAt"
          showTime
        />
        <RTWStatusField label="Status" source="status" />
        <TextField source="type" />
        <ReferenceField
          label="City"
          reference="StintUser"
          sortable={false}
          source="stinter.id"
        >
          <TextField source="currentCity.name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

import React from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import { Loading, ShowProps, useShowController } from 'react-admin';

import { StudentShowForm } from '../StudentShowForm';

export const icon = BusinessIcon;

export const StudentsShow: React.FC<ShowProps> = props => {
  const { record } = useShowController(props);

  if (!record) {
    return <Loading />;
  }

  return <StudentShowForm {...props} />;
};

export const bindResizeListener = (
  map: google.maps.Map,
  maps: typeof google.maps,
  bounds: google.maps.LatLngBounds,
) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    });
  });
};

import 'react-image-crop/dist/ReactCrop.css';

import { useCallback, useRef, useState } from 'react';

import { Crop } from 'react-image-crop';

interface Props {
  onError?: (error: Error) => void;
  onLoad: (value: string) => void;
  setCropModalVisible: (cropModalVisible: boolean) => void;
}

interface ImageDropZoneData {
  data: string;
  file: File;
}

const INITIAL_CROP: Crop = { unit: '%', height: 100, aspect: 1 };

export const readFileAsDataURL = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64data = reader.result;
      if (!base64data) {
        reject(new Error('Could not read file'));
      } else {
        resolve(Buffer.from(base64data as ArrayBuffer).toString());
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const canvasAsDataURL = (
  canvas: HTMLCanvasElement,
  mediaType: string,
): Promise<string> => {
  return new Promise<Blob>((resolve, reject) =>
    canvas.toBlob(
      blob =>
        blob ? resolve(blob) : reject(new Error('Blob generation error.')),
      mediaType,
      100,
    ),
  ).then(blob => readFileAsDataURL(blob));
};

const createCropPreview = (
  image: HTMLImageElement,
  crop: Crop,
  mediaType: string,
): Promise<string> => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx || !canvas || !crop.width || !crop.height) {
    return Promise.reject(new Error('Invalid crop parameters.'));
  }

  const scaleFactorX = image.naturalWidth / image.width;
  const scaleFactorY = image.naturalHeight / image.height;
  const croppedImageWidth = crop.width * scaleFactorX;
  const croppedImageHeight = crop.width * scaleFactorX;
  const cropX = (crop.x || 0) * scaleFactorX;
  const cropY = (crop.y || 0) * scaleFactorY;

  canvas.width = croppedImageWidth;
  canvas.height = croppedImageHeight;

  ctx.drawImage(
    image,
    cropX,
    cropY,
    croppedImageWidth,
    croppedImageHeight,
    0,
    0,
    croppedImageWidth,
    croppedImageHeight,
  );

  return canvasAsDataURL(canvas, mediaType);
};

export const useCrop = ({
  onLoad,
  onError = () => {},
  setCropModalVisible,
}: Props) => {
  const [crop, setCrop] = useState(INITIAL_CROP);
  const fileRef = useRef<File>();
  const originalImageAsURLDataRef = useRef('');
  const croppedImageRef = useRef<HTMLImageElement>();

  const handleLoad = ({ data, file }: ImageDropZoneData) => {
    fileRef.current = file;
    originalImageAsURLDataRef.current = data;
    setCropModalVisible(true);
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    croppedImageRef.current = image;
  };

  const onMakeClientCrop = useCallback(async () => {
    if (!croppedImageRef.current || !fileRef.current) {
      return;
    }

    const { type } = fileRef.current;

    try {
      const imageAsURLData = await createCropPreview(
        croppedImageRef.current,
        crop,
        type,
      );
      onLoad(imageAsURLData);
    } catch (error) {
      onError(error as Error);
    }

    setCropModalVisible(false);
  }, [crop, croppedImageRef, fileRef, onLoad, setCropModalVisible, onError]);

  return {
    crop,
    handleLoad,
    onImageLoaded,
    onMakeClientCrop,
    originalImageAsURLDataRef,
    setCrop,
  };
};

import React, { FC, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from 'match-sorter';
import { useInput, ValidationError } from 'react-admin';

import { ChangeEvent } from 'components/organisms/SearchAddressField/SearchAddressField.types';

import { SearchResultText } from '../../atoms';
import {
  AddressAutoCompleteProps,
  AddressSearchResult,
} from './AddressAutoComplete.types';

export const AddressAutoComplete: FC<AddressAutoCompleteProps> = ({
  addressSearchResults,
  onChange,
  onInputChange,
  placeholder,
  label,
  validate,
}) => {
  const [searchText, setSearchText] = useState('');

  const filterOptions = (
    options: AddressSearchResult[],
    { inputValue }: { inputValue: string },
  ) => matchSorter(options, inputValue, { keys: ['displayAddress'] });

  const { input, meta, isRequired } = useInput({
    source: 'displayAddress',
    validate,
  });

  const handleOnInputChange = (event: ChangeEvent, text: string) => {
    setSearchText(text);
    onInputChange(event, searchText);
  };

  const addressOptionResults =
    addressSearchResults?.map(address => {
      return {
        placeId: address?.placeId ?? '',
        displayAddress: address?.displayAddress ?? '',
      };
    }) || [];

  return (
    <Autocomplete
      disableClearable
      filterOptions={filterOptions}
      forcePopupIcon={false}
      fullWidth
      getOptionLabel={(option: AddressSearchResult) => option.displayAddress}
      getOptionSelected={(option, value) => option.placeId === value.placeId}
      noOptionsText={`No search results for ${searchText}`}
      onChange={onChange}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onInputChange={handleOnInputChange}
      options={addressOptionResults}
      renderInput={params => (
        <TextField
          error={meta.touched && meta.invalid}
          helperText={
            meta.touched && meta.invalid ? (
              <ValidationError error={meta.error} />
            ) : (
              ''
            )
          }
          label={label && isRequired ? `${label} *` : label}
          {...params}
          color="primary"
          placeholder={placeholder}
          value={searchText}
          variant="standard"
        />
      )}
      renderOption={(
        option: AddressSearchResult,
        { inputValue }: { inputValue: string },
      ) => {
        return (
          <SearchResultText
            optionText={option.displayAddress}
            value={inputValue}
          />
        );
      }}
    />
  );
};

import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { SaveButton, TextField, ToolbarProps } from 'react-admin';
import styled from 'styled-components';

import { DateTimeField } from 'components/atoms';

export const StintopediaEditToolbar: FC<ToolbarProps> = ({
  handleSubmitWithRedirect,
  record,
  saving,
}) => {
  return (
    <Toolbar>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <SaveButton
          handleSubmitWithRedirect={handleSubmitWithRedirect}
          saving={saving}
        />
        {!!record?.lastEditTime && (
          <Box>
            <Typography display="inline" variant="subtitle1">
              Last edit:
            </Typography>
            <TextWrapper>
              <TextField record={record} source="lastEditBy.email" />
              {', '}
              <DateTimeField
                dateTimeFormat="MMM DD, YYYY HH:mm"
                record={record}
                source="lastEditTime"
              />
            </TextWrapper>
          </Box>
        )}
      </Box>
    </Toolbar>
  );
};

const TextWrapper = styled.span`
  padding-left: 8px;
`;

import React, { ComponentProps } from 'react';

import { readFileAsDataURL } from 'hooks/useCrop';

import { FileDropZone } from '../FileDropZone';

export type ImageDropZoneData = { data: string; file: File };

type Props = Omit<ComponentProps<typeof FileDropZone>, 'onLoad'> & {
  onLoad?: (data: ImageDropZoneData) => void;
};

export const ImageDropZone: React.FC<Props> = ({
  onLoad,
  accept = ['image/*'],
  ...rest
}) => {
  return (
    <FileDropZone
      {...rest}
      accept={accept}
      onLoad={async (data: { file: File }) => {
        const { file } = data;
        onLoad?.({
          file,
          data: await readFileAsDataURL(file),
        });
      }}
    />
  );
};

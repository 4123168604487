import React from 'react';

import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

export const TopTipShow: React.FC<ShowProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

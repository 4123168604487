import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreIcon from '@material-ui/icons/Restore';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import {
  DEFAULT_SETTINGS,
  getServerSettings,
  resetServerSettings,
  ServerLinkSetting,
  setServerSettings,
  validateSettings,
} from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(0.5),
        width: '25ch',
      },
    },
  }),
);

export const ServerLink: React.FC = () => {
  const [draftSettings, setDraftSettings] = useState<ServerLinkSetting>(
    getServerSettings(),
  );
  const [settings, setSettings] = useState<ServerLinkSetting>(
    getServerSettings(),
  );
  const [error, setError] = useState<null | {
    auth: null | string;
    api: null | string;
  }>(null);

  const handleAPILinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftSettings({
      api: event.target.value,
      authService: draftSettings?.authService,
    });
  };
  const handleAuthLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftSettings({
      authService: event.target.value,
      api: draftSettings!.api,
    });
  };
  const handleReset = () => {
    resetServerSettings();
    setError(null);
    setSettings(DEFAULT_SETTINGS);
    setDraftSettings(DEFAULT_SETTINGS);
    setServerSettings(settings);
  };

  const handleSave = () => {
    const errors = validateSettings(draftSettings);
    setError(errors);

    if (errors?.auth || errors?.api) return;

    setSettings(draftSettings);
    setServerSettings(draftSettings);
  };

  const handleCancel = () => {
    setError(null);
    setDraftSettings(settings);
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid alignItems="center" container item justify="space-between" xs={6}>
          <form
            autoComplete="off"
            className={classes.root}
            noValidate
            style={{
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <FlexTextField
              error={!!error?.api}
              helperText={error?.api}
              id="outlined-basic"
              label="API link"
              onChange={handleAPILinkChange}
              size="small"
              value={draftSettings.api || ''}
              variant="outlined"
            />
            <FlexTextField
              error={!!error?.auth}
              helperText={error?.auth}
              id="outlined-basic"
              label="Auth link"
              onChange={handleAuthLinkChange}
              placeholder="Empty - Will use legacy GraphQL mutations"
              size="small"
              value={draftSettings.authService || ''}
              variant="outlined"
            />

            {!isEqual(draftSettings, settings) && (
              <NoFlexBox>
                <Button
                  color="primary"
                  onClick={() => handleSave()}
                  size="small"
                  startIcon={<SaveAltIcon />}
                  variant="outlined"
                >
                  Save
                </Button>
              </NoFlexBox>
            )}

            {!isEqual(draftSettings, settings) && (
              <NoFlexBox>
                <Button
                  color="primary"
                  onClick={() => handleCancel()}
                  size="small"
                  startIcon={<RestoreIcon />}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </NoFlexBox>
            )}
            {!isEqual(DEFAULT_SETTINGS, settings) && (
              <NoFlexBox>
                <Button
                  color="primary"
                  onClick={() => handleReset()}
                  size="small"
                  startIcon={<DeleteForeverIcon />}
                  variant="outlined"
                >
                  Reset
                </Button>
              </NoFlexBox>
            )}
          </form>
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </>
  );
};

const FlexTextField = styled(TextField)`
  flex: 1;
`;
const NoFlexBox = styled(Box)`
  flex: 0;
`;

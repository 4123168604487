import { List as ReactAdminList } from 'react-admin';

const List = ReactAdminList;

List.defaultProps = {
  bulkActionButtons: false,
  perPage: 25,
};

export default List;

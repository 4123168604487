import { OffenceAppealStatus } from 'apollo/schema';

const reasonMap = new Map<OffenceAppealStatus, string>([
  [OffenceAppealStatus.NOT_APPEALED, 'Not appealed'],
  [OffenceAppealStatus.PENDING, 'Pending'],
  [OffenceAppealStatus.SUCCESSFUL, 'Successful'],
  [OffenceAppealStatus.UNSUCCESSFUL, 'Unsuccessful'],
]);

export const mapOffenceAppealStatus = (reason: OffenceAppealStatus): string => {
  const mapped = reasonMap.get(reason);
  if (!mapped) {
    return '';
  }

  return mapped;
};

import { CreateFormData } from './StintCreate.types';

export const transform = (
  formData: CreateFormData,
): { createInputs: CreateFormData } => {
  return {
    createInputs: {
      stintTemplateId: formData.stintTemplateId,
      businessId: formData.businessId,
      dateFrom: formData.dateFrom,
      dateTo: formData.dateTo,
      quantity: formData.quantity,
    },
  };
};

import React from 'react';

import { Button } from 'react-admin';
import Dropzone, { FileError, FileRejection } from 'react-dropzone';

import { StyledDropZone } from './FileDropZone.styles';

type Props = {
  accept?: string[];
  maxSizeInMB?: number;
  onDropAccepted?: (files: File[]) => void;
  onError?: (error: Error) => void;
  onLoad?: (data: { file: File }) => void;
  validator?: (file: File) => FileError | FileError[] | null;
};

export const FileDropZone: React.FC<Props> = ({
  accept,
  children,
  maxSizeInMB = 10,
  onDropAccepted = () => {},
  onError = () => {},
  onLoad = () => {},
  validator,
}) => {
  const handleDrop = async (files: File[]) => {
    const file = files?.[0];
    if (file) {
      onLoad({
        file,
      });
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const fileRejectionErrorMessage = fileRejections[0]?.errors[0]?.message;

    let errorMessage =
      'There was something wrong with this file, please try again.';

    if (fileRejectionErrorMessage) {
      errorMessage = fileRejectionErrorMessage;
    }

    onError(new Error(errorMessage));
  };

  return (
    <Dropzone
      accept={accept}
      maxSize={maxSizeInMB * 1024 * 1024}
      multiple={false}
      onDrop={handleDrop}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      validator={validator}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <StyledDropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {children}

            {!children && <Button title="Upload" />}
          </StyledDropZone>
        );
      }}
    </Dropzone>
  );
};

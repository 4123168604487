import { DeviceLocation } from 'apollo/schema';

import { ValidDeviceLocation } from './types';

/**
 * Type guard that checks if a DeviceLocation object is a ValidDeviceLocation object
 * In short, it checks if important properties are defined so that the object
 * is usable
 * @param deviceLocation
 */
export const isValidDeviceLocation = (
  deviceLocation: DeviceLocation,
): deviceLocation is ValidDeviceLocation => {
  return (
    !!deviceLocation &&
    !!deviceLocation.latitude &&
    !!deviceLocation.longitude &&
    deviceLocation.accuracy !== null &&
    deviceLocation.accuracy !== undefined &&
    !!deviceLocation.recordedAt &&
    !!deviceLocation.user_id
  );
};

import gql from 'graphql-tag';

import { buildQuery } from './utils';

const createQuery = gql`
  mutation AdminCreateAppeal($createInputs: AdminCreateAppealInput!) {
    data: adminCreateAppeal(createInputs: $createInputs) {
      id
      decisionAt
      status
    }
  }
`;

export const appeals = buildQuery({
  createQuery,
});
